import "./index.scss";
const Montoring = () => {
  return (
    <div className="component_wrapper table_container">
      <p className="component_title">Latest Montoring</p>
      <table className="table_color_alternate">
        <tr>
          <td className="checkbox_label">
            <input type="checkbox" />1
          </td>
          <td>Province 1</td>
          <td>10,213</td>
          <td>1,234</td>
          <td>5,678</td>
          <td>9,012</td>
        </tr>
        <tr>
          <td className="checkbox_label">
            <input type="checkbox" />1
          </td>
          <td>Province 1</td>
          <td>10,213</td>
          <td>1,234</td>
          <td>5,678</td>
          <td>9,012</td>
        </tr>{" "}
        <tr>
          <td className="checkbox_label">
            <input type="checkbox" />1
          </td>
          <td>Province 1</td>
          <td>10,213</td>
          <td>1,234</td>
          <td>5,678</td>
          <td>9,012</td>
        </tr>{" "}
        <tr>
          <td className="checkbox_label">
            <input type="checkbox" />1
          </td>
          <td>Province 1</td>
          <td>10,213</td>
          <td>1,234</td>
          <td>5,678</td>
          <td>9,012</td>
        </tr>{" "}
        <tr>
          <td className="checkbox_label">
            <input type="checkbox" />1
          </td>
          <td>Province 1</td>
          <td>10,213</td>
          <td>1,234</td>
          <td>5,678</td>
          <td>9,012</td>
        </tr>{" "}
        <tr>
          <td className="checkbox_label">
            <input type="checkbox" />1
          </td>
          <td>Province 1</td>
          <td>10,213</td>
          <td>1,234</td>
          <td>5,678</td>
          <td>9,012</td>
        </tr>{" "}
        <tr>
          <td className="checkbox_label">
            <input type="checkbox" />1
          </td>
          <td>Province 1</td>
          <td>10,213</td>
          <td>1,234</td>
          <td>5,678</td>
          <td>9,012</td>
        </tr>
      </table>
      <div className="table_footer">
        <p className="table_pages">Page 1 of 1</p>
        <button className="table_page">1</button>
        <div></div>
      </div>
    </div>
  );
};
export default Montoring;
