import Signature from "../../lang_nepali/signature";
import "./index.scss";
const Content = () => {
  return (
    <>
      <p className="paragraph_indent" style={{ marginBottom: "4rem" }}>
        तहाँ सम्मानित अदालतको मिति ..................... च.नं. .......... को
        पत्रानुसार यस वडा कार्यालयबाट प्राविधिक मुल्यांकन गरी यसै पत्र साथ
        कार्यरत प्राविधिकको सक्कल प्रतिबेदन संलग्न राखी पठाइएको व्यहोरा अनुरोध छ
        |
      </p>
      <Signature />
    </>
  );
};
export default Content;
