import { useEffect } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import DesignationContainer from ".";
import { useAppDispatch } from "../../../../hooks/hook";
import {
  setSpecificdesignation,
  set_loading_designation,
  setdesignation,
} from "../../../../redux/store/designation";
import Http from "../../../../utils/httpClients";
const UpdateOfficeType = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    Http.GET("/designations/" + id, true)
      .then((res) => {
        dispatch(setSpecificdesignation(res.data.data.designation));
      })
      .catch((err) => {
        toast.error("Error occured while fetching designation!");
      });
  }, [id, dispatch]);
  const form_handler = (e: any) => {
    e.preventDefault();
    const form = new FormData(e.target);
    dispatch(set_loading_designation(true));
    Http.PUT(
      "/designations/" + id,
      { designation: form.get("designation") },
      true
    )
      .then((res) => {
        dispatch(set_loading_designation(false));
        Http.GET("/designations", true).then((res) => {
          dispatch(setdesignation(res.data.data));
        });
        toast.success("Office type has been updated");
      })
      .catch((err) => {
        dispatch(set_loading_designation(false));
        toast.error("Error occured while updating designation!");
      });
  };
  return (
    <DesignationContainer title="सम्पादन" update form_handler={form_handler} />
  );
};
export default UpdateOfficeType;
