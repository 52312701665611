import Table from "../../../component/table_container/main_table";
import TableHeader from "../../../component/table_container/table_header";
import TableSubHeader from "../../../component/table_container/table_sub_header";
import { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  deleteDistrict,
  search_items,
  setDistrict,
  set_current_page,
  set_items_per_page,
  show_data_district,
} from "../../../redux/store/districts";
import Modal from "../../../component/modal";
import { get_districts } from "../../../utils/api/federal_stucture/district";
import { delete_district } from "../../../utils/api/federal_stucture/district";
const Jilla = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [delete_id, setDeleteId] = useState<number | null>(null);
  const [modal, setModal] = useState<boolean>(false);
  const data = useAppSelector((state) => state.district.show_data);
  const items = useAppSelector((state) => state.district.data);
  const filtered_data = useAppSelector(
    (state) => state.district.filtered_data
  );
  const items_per_page = useAppSelector(
    (state) => state.district.items_per_page
  );
  const current_page = useAppSelector(
    (state) => state.district.current_page
  );
  useEffect(() => {
    if (data && data.length === 0) {
      get_districts()
        .then((res) => {
          dispatch(setDistrict(res?.data?.data));
          dispatch(show_data_district());
        })
        .catch((err) => toast.error("जिल्ला ल्याउने क्रममा त्रुटि भयो!"));
    }
  }, [dispatch]);
  const deleteHandler = (id: number) => {
    setDeleteId(id);
    setModal(true);
  };
  const deleteData = () => {
    if (delete_id !== undefined && delete_id !== null) {
      delete_district(delete_id)
        .then((res) => {
          toast.success("जिल्ला सफलतापूर्वक मेटिएको छ");
          dispatch(deleteDistrict(delete_id));
          setModal(false);
        })
        .catch((err) => {
          toast.error("डाटा मेटाउँदा त्रुटि भयो!");
          setModal(false);
        });
    }
  };
  const show_items = (e: any) => {
    dispatch(set_items_per_page(e.value));
    dispatch(show_data_district());
  };
  const page_change = (event: any, pageNumber: any) => {
    dispatch(set_current_page(pageNumber));
    dispatch(show_data_district());
  };
  const change_input = (e: any) => {
    dispatch(search_items(e.target.value));
    dispatch(show_data_district());
  };
  return (
    <>
      <Modal
        open={modal}
        close_modal={() => setModal(false)}
        action={deleteData}
        title="district"
      />
      <div className="table_wrapper">
        <div className="component_wrapper table_container">
          <TableHeader
            primary_button_name="
            जिल्ला थप्नुहोस्"
            table_title="जिल्ला"
            on_add={() => navigate("add")}
            items_length={items ? items.length : 0}
          />
          <TableSubHeader
            item_select={true}
            no_of_items={show_items}
            placeholder="Search district data...."
            input_handler={change_input}
          />
          <Table
            page_change={page_change}
            page_count={filtered_data && Math.ceil(filtered_data?.length / items_per_page)}
            current_page={current_page}
            pagination_display={items && items?.length >= 10}
            item_range={
              data && data?.length * (current_page - 1) +
              "-" +
              current_page * data?.length +
              " items"
            }
            table_headings={[
              "S.N.",
              "district name(en)",
              "district name(np)",
              "Province Name",
              "last modified by",
              "actions",
            ]}
          >
            {data && data?.length > 0 ? (
              data?.map((p: any, i: any) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{p.district_en}</td>
                  <td>{p.district_np}</td>
                  <td>{p.province?.province_en}</td>
                  <td>{p.updated_at ? p.updated_at : "Not Modified Yet"}</td>
                  <td className="align_items table_icons">
                    <AiOutlineDelete
                      data-tooltip-id="view"
                      data-tooltip-content="Delete District"
                      onClick={() => deleteHandler(p.id)}
                    />
                    <FiEdit2
                      data-tooltip-id="view"
                      data-tooltip-content="Update District"
                      onClick={() => navigate("update/" + p.id)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <td className="no_match_text" colSpan={6}>
                No search result found for district
              </td>
            )}
          </Table>
        </div>
      </div>
    </>
  );
};
export default Jilla;
