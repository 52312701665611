//विषय
import styles from "./index.module.scss";
interface Props {
  subject: String;
}
const Subject = (props: Props) => {
  return (
    <>
      <p className={styles.underline_center_text}>विषय: {props.subject} |</p>
    </>
  );
};
export default Subject;
