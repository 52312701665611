import HeaderLayout from "../../layouts/header";
const Header = () => {
  let ward_no = 1;
  let address = "Putalisadak";
  let province = "Bagmati";
  let title_1 = "Kathmandu Metropolitian";
  let title_2 = `Ward No. ${ward_no} Office`;
  let title_3 = `${address}, Kathmandu`;
  let title_4 = `Province ${province}, Nepal`;
  return (
    <HeaderLayout
      title_1={title_1}
      title_2={title_2}
      title_3={title_3}
      title_4={title_4}
      date="27/09/2023"
      sub_title_1="Ref no:"
      sub_title_2="Date:"
    />
  );
};
export default Header;
