// all links of side_bar
import { HiOutlineHome } from "react-icons/hi";
import { FiSettings } from "react-icons/fi";
import { FiUserPlus } from "react-icons/fi";
import LinkLayout from "../link_layout";
import { TbSitemap } from "react-icons/tb";
import SubMenu from "../link_layout/sub_menu";
import { AiOutlineProfile } from "react-icons/ai";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { setSidebar } from "../../../../redux/store/sidebar";
const Links = () => {
  const dispatch = useAppDispatch();
  const [active_link, setActiveLink] = useState<Number>(0);
  const [hide_sub_menu, setSubMenu] = useState<boolean>(false);
  const minimize_link = useAppSelector(
    (state: any) => state.sidebar.sidebar_max
  );

  const submenu_handler = (id: number) => {
    if (id === active_link) {
      if (hide_sub_menu) {
        setSubMenu(false);
      } else {
        setSubMenu(true);
      }
    } else {
      setSubMenu(true);
      setActiveLink(id);
    }
  };
  const minimizeSideBar = () => {
    dispatch(setSidebar());
    let p = document.getElementsByClassName("side_bar");
    let q = document.getElementsByClassName("sidebar_link");
    let r = document.getElementsByClassName("content");
    if (p) {
      p[0].classList.toggle("minimize_side_bar");
    }
    if (q) {
      for (let i = 0; i < q.length; i++) {
        q[i].classList.toggle("minimize_side_bar");
      }
    }
    if (r) {
      r[0].classList.toggle("minimize_content");
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <button
        className="toggle_button_sidebar"
        onClick={minimizeSideBar}
        style={{ marginBottom: "5px" }}
      >
        <MdKeyboardDoubleArrowRight
          style={{ rotate: !minimize_link ? "180deg" : "0deg" }}
        />
      </button>
      <LinkLayout
        url="/"
        icon={<HiOutlineHome />}
        name="Dashboard"
        link_clicked={() => setActiveLink(0)}
      />
      {!minimize_link && (
        <div className="divider_font light_bold">
          <p>system configuration</p>
        </div>
      )}
      <SubMenu
        icon={<TbSitemap />}
        show={active_link === 1 && hide_sub_menu}
        active={active_link === 1}
        toggle_sidebar_link={() => submenu_handler(1)}
        route_name="federal structure"
        children={[
          { path: "/federal_structure/province", name: "Province" },
          { path: "/federal_structure/district", name: "District" },
          { path: "/federal_structure/local_level", name: "Local Level" },
        ]}
      />

      <SubMenu
        icon={<FiUserPlus />}
        show={active_link === 2 && hide_sub_menu}
        active={active_link === 2}
        route_name="user configuration"
        toggle_sidebar_link={() => submenu_handler(2)}
        children={[
          { path: "/user_configuration/user", name: "User" },
          { path: "/user_configuration/role", name: "Roles" },
          { path: "/user_configuration/permission", name: "Permission" },
          { path: "/user_configuration/publicuser", name: "Public User" },
        ]}
      />
      <SubMenu
        icon={<FiSettings />}
        show={active_link === 3 && hide_sub_menu}
        active={active_link === 3}
        route_name="system configuration"
        toggle_sidebar_link={() => submenu_handler(3)}
        children={[
          { path: "/system_configuration/office", name: "Office" },
          { path: "/system_configuration/office_type", name: "Office Type" },
          { path: "/system_configuration/designation", name: "Designation" },
          {
            path: "/system_configuration/sifaris_type",
            name: "Sifaris Type",
          },
          { path: "/system_configuration/document", name: "Document" },
          {
            path: "/system_configuration/family_relation",
            name: "Family Relation",
          },
        ]}
      />
      <SubMenu
        route_name="sifaris"
        show={active_link === 4 && hide_sub_menu}
        active={active_link === 4}
        icon={<AiOutlineProfile />}
        toggle_sidebar_link={() => submenu_handler(4)}
        children={[
          { path: "/sifaris/add", name: "Add New" },
          { path: "/sifaris/approve", name: "Approve" },
        ]}
      />
    </div>
  );
};
export default Links;
