import ManageSifarisType from ".";
import Http from "../../../utils/httpClients";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { useEffect } from "react";
import { resetSifarisForm } from "../../../redux/store/sifaris_type";
const AddSifarisType = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const data = useAppSelector(
    (state: any) => state.sifaris_types.specific_sifaris_type
  );
  useEffect(() => {
    dispatch(resetSifarisForm());
  }, [dispatch]);
  const form_handler = (e: any) => {
    e.preventDefault();
    Http.POST(
      "/sifaristypes",
      { name: data.name, route: data.route, method: data.method },
      true
    )
      .then((res) => {
        navigate("/system_configuration/sifaris_type");
      })
      .catch((err) => {
        toast.error("Error occured while creating sifaris type!");
      });
  };
  return (
    <>
      <ManageSifarisType
        form_handler={form_handler}
        title="
    सिर्जना"
      />
    </>
  );
};

export default AddSifarisType;
