import DynamicForm from "../dynamic_form";
import Http from "../../utils/httpClients";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { useNavigate, useParams } from "react-router";
import {
  resetDynamicInputField,
  updateSifarisForm,
} from "../../redux/store/sifaris_type";
import { useEffect } from "react";
import {
  setActiveState,
  setCurrentState,
  setWidth,
} from "../../redux/store/sifaris_progress";
import { resetSearchData } from "../../redux/store/add_sifaris";
const Table = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const table_title = useAppSelector(
    (state: any) => state.sifaris_types.table_title
  );
  const table_header = useAppSelector(
    (state: any) => state.sifaris_types.table_header
  );
  useEffect(() => {
    dispatch(resetSearchData());
    dispatch(updateSifarisForm({}));
    dispatch(resetDynamicInputField());
  }, [dispatch]);

  console.log(window.location.pathname);
  const table_submit = (data: any) => {
    const table_header = data.map((p: any) => p.label);
    Http.POST(
      "/sifaristables",
      data.map((p: any) => {
        return {
          ...p,
          table_title,
          table_header: table_header.join("|"),
          sifaristype_id: id,
        };
      }),
      true
    )
      .then((res) => {
        if (window.location.pathname.split("/").includes("dynamic_table")) {
          navigate("/system_configuration/sifaris_type/table/" + id,{replace:true});
        }
        toast.success("Table has been created!", { position: "bottom-right" });
        dispatch(setCurrentState(5));
        dispatch(setActiveState(2));
        dispatch(setWidth(4));
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error while creating table!");
      });
  };
  return (
    <>
      <DynamicForm
        form_title={table_title}
        status="सिर्जना"
        status_en="Submit"
        current_status="Creation"
        table_headings={table_header}
        table
        title="
        तालिका"
        on_submit={table_submit}
      />
    </>
  );
};
export default Table;
