// higher order component
import { useAppSelector } from "../../hooks/hook";
import Nav from "../nav";
import BottomNav from "../nav/bottom_nav";
import SideBar from "../side_bar";
import style from "./index.module.scss";
import { motion, AnimatePresence } from "framer-motion";
interface LayoutProps {
  children?: JSX.Element[] | JSX.Element;
}
const HOC = (props: LayoutProps) => {
  const minimize_link = useAppSelector(
    (state: any) => state.sidebar.sidebar_max
  );
  return (
    <div className={style.layout}>
      <Nav />
      <div className="content">
        <SideBar />
        <AnimatePresence>
          <motion.div
            className="body"
            style={{
              position: "relative",
              marginLeft: minimize_link && "92px",
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <BottomNav />
            {props.children}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};
export default HOC;
