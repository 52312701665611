import Signature from "../../lang_nepali/signature";
import style from "../../content.module.scss";
import Table from "../../../component/table_container/main_table";
const Content = () => {
  let headings = ["क्र.सं.", "टोल", "बाटोको नाम", "घर नं."];

  return (
    <>
      <p className="paragraph_indent">
        श्री/सुश्री/श्रीमती ................... महा/उपमहा/नगरपालिका/गाउँपालिका
        वडा नं. .................... (साबिकको ठेगाना ....................)
        अन्तर्गत तल उल्लेखित स्थानमा रहेको घरधनी श्री/सुश्री/श्रीमती
        ................... को घरमा बिगत मिति ........................ देखि
        अस्थायी बसोबास गर्दै आउनु भएको व्यहोरा सिफारिस साथ अनुरोध गरिन्छ|
        <br />
        बसोबास गर्नेको ना. प्र. प नं:..................../ जिल्ला -
        .................../ जारी मिति ....................
      </p>
      <div className={style.sifaris_docs_table}>
        <h4 style={{ textAlign: "center", marginBottom: "1rem" }}>
          बसोबास स्थान
        </h4>
        <Table table_headings={headings}></Table>
      </div>

      <Signature />
    </>
  );
};
export default Content;
