import Province from "../";
import AddProvince from "../manage_pradesh/add_pradesh";
import UpdateProvince from "../manage_pradesh/update_pradesh";
import { Route, Routes } from "react-router";
export const ProvinceRoutes = () => (
  <Routes>
    <Route path="/federal_structure/province" element={<Province />} />
    <Route path="/federal_structure/province/add" element={<AddProvince />} />
    <Route
      path="/federal_structure/province/update/:id"
      element={<UpdateProvince />}
    />
  </Routes>
);
