import Signature from "../../lang_nepali/signature";
import style from "../../content.module.scss";
import Table from "../../../component/table_container/main_table";
const Content = () => {
  let headings1 = [
    "क्र.स.",
    "हकदारहरुको नाम",
    "नाता",
    "बाबु पति को / नाम",
    "नागरिकता नं",
    "घर नं",
    "कित्ता नं",
    "बाटाको नाम",
  ];

  let headings2 = [
    "क्र.स.",
    "वडा",
    "सिट नं.",
    "कित्ता",
    "क्षेत्रफल",
    "घर नं",
    "कित्ता नं",
    "बाटोको नाम बाटोको / प्रकार",
    "कैफियत",
  ];
  return (
    <>
      श्री ....................
      <br />
      ................... |
      <p className="paragraph_indent">
        निवेदक श्री/सुश्री/श्रीमती .......... को .......... नाता पर्ने श्री
        .......... को मिति .......... मा मृत्यु भएको हुनाले निज मृतकका नाममा
        दर्ता कायम रहेको तल उल्लेखित विवरणको घरजग्गा नामसारीको लागि
        श्री/सुश्री/श्रीमती .......... ले निबेदन दिनु भए कोमा मृतकका हकदारहरु
        नाता प्रमाणित प्रमाण पत्रमा उल्लेखित भए अनुसार रहेकोले निज मृतकका नाममा
        रहेको सो घर/जग्गा त्यहाँको नियमानुसार हकदारहरुको नाममा नामसारीको लागि
        सिफारिससाथ अनुरोध गरिन्छ |
      </p>
      <div className={style.sifaris_docs_table}>
        <h4 style={{ textAlign: "center", marginBottom: "1rem" }}>
          मृतकका हकदारको विवरण
        </h4>
        <Table table_headings={headings1}></Table>
      </div>
      <div className={style.sifaris_docs_table}>
        <h4 style={{ textAlign: "center", marginBottom: "1rem" }}>
          नामसारी गर्ने घर/जग्गाको विवरण
        </h4>
        <Table table_headings={headings2}></Table>
      </div>
      <Signature />
    </>
  );
};
export default Content;
