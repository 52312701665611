import { Route, Routes } from "react-router";
import Office from "../";
import AddOffice from "../manage_office/add_office";
import UpdateOffice from "../manage_office/update_office";
import ViewOffice from "../manage_office/view_office";
export const OfficeRoutes = () => {
  return (
    <Routes>
      <Route path="/system_configuration/office" element={<Office />} />
      <Route path="/system_configuration/office/add" element={<AddOffice />} />
      <Route
        path="/system_configuration/office/update/:id"
        element={<UpdateOffice />}
      />
      <Route
              path="/system_configuration/office/view/:id"
              element={<ViewOffice />}
            />
    </Routes>
  );
};
