import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  setCurrentState,
  setWidth,
} from "../../../redux/store/sifaris_progress";
import { setSifarisFormTitle } from "../../../redux/store/sifaris_type";
import { TitleElement, InputFieldElement, PrevNxtButtons } from "../index";
const FormTitle = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const form_handler = (e: any) => {
    e.preventDefault();
    dispatch(setWidth(1));
    dispatch(setCurrentState(2));
  };
  const form_title = useAppSelector(
    (state: any) => state.sifaris_types.form_title
  );

  const changeHandler = (e: any) => {
    dispatch(setSifarisFormTitle(e.target.value));
  };
  return (
    <form
      autoComplete="off"
      onSubmit={form_handler}
      style={{ padding: "3% 0" }}
    >
      <TitleElement
        title="Sifaris Form"
        sub_title="Please enter form title that denote form*"
      />
      <InputFieldElement
        name="form_title"
        title="form title"
        placeholder="Enter form title"
        on_change={changeHandler}
        value={form_title}
      />
      <PrevNxtButtons
        cancel_title="Return"
        skip
        skip_state={3}
        prev_action={() => navigate(-1)}
        next_action={form_handler}
      />
    </form>
  );
};
export default FormTitle;
