// user info side_bar at top
import DummyImg from "../../../../assest/images/fummy.jpg";
import { AnimatePresence, motion } from "framer-motion";
interface UserInfoProps {
  toggleSidebar?: React.MouseEventHandler;
  minimize?: boolean;
  showAnimation?: any;
}
const UserInfo = (props: UserInfoProps) => {
  return (
    <AnimatePresence>
      <div id="user_identity" className="header align_items">
        <img
          style={{ width: "5rem" }}
          className="loginImage"
          alt="loginImage"
          src="https://i.pinimg.com/564x/e1/c1/d7/e1c1d76acbb51846b915e540af5ae879.jpg"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <span
            style={{
              fontSize: "17px",
              color: "#676767",
              fontFamily: "Mukta",
              textAlign: "left",
            }}
          >
            ई-सिफारिस प्रणाली
          </span>
          <p
            style={{
              fontSize: "15px",
              textAlign: "left",
              color: "#28A745",
              wordWrap: "break-word",
            }}
          >
            <span>वेनी नगरपालिका, नगर कार्यपालिकाको कार्यालय</span>
          </p>
          <small
            style={{ fontFamily: "Mukta", color: "red", textAlign: "left" }}
          >
            बेनी बजार, म्याग्दी
          </small>
        </div>
      </div>
      {/*<div
        id="user_identity"
        className="user_identity"
        onClick={props.toggleSidebar}
      >
        <img className="user_img" src={DummyImg} alt="user_img" />
        {!props.minimize ? (
          <motion.div
            initial="hidden"
            animate="show"
            exit="hidden"
            variants={props.showAnimation}
            className="user_details"
          >
            <p className="user_name">सिफारिस प्रणाली</p>
            <p className="user_email">user.name@example.com</p>
          </motion.div>
        ) : null}
      </div> */}
    </AnimatePresence>
  );
};
export default UserInfo;
