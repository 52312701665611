export const CenterElement = (props: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <div
      className="component_wrapper"
      style={{
        backgroundColor: "transparent",
        border: "none",
        height: "calc(100% - 48.2px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {props.children}
    </div>
  );
};
