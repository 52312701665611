import { MdOutlineAdd } from "react-icons/md";
import { FiUploadCloud } from "react-icons/fi";
import { MdOutlineFileDownload } from "react-icons/md";
const TableHeader = () => {
  return (
    <div className="align_items table_header space_between">
      <div className="align_items_col">
        <div className="align_items">
          <p className="large_text super_bold">User list</p>
          <p className="small_text blue_border_box">12</p>
        </div>
        <p className="medium_size_shaded_text">
          Manage your user information details
        </p>
      </div>
      <div className="align_items">
        <button className="align_items bold button_font_size primary_button">
          <MdOutlineAdd />
          Add Customer
        </button>
        <button className="bold secondary_button button_font_size align_items">
          <FiUploadCloud />
          Import
        </button>
        <button className="bold secondary_button button_font_size align_items">
          <MdOutlineFileDownload />
          Export
        </button>
        <button className="bold secondary_button button_font_size align_items">
          <MdOutlineFileDownload />
          Download PDF Report
        </button>
      </div>
    </div>
  );
};

export default TableHeader;
