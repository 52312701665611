import PreviewInputField from "../../dynamic_form/output_input_field";
import style from "../index.module.scss";
import PreviewSelectField from "../../dynamic_form/output_input_field/selectinput";
import PreviewTextField from "../../dynamic_form/output_input_field/textarea";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { useEffect } from "react";
import Http from "../../../utils/httpClients";
import { setSifarisForms } from "../../../redux/store/sifaris_type";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { changeFormField } from "../../../redux/store/add_sifaris";
const Form = (props: { data?: any }) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  var data = useAppSelector((state: any) => state.sifaris_types.forms);
  var sort_data =
    data && data.sifarisforms && Object.keys(data).length > 0
      ? [...data.sifarisforms]
      : [];
  const form_fields = useAppSelector(
    (state: any) => state.add_sifaris.form_fields
  );
  useEffect(() => {
    Http.GET("/sifaristypes/" + id, true)
      .then((res: any) => {
        let data = res.data.data;
        dispatch(setSifarisForms(data));
      })
      .catch((err) => {
        toast.error("Error while fetching forms");
      });
  }, [id, dispatch]);
  const input_change = (value: any, name: string) => {
    dispatch(changeFormField({ name, value }));
  };
  return (
    <div
      className="flex"
      style={{
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div>
        <fieldset
          style={{
            border: "1px solid #ccc",
            borderRadius: "5px",
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            backgroundColor: "#fff",
          }}
        >
          <legend
            className="bold"
            style={{
              color: "#1f60ba",
              fontSize: "1.5rem",
              padding: "0 8px",
            }}
          >
            {data.sifarisforms &&
              data.sifarisforms.length > 0 &&
              data.sifarisforms[0]?.form_title}
          </legend>
          <div className={style.form}>
            {sort_data && sort_data.length > 0
              ? sort_data
                  ?.sort((a: any, b: any) =>
                    Number(a.field_order) > Number(b.field_order) ? 1 : -1
                  )
                  .map((p: any) => (
                    <>
                      {p.input_type !== "select" &&
                      p.input_type !== "textarea" ? (
                        <PreviewInputField
                          input_data={{
                            placeholder: p.placeholder,
                            input_type: p.input_type,
                            label: p.label,
                            outer_class: p.outer_class,
                            label_class: p.label_class,
                            input_class: p.input_class,
                            value: form_fields[p.name],
                          }}
                          on_change={(e: any) =>
                            input_change(e.target.value, p.name)
                          }
                        />
                      ) : null}
                      {p.input_type === "select" ? (
                        <PreviewSelectField
                          input_data={{
                            placeholder: p.placeholder,
                            input_type: p.input_type,
                            label: p.label,
                            outer_class: p.outer_class,
                            label_class: p.label_class,
                            input_class: p.input_class,
                          }}
                        />
                      ) : null}
                      {p.input_type === "textarea" ? (
                        <PreviewTextField
                          input_data={{
                            placeholder: p.placeholder,
                            input_type: p.input_type,
                            label: p.label,
                            outer_class: p.outer_class,
                            label_class: p.label_class,
                            input_class: p.input_class,
                            value: props.data ? props.data[p.name] : "",
                          }}
                        />
                      ) : null}
                    </>
                  ))
              : null}
          </div>
        </fieldset>
      </div>
    </div>
  );
};
export default Form;
