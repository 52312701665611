import Header from "../lang_nepali/header";
import Footer from "../lang_nepali/footer";
import Content from "./content";
import Subject from "../lang_nepali/subject";
import "./index.scss";
import SifarisDocLayout from "..";
const Template_Nep_1 = () => {
  let subject = "गैर नाफामुलक संस्था दर्ता प्रमाणपत्र";
  return (
    <SifarisDocLayout>
      <div>
        <Header>
          <Subject subject={subject} />
          <div className="space_between">
            <div>
              <p className="bold">दर्ता नं.:</p>
              <p className="bold">दर्ता मिति:</p>
            </div>
            <p className="box_contain_text">संस्थाको छाप वा फोटो</p>
          </div>
        </Header>
        <Content />
      </div>
      <Footer />
    </SifarisDocLayout>
  );
};
export default Template_Nep_1;
