import ManageLayout from "../../../../component/manage_layout";
import InputField from "../../../../component/input_container";
import SelectInput from "../../../../component/input_container/select_input";
import { useEffect } from "react";
import Http from "../../../../utils/httpClients";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { toast } from "react-toastify";
import { setProvince } from "../../../../redux/store/provinces/province";
import { setSpecificDistrict } from "../../../../redux/store/districts";
import { CenterElement } from "../../../../component/manage_layout/center_element";
import { useNavigate } from "react-router";
interface Props {
  change_select?: any;
  input_change?: any;
  data?: any;
  title: string;
  form_handler?: any;
  province_id?: string;
  update?:boolean;
}
const JillaContainer = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const province_data = useAppSelector((state) => state.province.data);
  const data = useAppSelector((state) => state.district.specific_district);
  const loading = useAppSelector((state) => state.district.loading);
  useEffect(() => {
    if (province_data.length === 0) {
      Http.GET("/provinces", true)
        .then((res) => {
          dispatch(setProvince(res.data.data));
        })
        .catch((err) => {
          toast.error("डाटा प्राप्त गर्दा त्रुटि भएको छ!");
        });
    }
  }, [dispatch]);
  const input_change = (e: any) => {
    dispatch(
      setSpecificDistrict({ name: e.target.name, value: e.target.value })
    );
  };
  const select_change = (name: string, value: string) => {
    dispatch(setSpecificDistrict({ name, value }));
  };
  return (
    <CenterElement>
      <ManageLayout
        form_handler={props.form_handler}
        page_title={"जिल्ला " + props.title}
        on_cancel={() => navigate(-1)}
        form_width="50%"
        loading={loading}
        update={props.update}
      >
        <div
          className="flex center space_between"
          style={{ gap: "1rem", width: "100%", flexDirection: "column" }}
        >
          <SelectInput
            options={province_data?.map((p: any) => {
              return { label: p.province_en, value: p.id };
            })}
            label="Provinces*"
            placeholder="Select Province"
            name="province_id"
            value={province_data
              ?.map((p: any) => {
                return { label: p.province_en, value: p.id };
              })
              .filter((p: any) => {
                return p.value.toString() === data?.province_id?.toString();
              })}
            on_change={(e: any) => select_change("province_id", e.value)}
          />
          <InputField
            placeholder="Enter District Name in English"
            type="text"
            label="District Name (In English)"
            name="district_en"
            value={data?.district_en}
            on_change={input_change}
            required
          />
          <InputField
            placeholder="Enter District Name in Nepali"
            type="text"
            label="District Name (In Nepali)"
            name="district_np"
            value={data?.district_np}
            on_change={input_change}
            required
          />
        </div>
      </ManageLayout>
    </CenterElement>
  );
};
export default JillaContainer;
