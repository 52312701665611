import { FiSearch } from "react-icons/fi";
import { FiFilter } from "react-icons/fi";
import FilterModal from "../filter_modal";
import { useState } from "react";
const TableSubHeader = () => {
  const [filterModal, setFilterModal] = useState(false);
  const filter_modal_handler = () => {
    setFilterModal(!filterModal);
  };
  return (
    <div className="align_items space_between table_sub_header">
      <div className="align_items table_search_input">
        <FiSearch />
        <input
          className="search_input"
          type="text"
          placeholder="Search by number, name, company ..."
        />
      </div>
      <div style={{ position: "relative" }}>
        <div
          className="align_items bold table_widget"
          onClick={filter_modal_handler}
        >
          <FiFilter
            onClick={filter_modal_handler}
            style={{ cursor: "pointer" }}
          />
          <p>Filter</p>
        </div>
        {filterModal ? (
          <FilterModal close_modal={filter_modal_handler} />
        ) : null}
      </div>
    </div>
  );
};
export default TableSubHeader;
