import { useEffect } from "react";
import Http from "../../../../../utils/httpClients";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import JillaContainer from "..";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/hook";
import {
  getSpecificDistrict,
  set_loading_district,
} from "../../../../../redux/store/districts";
import { useNavigate } from "react-router";
const AddJilla = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const data = useAppSelector((state: any) => state.district.specific_district);
  useEffect(() => {
    Http.GET("/districts/" + id, true)
      .then((res) => {
        let data = res.data.data;
        dispatch(
          getSpecificDistrict({
            district_en: data?.district_en,
            district_np: data?.district_np,
            province_id: data?.province?.id,
          })
        );
      })
      .catch((err) => {
        toast.error("जिल्ला डाटा ल्याउने क्रममा त्रुटि भयो!");
      });
  }, [id, dispatch]);
  const form_handler = (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);
    dispatch(set_loading_district(true));
    Http.POST("/province/update/" + id, { data }, true)
      .then((res) => {
        toast.success("District has been updated!");
        dispatch(set_loading_district(false));
        navigate(-1);
      })
      .catch((err) => {
        dispatch(set_loading_district(false));
        toast.error("Error while updating district!");
      });
  };
  return (
    <>
      <JillaContainer
        title="सम्पादन"
        data={data}
        form_handler={form_handler}
        update
      />
    </>
  );
};
export default AddJilla;
