import Signature from "../../lang_english/signature";
import "../../index.scss";
import Subject from "../../lang_english/subject";
import Table from "../../../component/table_container/main_table";
import style from "../../content.module.scss";
const Content = () => {
  const headings = [
    "S.N.",
    "Relations with Applicant",
    "Parties Name",
    "Annual Income",
    "Remarks",
  ];
  let applicant_name = "Venture Four Technology";
  let ward_no = 1;
  let address = "Putalisadak";
  let property_value = 8600000;
  let property_value_in_dollar_rate = 86000;
  return (
    <>
      <div>
        <Subject
          subject="Verification of Annual Income"
          to="Whom it May Concern"
        />
        <p className="paragraph_indent">
          This is to certify that{" "}
          <span className="bold">Mr/Mrs/Miss {applicant_name},</span> permanent
          resident of Kathmandu City, Ward No: {ward_no}, {address}, has income
          from the following sources:
        </p>
        <div className={style.sifaris_docs_table}>
          <Table table_headings={headings}></Table>
        </div>
        <p>
          Total valuation of income source is{" "}
          <span className="bold">NRs. {property_value}</span> which is
          equivalent to{" "}
          <span className="bold">${property_value_in_dollar_rate}</span>
        </p>
        <p>
          Note: Today's exchange rate <span className="bold">$1 = NRs 132</span>
        </p>
      </div>
      <Signature />
    </>
  );
};
export default Content;
