import axios from "axios";
console.log(process.env.REACT_APP_BASE_URL);
const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  responseType: "json",
});

const tokenHandler = () => {
  let localStorage_token = localStorage.getItem("sifaris-token");
  let sessionStorage_token = sessionStorage.getItem("sifaris-token");
  if (localStorage_token) {
    return localStorage_token;
  } else if (sessionStorage_token) {
    console.log();
    return sessionStorage_token;
  }
  return null;
};

// checking header
const getHeaders = (isSecure: Boolean, multi_part?: boolean) => {
  console.log(multi_part);
  let options: any = {
    "content-type": multi_part ? "multipart/form-data" : "application/json",
    Authorization: isSecure ? "Bearer " + tokenHandler() : "",
  };
  return options;
};

// Http functions
function GET(url: string, isSecure: boolean, data?: any) {
  return http.get(url, {
    headers: getHeaders(isSecure),
    data,
  });
}

function POST(url: string, data: any, isSecure: boolean, multi_part?: boolean) {
  return http.post(url, data, {
    headers: getHeaders(isSecure, multi_part),
  });
}

function PUT(url: string, data: any, isSecure: boolean, multi_part?: boolean) {
  return http.put(url, data, {
    headers: getHeaders(isSecure, multi_part),
  });
}

function DELETE(url: string, isSecure: boolean, multi_part?: boolean) {
  return http.delete(url, {
    headers: getHeaders(isSecure, multi_part),
  });
}

export default {
  GET,
  POST,
  PUT,
  DELETE,
};
