import styles from "./index.module.scss";
import { HiArrowUp } from "react-icons/hi";
import CardLayout from "../layout";

const AnalyticalCard = () => {
  return (
    <CardLayout bg_color="white" className={styles.analytical_card}>
      <div className={["price_rate_analysis", styles.align_text].join(" ")}>
        <div className="price_rate_analysis">
          <p className="price">
            <span>रु</span>52,123
          </p>
          <p className="label">Total Fines Collected</p>
        </div>
        <p className="rate">
          <HiArrowUp />
          5.6%
        </p>
      </div>
    </CardLayout>
  );
};
export default AnalyticalCard;
