import { AiOutlineSend } from "react-icons/ai";
import { ButtonLoading } from "./button_loading";
import "./index.scss";
export const SubmitButton = (props: {
  loading?: boolean;
  btn_title?: string;
  loading_title?: string;
  update?: boolean;
}) => {
  return (
    <button
      type="submit"
      disabled={props.loading}
      style={{ cursor: props.loading ? "not-allowed" : "pointer" }}
      className={[
        "primary_button align_items",
        props.loading && "disable_button",
      ].join(" ")}
    >
      {props.loading && <ButtonLoading />}
      {props.loading
        ? props.update
          ? "सम्पादन..."
          : "सिर्जना..."
        : props.update
        ? "सम्पादन"
        : "सिर्जना"}
      {!props.loading && <AiOutlineSend />}
    </button>
  );
};
