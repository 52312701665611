import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  addpermission,
  resetFormPermission,
  set_loading_permissions,
  setpermission,
} from "../../../../redux/store/permissions";
import Container from "../container";
import {
  create_permission,
  get_permissions,
} from "../../../../utils/api/user_configuration/permissions";
const AddPermission = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetFormPermission());
  }, [dispatch]);
  const form_handler = (e: any) => {
    e.preventDefault();
    const form_data = new FormData(e.target);
    dispatch(set_loading_permissions(true));
    create_permission(form_data)
      .then(async (res) => {
        dispatch(addpermission(form_data));
        toast.success("Permission has been created!", {
          position: "bottom-right",
        });
        dispatch(set_loading_permissions(false));
        navigate(-1);
        get_permissions().then((res) => {
          dispatch(setpermission(res.data.data));
        });
      })
      .catch((err) => {
        dispatch(set_loading_permissions(false));
        toast.error("Error occued while creating permission!", {
          position: "bottom-right",
        });
      });
  };
  return (
    <>
      <Container
        form_handler={form_handler}
        title="थप्नुहोस्"
      />
    </>
  );
};
export default AddPermission;
