import React from "react";
import styles from "./tab.module.scss";
import { MdVerified } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { set_tab_id } from "../../redux/store/tabs";
import { useNavigate } from "react-router";
interface Tab {
  title: string;
  content: React.ReactNode;
  exit_button?: boolean;
}

interface TabsProps {
  tabs: Tab[];
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const activeTab = useAppSelector((state: any) => state.tab?.tab_id);
  const checkedTabs = useAppSelector((state: any) => state.tab?.checked_tabs);
  const set_active_tab = (id: number) => {
    dispatch(set_tab_id(id));
  };
  const next_tab = (id: number) => {
    if (id !== 4) dispatch(set_tab_id(id + 1));
  };
  const previous_tab = (id: number) => {
    dispatch(set_tab_id(id - 1));
  };
  return (
    <div className={styles.tabcontainer}>
      <div className={styles.tab}>
        {tabs.map((tab, index) => (
          <button
            className={[
              activeTab === index ? styles.activeTabButton : "",
              styles.tabButton,
            ].join(" ")}
            key={index}
            onClick={() => set_active_tab(index)}
          >
            {tab.title}
            {checkedTabs
              ? checkedTabs?.includes(index) && <MdVerified />
              : null}
          </button>
        ))}
      </div>
      <div className={styles.tabBody}>
        {tabs[activeTab]?.content}
        {tabs[activeTab]?.exit_button && (
          <div className={styles.buttons}>
            <button
              type="button"
              className="primary_button"
              onClick={() => navigate(-1)}
            >
              Exit
            </button>
            <div className={styles.buttons_2}>
              <button
                type="button"
                className="secondary_button"
                onClick={() => previous_tab(activeTab)}
              >
                Previous
              </button>
              {activeTab !== 4 && (
                <button
                  type="button"
                  className="primary_button"
                  onClick={() => next_tab(activeTab)}
                >
                  Next
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tabs;
