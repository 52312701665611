import { useEffect } from "react";
import style from "./index.module.scss";
import Form from "./form";
import Table from "./test";
import Http from "../../utils/httpClients";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { setSifarisForms } from "../../redux/store/sifaris_type";
import { useState } from "react";
import Required from "./required";
import { setFormFields, setTableFields } from "../../redux/store/add_sifaris";
import { FiEye } from "react-icons/fi";
const TemplateForm = (props: { data?: any; button?: boolean }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sifaris_types = useAppSelector(
    (state: any) => state.sifaris_types.forms
  );
  const [table, setTables] = useState<any>();
  useEffect(() => {
    Http.GET("/sifaristypes/" + id, true)
      .then((res: any) => {
        let data = res.data.data;
        console.log(data);
        const groupedArrays = data.sifaristables.reduce(
          (result: any, obj: any) => {
            const tabValue = obj.table_header;
            if (!result[tabValue]) {
              result[tabValue] = [];
            }
            result[tabValue].push(obj);
            return result;
          },
          {}
        );
        setTables(Object.values(groupedArrays));
        dispatch(setSifarisForms(data));
        dispatch(
          setFormFields(
            data.sifarisforms.map((p: any) => {
              return { [p.name]: "" };
            })
          )
        );
        dispatch(
          setTableFields(
            data.sifaristables.map((p: any) => {
              return { [p.name]: "" };
            })
          )
        );
      })
      .catch((err) => {
        toast.error("Error occured while previewing datas");
      });
  }, [dispatch, id]);
  return (
    <div className={style.align_items}>
      {Object.keys(sifaris_types).length > 0 ? (
        <>
          <div
            style={{ flexDirection: "column", gap: "1rem", width: "95%" }}
            className="flex"
          >
            {sifaris_types && (
              <p
                className="bold"
                style={{
                  fontSize: "2rem",
                  textTransform: "capitalize",
                  textAlign: "center",
                  paddingBottom: "1rem",
                }}
              >
                {sifaris_types?.name}
              </p>
            )}
            {sifaris_types.sifarisforms &&
            sifaris_types.sifarisforms.length !== 0 ? (
              <Form />
            ) : null}
            {sifaris_types.sifaristables &&
              sifaris_types.sifaristables.length !== 0 &&
              table &&
              table.map((p: any) => <Table table={p} />)}
            {sifaris_types.sifarisdocuments &&
              sifaris_types.sifarisdocuments.length !== 0 && <Required />}
          </div>
        </>
      ) : null}
      {props.button && (
        <button
          className={[
            "primary_button",
            "flex",
            "center",
            style.send_button,
          ].join(" ")}
          onClick={() => navigate("/sifaris_document/" + id)}
        >
          <FiEye />
          Preview
        </button>
      )}
    </div>
  );
};
export default TemplateForm;
