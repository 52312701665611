import styles from "../modal.module.scss";
interface BtnProps {
  handleClose: () => void;
  action_btn_title?: string;
}
const Buttons = (props: BtnProps) => {
  return (
    <div className={styles.align_button} style={{ marginTop: "2rem" }}>
      <button
        type="button"
        className="secondary_button"
        onClick={props.handleClose}
      >
        Cancel
      </button>
      <button type="submit" className="primary_button">
        {props.action_btn_title}
      </button>
    </div>
  );
};
export default Buttons;
