import Modal from "../../../../../component/customModal";
import "./index.scss";
import style from "../../../index.module.scss";
import { useAppSelector } from "../../../../../hooks/hook";
const Container = (props: {
  children?: JSX.Element[] | JSX.Element;
  title: string;
}) => {
  return (
    <fieldset
      style={{
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <legend
        className="bold"
        style={{
          color: "#1f60ba",
          fontSize: "1.2rem",
          padding: "0 8px",
        }}
      >
        {props.title}
      </legend>
      {props.children}
    </fieldset>
  );
};
const Childs = (props: { label: string; value: string }) => {
  return (
    <div className="input_container">
      <p className="input_label">{props.label}</p>
      <p className="input_p">{props.value}</p>
    </div>
  );
};
const FamilyInfo = (props: { show: boolean; close: any }) => {
  const family_data = useAppSelector((state) => state.public_user.family_data);
  return (
    <Modal
      title={family_data?.fullname_en}
      show={props.show}
      onClose={props.close}
      fix_height
    >
      {family_data && (
        <>
          <div
            style={{
              margin: "1rem auto",
              backgroundImage:"("+process.env.REACT_APP_BASE_URL?.replace('api','storage') + "/"  +
                family_data.person_photo_path +
                ")",
            }}
            className={style.user_image_change}
          />
          <Container title="Basic Information">
            <div className="input_wrapper">
              <Childs label="Full Name(EN)" value={family_data?.fullname_en} />
              <Childs label="Full Name(NP)" value={family_data?.fullname_ne} />
            </div>
            <div className="input_wrapper">
              <Childs label="Date of Birth" value={family_data?.dob} />
              <Childs label="Phone Number" value={family_data?.mobile} />
            </div>
            <div className="input_wrapper">
              <Childs label="E-mail" value={family_data?.email} />
              <Childs label="Gender" value={family_data?.gender?.gender_en} />
            </div>
          </Container>
          <Container title="Identifications">
            <div className="input_wrapper">
              <Childs label="Citizenship No." value={family_data?.ctz_num} />
              <Childs
                label="Citizenship Issue Date"
                value={family_data?.ctz_issuedate}
              />
            </div>
            <Childs
              label="Citizenship Issue Place"
              value={family_data?.ctz_issuedplace}
            />
            <div className="input_wrapper">
              <div
                className={style.upload_file}
                style={{
                  backgroundImage:
                  process.env.REACT_APP_BASE_URL?.replace('api','storage') + "/"  +
                    family_data.ctz_front_path +
                    ")",
                }}
              ></div>
              <div
                className={style.upload_file}
                style={{
                  backgroundImage:
                  process.env.REACT_APP_BASE_URL?.replace('api','storage') + "/"  +
                    family_data.ctz_back_path +
                    ")",
                }}
                id="cc_back_family"
              ></div>
            </div>
          </Container>
        </>
      )}
    </Modal>
  );
};
export default FamilyInfo;
