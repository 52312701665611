import { Route, Routes } from "react-router";
import Permission from "..";
import AddPermission from "../add_permission/index";
import UpdatePermission from "../update_permission";
import ViewPermission from "../view_permission";
export const PermissionsRoutes =() =>(
    <Routes>
        <Route
              path="/user_configuration/permission"
              element={<Permission />}
            />
            <Route
              path="/user_configuration/permission/add"
              element={<AddPermission />}
            />
            <Route
              path="/user_configuration/permission/update/:id"
              element={<UpdatePermission />}
            />
            <Route
              path="/user_configuration/permission/view/:id"
              element={<ViewPermission />}
            />
    </Routes>
)