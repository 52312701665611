import Http from "../../../httpClients";
export const get_llevels = async () => Http.GET("/llevels", true);
export const create_llevel = async (data: any) =>
  Http.POST("/llevels", data, true);
export const update_llevel = async (id: number, data: any) =>
  Http.PUT("/llevels/" + id, data, true);
export const delete_llevel = async (id: number) =>
  Http.DELETE("/llevels/" + id, true);
export const get_llevel = async (id: number) =>
  Http.GET("/llevels/" + id, true);
