import ManageDocument from ".";
import Http from "../../../../utils/httpClients";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { useEffect } from "react";
import { resetRelations } from "../../../../redux/store/family_relations";
const AddRelation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const relations = useAppSelector(
    (state: any) => state.family_relation.specific_family_relation
  );
  useEffect(() => {
    dispatch(resetRelations());
  }, [dispatch]);
  const form_handler = (e: any) => {
    e.preventDefault();
    Http.POST("/relations", relations, true)
      .then((res) => {
        toast.success("Relation has been added!");
        navigate(-1);
      })
      .catch((err) => {
        toast.error(err.data.message);
      });
  };
  return (
    <>
      <ManageDocument
        form_handler={form_handler}
        title="
    सिर्जना"
      />
    </>
  );
};

export default AddRelation;
