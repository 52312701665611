import Table from "../../../../component/table_container/main_table";
import TableHeader from "../../../../component/table_container/table_header";
import TableSubHeader from "../../../../component/table_container/table_sub_header";
import { useState } from "react";
import Modal from "./modal";
import { toast } from "react-toastify";
import DeleteModal from "../../../../component/modal";
import { useParams } from "react-router";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import Http from "../../../../utils/httpClients";
import { setFamilyMemberId } from "../../../../redux/store/public_users";
import { check_tab } from "../../../../redux/store/tabs";
const FamilyRelations = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const family_relations = useAppSelector(
    (state: any) => state.public_user.family_relations
  );
  const public_info = useAppSelector(
    (state: any) => state.public_user.specific_public_user
  );
  const form_handler = (e: any) => {
    e.preventDefault();
    let success = false;
    for (let i = 0; i < family_relations.length; i++) {
      Http.POST(
        "/relations/familymembers",
        { ...family_relations[i], familyprofile_id: Number(id) },
        true
      )
        .then((res) => {
          dispatch(check_tab(4));
          dispatch(setFamilyMemberId(null));
          setModal(false);
          success = true;
        })
        .catch((err) => {
          toast.error("Error occured while manage relations!");
          setModal(false);
        });
      if (success) {
        toast.success("Relation has been set successfully!");
        dispatch(check_tab(4));
      }
    }
  };
  const addDocuments = () => {
    dispatch(setFamilyMemberId(null));
    setModal(true);
  };
  return (
    <div>
      <Modal
        on_submit={form_handler}
        show_modal={modal}
        on_close={() => setModal(false)}
        title="Manage Family Relations"
      />
      <DeleteModal
        open={deleteModal}
        title="Family Relation"
        close_modal={() => setDeleteModal(false)}
      />
      <div className="table_wrapper" style={{ backgroundColor: "#fff" }}>
        <div className="component_wrapper table_container">
          <TableHeader
            primary_button_name="
            Add Relation"
            table_title="Family Relations"
            sub_title="Manage family relations of public user"
            on_add={addDocuments}
          />
          <TableSubHeader placeholder="Search your relation ...." />
          <Table table_headings={["S.N.", "From", "Relation", "To", "Actions"]}>
            {public_info &&
            public_info.familyrelationship &&
            public_info.familyrelationship.length > 0 ? (
              public_info.familyrelationship.map((p: any, i: number) => (
                <tr>
                  <td>{i + 1}</td>
                  <td>{p.fromfamilymember?.fullname_en}</td>
                  <td>{p.relation?.relation_en}</td>
                  <td>{p.tofamilymember?.fullname_en}</td>
                  <td className="align_items table_icons">
                    <AiOutlineDelete />
                    <FiEdit2 />
                  </td>
                </tr>
              ))
            ) : (
              <td colSpan={5} className="no_match_text">
                No family relation has been set yet.
              </td>
            )}
          </Table>
        </div>
      </div>
    </div>
  );
};
export default FamilyRelations;
