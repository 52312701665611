import UserContainer from "..";
import Http from "../../../../../utils/httpClients";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getSpecificuser,
  setUser,
  set_loading_user,
  show_data_users,
  updateuser,
} from "../../../../../redux/store/user";
import { FaUserEdit } from "react-icons/fa";
import {
  get_user,
  get_users,
  update_user,
} from "../../../../../utils/api/user_configuration/user";
const AddUser = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (id !== undefined) {
      get_user(id?.toString())
        .then((res) => {
          let data = res.data.data;
          dispatch(
            getSpecificuser({
              ...data,
            })
          );
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error occured while fetching user's data!");
        });
    }
  }, [id, dispatch]);
  const form_handler = async (e: any) => {
    e.preventDefault();
    const form_data = new FormData(e.target);
    if (id !== undefined) {
      dispatch(set_loading_user(true));
      update_user(id?.toString(), form_data)
        .then(async (res) => {
          console.log(res);
          dispatch(set_loading_user(false));
          dispatch(updateuser(res.data.user));
          toast.success("User has been updated!");
          await get_users().then((res) => {
            dispatch(setUser(res.data.data));
            dispatch(show_data_users());
          });
          navigate(-1);
        })
        .catch((err) => {
          dispatch(set_loading_user(false));
          toast.error("Error occured while creating local level");
        });
    }
  };
  return (
    <>
      <UserContainer
        form_handler={form_handler}
        title="सम्पादन"
        update
        icon={<FaUserEdit />}
      />
    </>
  );
};
export default AddUser;
