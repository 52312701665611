import { useEffect } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../hooks/hook";
import {
  setSpecificofficetype,
  set_loading_officetype,
} from "../../../../redux/store/office_type";
import Http from "../../../../utils/httpClients";
import OfficeTypeContainer from "../container";
const AddOfficeType = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setSpecificofficetype(""));
  }, []);
  const form_handler = (e: any) => {
    e.preventDefault();
    const form = new FormData(e.target);
    dispatch(set_loading_officetype(true));
    Http.POST("/officetypes", { office_type: form.get("office_type") }, true)
      .then((res) => {
        toast.success("Office type has been added");
        dispatch(set_loading_officetype(false));
        navigate(-1);
      })
      .catch((err) => {
        dispatch(set_loading_officetype(false));

        toast.error("Error occured while adding office type!");
      });
  };
  return <OfficeTypeContainer title="सिर्जना" form_handler={form_handler} />;
};
export default AddOfficeType;
