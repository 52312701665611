import Http from "../../../../../utils/httpClients";
import { toast } from "react-toastify";
import ProvinceContainer from "..";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../../../../hooks/hook";
import {
  addProvince,
  setProvince,
  set_loading_province,
} from "../../../../../redux/store/provinces/province";
const AddProvince = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const form_handler = async (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);
    await Http.POST("/provinces", data, true)
      .then(async (res) => {
        dispatch(set_loading_province(true));
        Http.GET("/provinces", true).then((res) => {
          dispatch(setProvince(res.data.data));
        });
        toast.success("प्रदेश सिर्जना गरिएको छ!");
        dispatch(addProvince(data));
        dispatch(set_loading_province(false));
        navigate(-1);
      })
      .catch((err) => {
        dispatch(set_loading_province(false));
        toast.error("प्रदेश सिर्जना गर्दा त्रुटि उत्पन्न भयो!");
      });
  };
  return (
    <>
      <ProvinceContainer
        title="सिर्जना"
        form_handler={form_handler}
      />
    </>
  );
};
export default AddProvince;
