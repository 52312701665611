import { createSlice } from "@reduxjs/toolkit";
interface TabState {
  tab_id: any;
  checked_tabs: any;
}

const initialState: TabState = {
  tab_id: 0,
  checked_tabs: [],
};

export const tabSlice = createSlice({
  name: "tabSlice",
  initialState,
  reducers: {
    set_tab_id: (state, action) => {
      state.tab_id = action.payload;
    },
    check_tab: (state, action) => {
      state.checked_tabs = [...state.checked_tabs, action.payload];
    },
    remove_check: (state, action) => {
      state.checked_tabs = state.checked_tabs.filter(
        (p: any) => p.toString() !== action.payload.toString()
      );
    },
    remove_checks: (state) => {
      state.checked_tabs = [];
    },
  },
});

export const { set_tab_id, check_tab, remove_checks,remove_check } = tabSlice.actions;

export default tabSlice.reducer;
