import DummyImg from "../../../../assest/images/fummy.jpg";
import { MdKeyboardArrowRight } from "react-icons/md";
import styles from "./index.module.scss";
const DataItems = () => {
  return (
    <div className={styles.supplier_data_items}>
      <div className={styles.supplier_user_detail}>
        <img src={DummyImg} alt="user_img" className={styles.supplier_user_img} />
        <div className={styles.supplier_user}>
          <p className={["bold_text", styles.regular_size_text].join(" ")}>User Name</p>
          <p className="small_light_text">Location</p>
        </div>
      </div>
      <p className={["bold_text", styles.regular_size_text].join(" ")}>Rs. 1,421</p>
    </div>
  );
};
const Suppliers = () => {
  return (
    <div className={["component_wrapper" ,styles.supplier].join(" ")}>
      <p className="bold_text" style={{ fontSize: "16px" }}>
        Suppliers
      </p>
      <div className={styles.items}>
        <DataItems />
        <DataItems />
        <DataItems />
        <DataItems />
        <DataItems />
        <DataItems />
      </div>
      <div className={styles.see_all_items}>
        <p>See all supliers</p>
        <MdKeyboardArrowRight />
      </div>
    </div>
  );
};
export default Suppliers;
