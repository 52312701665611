import style from "./index.module.scss";
import MainCard from "./component/main_card";
import AnalyticalCard from "./component/analytical_card";
const Cards = () => {
  return (
    <div className={style.cards}>
      <MainCard />
      <AnalyticalCard />
      <AnalyticalCard />
      <AnalyticalCard />
    </div>
  );
};
export default Cards;
