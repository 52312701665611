import Signature from "../../lang_nepali/signature";
import style from "../../content.module.scss";
import Table from "../../../component/table_container/main_table";
const Content = () => {
  let headings = ["क्र.सं.", "कित्ता नं"];

  return (
    <>
      श्री ....................
      <br />
      ................... |
      <p className="paragraph_indent">
        .................... महा/उपमहा/नगरपालिका/गाउँपालिका वडा नं.
        .................... (साबिकको ठेगाना ....................) बस्ने
        श्री/सुश्री/श्रीमती .................... को नाममा दर्ता कायम रहेको
        कि.नं. .................... को जग्गामा मिति .................... मा भवन
        निर्माण स्विकृत्ति लिनु भई आंशिक/पूर्ण रुपमा निर्माण सम्पन्न गर्नुभएको
        वा अभिलेखीकरण गर्नुभएको हुँदा निजलाई .................. एम्पिएर क्षमताको
        .................. बिजुलीको लाईन जडान गरिदिन हुन सिफारिस साथ अनुरोध
        गरिन्छ|
      </p>
      <div className={style.sifaris_docs_table}>
        <h4
          style={{
            textAlign: "center",
            marginBottom: "1rem",
            textDecoration: "underline",
          }}
        >
          कित्ता नं को विवरण
        </h4>
        <Table table_headings={headings}></Table>
      </div>
      <Signature />
      <div style={{ marginTop: "200px" }}>
        बोधार्थ:-
        <br />
        <p>
          <span>....................</span>
          <span style={{ marginLeft: "400px" }}>- जानकारीको लागि |</span>
        </p>
      </div>
    </>
  );
};
export default Content;
