import { createSlice } from "@reduxjs/toolkit";
interface OfficeState {
  data: Array<Object>;
  office_type: string;
  loading: boolean;
}

const initialState: OfficeState = {
  data: [],
  office_type: "",
  loading: false,
};

export const officeTypeSlice = createSlice({
  name: "officeTypeSlice",
  initialState,
  reducers: {
    set_loading_officetype: (state, action) => {
      state.loading = action.payload;
    },
    setofficetype: (state, action) => {
      state.data = action.payload;
    },
    addofficetype: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    setSpecificofficetype: (state, action) => {
      state.office_type = action.payload;
    },
    deleteofficetype: (state, action) => {
      state.data = [
        ...state.data.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
    },
  },
});

export const {
  setofficetype,
  addofficetype,
  deleteofficetype,
  setSpecificofficetype,
  set_loading_officetype
} = officeTypeSlice.actions;

export default officeTypeSlice.reducer;
