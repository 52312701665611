import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface OfficeState {
  data: Array<Object>;
  designation: string;
  loading?: boolean;
}

const initialState: OfficeState = {
  data: [],
  designation: "",
  loading: false,
};

export const designationSlice = createSlice({
  name: "designationSlice",
  initialState,
  reducers: {
    set_loading_designation: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setdesignation: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    adddesignation: (state, action: PayloadAction<any>) => {
      state.data = [...state.data, action.payload];
    },
    setSpecificdesignation: (state, action) => {
      state.designation = action.payload;
    },
    deletedesignation: (state, action: PayloadAction<number>) => {
      state.data = [
        ...state.data.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
    },
  },
});

export const {
  setdesignation,
  adddesignation,
  deletedesignation,
  setSpecificdesignation,
  set_loading_designation,
} = designationSlice.actions;

export default designationSlice.reducer;
