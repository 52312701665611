import { MdOutlineModeEdit } from "react-icons/md";
import { useNavigate } from "react-router";

export const UpdateButton = (props: { url: string }) => {
  const navigate = useNavigate();
  return (
    <button
      className="primary_button align_items"
      onClick={() => navigate(props.url)}
    >
      <MdOutlineModeEdit />
      Update
    </button>
  );
};
