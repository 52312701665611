import "./index.scss";
const Signature = () => {
  return (
    <div className="nep_signature">
      <p>............................</p>
      <p>वडा अध्यक्ष</p>
    </div>
  );
};
export default Signature;
