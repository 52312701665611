import { toast } from "react-toastify";
import LocalLevelContainer from "..";
import { useNavigate } from "react-router";
import { create_llevel } from "../../../../../utils/api/federal_stucture/llevel";
import { useAppDispatch } from "../../../../../hooks/hook";
import { set_loading_llevel } from "../../../../../redux/store/local_level";
const AddLocalLevel = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const form_handler = async (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);
    dispatch(set_loading_llevel(true));
    create_llevel(data)
      .then((res) => {
        toast.success("Local level has been created!");
        dispatch(set_loading_llevel(false));
        navigate(-1);
      })
      .catch((err) => {
        dispatch(set_loading_llevel(false));

        toast.error("Error occured while creating local level");
      });
  };
  return (
    <>
      <LocalLevelContainer
        title="सिर्जना"
        form_handler={form_handler}
      />
    </>
  );
};
export default AddLocalLevel;
