import "./index.scss";
const Footer = () => {
  let phone_num = "४४६००४";
  let fax_num = "५५५-१२३-४५६७";
  let email = "nepal@gov.com";
  let website = "nepalgov.com.np";
  return (
    <div className="footer">
      <hr />
      <p>
        पुतलीसडक, काठमाडौँ, नेपाल, फोन नॱ. {phone_num}, फ्याक्स: {fax_num}
      </p>
      <p className="font_size_medium">
        इमेल: {email}, वेभसाइट: {website}
      </p>
    </div>
  );
};
export default Footer;
