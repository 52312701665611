import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/hook";
import Http from "../../../../../utils/httpClients";
import { useNavigate } from "react-router";
import ManageRoles from "..";
import { useEffect } from "react";
import {
  resetRoles,
  set_loading_role,
  setrole,
} from "../../../../../redux/store/roles";
import { create_role } from "../../../../../utils/api/user_configuration/roles";
const AddRoles = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const role = useAppSelector((state: any) => state.roles.specific_role);
  useEffect(() => {
    dispatch(resetRoles());
  }, [dispatch]);
  const form_handler = (e: any) => {
    e.preventDefault();
    dispatch(set_loading_role(true));
    create_role(role)
      .then((res) => {
        navigate(-1);
        dispatch(set_loading_role(false));
        toast.success("Roles has been added!");
        Http.GET("/roles", true).then((res) => {
          console.log(res);
          dispatch(setrole(res.data.data));
        });
      })
      .catch((err) => {
        dispatch(set_loading_role(false));
        toast.error("Error occured while sending roles!");
      });
  };
  return <ManageRoles form_handler={form_handler} title="सिर्जना" />;
};
export default AddRoles;
