import ManageDocument from ".";
import Http from "../../../../utils/httpClients";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { useEffect } from "react";
import {
  setdocument,
  setSpecificdocument,
} from "../../../../redux/store/document";
const AddDocument = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setSpecificdocument(""));
  }, [dispatch]);
  const form_handler = (e: any) => {
    e.preventDefault();
    const form_data = new FormData(e.target);
    Http.POST("/documents", { doc_name: form_data.get("doc_name") }, true)
      .then(async (res) => {
        await Http.GET("/documents", true).then((res) => {
          dispatch(setdocument(res.data.data));
        });
        toast.success("Document has been added!");
        navigate("/system_configuration/document");
      })
      .catch((err) => {
        toast.error("Error occured while creating document!");
      });
  };
  return (
    <>
      <ManageDocument
        form_handler={form_handler}
        title="
    सिर्जना"
      />
    </>
  );
};

export default AddDocument;
