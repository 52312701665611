import Signature from "../../lang_english/signature";
import "../../index.scss";
import Subject from "../../lang_english/subject";
const Content = () => {
  let applicant_name = "Venture Four Technology";
  let ward_no = 1;
  let plot_no = 34;
  let address = "Putalisadak";
  return (
    <>
      <div>
        <Subject subject="Tax Clearance Certificate" to="Whom it May Concern" />
        <p className="paragraph_indent">
          This is to certify that{" "}
          <span className="bold">Mr/Mrs/Miss {applicant_name} </span>has paid
          all Bussiness Tax, Rental Tax, House and Land Tax/Integrated Property
          Tax ({address} & Plot No. {plot_no} Ward No. {ward_no}) as per rule of
          Kathmandu Metropolitian Office on fiscal year{" "}
          <span>.......................</span>B.S.
        </p>
      </div>

      <Signature />
    </>
  );
};
export default Content;
