import { useEffect } from "react";
import Http from "../../../../utils/httpClients";
import { useParams } from "react-router";
import { useAppDispatch } from "../../../../hooks/hook";
import { getSpecificpublicuser } from "../../../../redux/store/public_users";
import AccountSettingsContainer from ".";
import { toast } from "react-toastify";
import { check_tab, set_tab_id } from "../../../../redux/store/tabs";
const AccountSettings = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    Http.GET("/familyprofiles/" + id, true).then((res) => {
      let data = res.data.data;
      dispatch(check_tab(0));
      if (data.familydocuments.length > 0) {
        dispatch(check_tab(1));
      }
      if (data.familymembers.length > 0) {
        dispatch(check_tab(2));
      }
      if (data.familymemberdocuments.length > 0) {
        dispatch(check_tab(3));
      }
      if (data.familyrelationship.length > 0) {
        dispatch(check_tab(4));
      }
      dispatch(
        getSpecificpublicuser({
          ...data,
          permanent_province_id: data.permanentprovince.id,
          permanent_district_id: data.permanentdistrict.id,
          permanent_locallevel_id: data.permanentlocallevel.id,
          temporary_province_id: data.temporaryprovince.id,
          temporary_district_id: data.temporarydistrict.id,
          temporary_locallevel_id: data.temporarylocallevel.id,
        })
      );
    });
  }, [dispatch, id]);
  const user_info_update = (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);
    Http.PUT("/familyprofiles/" + id, data, true)
      .then((res) => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        toast.success("Family profile has been updated!", {
          position: "bottom-right",
        });
        dispatch(set_tab_id(1));
      })
      .catch((err) => {
        toast.success("Error while updating family profile!", {
          position: "bottom-right",
        });
      });
  };
  return (
    <AccountSettingsContainer
      form_submit={user_info_update}
      btn_title="Update"
    />
  );
};
export default AccountSettings;
