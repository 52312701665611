import styles from "./index.module.scss";
interface BackdropProps {
  children: JSX.Element | JSX.Element[];
  open: Boolean;
}
const BlackBackdrop = (props: BackdropProps) => {
  return (
    <div
      className={styles.backdrop}
      style={{ display: props.open ? "block" : "none" }}
    >
      {props.children}
    </div>
  );
};
export default BlackBackdrop;
