import Header from "../lang_nepali/header";
import Footer from "../lang_nepali/footer";
import Subject from "../lang_nepali/subject";
import Content from "./content";
import SifarisDocLayout from "..";
const TemplateNep11 = () => {
  let subject = "बिजुली जडान सिफारिस";
  return (
    <SifarisDocLayout>
      <div className="template_content_gap" style={{ gap: "1rem" }}>
        <Header>
          <Subject subject={subject} />
        </Header>
        <Content />
      </div>
      <Footer />
    </SifarisDocLayout>
  );
};
export default TemplateNep11;
