import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  setCurrentState,
  setWidth,
} from "../../../redux/store/sifaris_progress";
import {
  setSifarisTableHeader,
  setSifarisTableTitle,
} from "../../../redux/store/sifaris_type";
import style from "../index.module.scss";
import { TitleElement, InputFieldElement, PrevNxtButtons } from "../index";
const TableTitle = () => {
  const dispatch = useAppDispatch();
  const form_handler = (e: any) => {
    e.preventDefault();
    dispatch(setCurrentState(4));
    dispatch(setWidth(3));
  };
  const table_title = useAppSelector(
    (state: any) => state.sifaris_types.table_title
  );
  const table_header = useAppSelector(
    (state: any) => state.sifaris_types.table_header
  );
  const input_changeHandler = (e: any) => {
    if (e.target.name === "table_title") {
      dispatch(setSifarisTableTitle(e.target.value));
    }
    if (e.target.name === "table_header") {
      dispatch(setSifarisTableHeader(e.target.value));
    }
  };
  return (
    <form
      autoComplete="off"
      onSubmit={form_handler}
      style={{ padding: "3% 0" }}
    >
      <TitleElement
        title="Sifaris Table"
        sub_title="Please enter table title & table headings that denote table*"
      />
      <InputFieldElement
        name="table_title"
        title="table title"
        placeholder="Enter table title"
        on_change={input_changeHandler}
        value={table_title}
      />
      {/* <div className={style.reduce_padding}>
        <InputFieldElement
          name="table_header"
          title="table headings"
          placeholder="Enter table headings"
          on_change={input_changeHandler}
          value={table_header}
        />
      </div> */}
      <PrevNxtButtons
        skip
        skip_state={5}
        prev_action={() => dispatch(setCurrentState(2))}
        next_action={form_handler}
      />
    </form>
  );
};
export default TableTitle;
