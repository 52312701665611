import Table from "../../../component/table_container/main_table";
import TableHeader from "../../../component/table_container/table_header";
import TableSubHeader from "../../../component/table_container/table_sub_header";
import { useEffect, useState } from "react";
import Http from "../../../utils/httpClients";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { FiEdit2, FiUser } from "react-icons/fi";
import { useNavigate } from "react-router";
import Modal from "../../../component/modal";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  search_items,
  set_current_page,
  set_items_per_page,
  setrole,
  show_data_roles,
} from "../../../redux/store/roles";
import { toast } from "react-toastify";
import {
  delete_role,
  get_roles,
} from "../../../utils/api/user_configuration/roles";
const Role = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState<Boolean>(false);
  const [delete_id, setDeleteId] = useState<number>();
  const data = useAppSelector((state: any) => state.roles.filtered_data);
  const items = useAppSelector((state: any) => state.roles.data);
  const filtered_data = useAppSelector(
    (state: any) => state.roles.filtered_data
  );
  const items_per_page = useAppSelector(
    (state: any) => state.roles.items_per_page
  );
  const current_page = useAppSelector((state: any) => state.roles.current_page);
  console.log(data);
  useEffect(() => {
    if (data.length === 0) {
      get_roles()
        .then((res) => {
          console.log(res);
          dispatch(show_data_roles());
          dispatch(setrole(res.data.data));
        })
        .catch((err) => {
          toast.error("Error while fetching roles!");
        });
    }
  }, [dispatch]);
  const deleteOffice = async () => {
    if (delete_id !== undefined && delete_id !== null) {
      delete_role(delete_id.toString())
        .then(async (res) => {
          toast.success("Role has been deleted successfully!");
          setModal(false);
          await get_roles().then((res) => {
            dispatch(setrole(res.data.data));
          });
        })
        .catch((err) => {
          toast.error("Error occured while deleting role!");
        });
    }
  };
  const deleteHandler = (id: number) => {
    setDeleteId(id);
    setModal(true);
  };
  const show_items = (e: any) => {
    dispatch(set_items_per_page(e.value));
    dispatch(show_data_roles());
    console.log(e);
  };
  const page_change = (event: any, pageNumber: any) => {
    dispatch(set_current_page(pageNumber));
    dispatch(show_data_roles());
    console.log(pageNumber);
  };
  const change_input = (e: any) => {
    dispatch(search_items(e.target.value));
    dispatch(show_data_roles());
  };
  return (
    <>
      <Modal
        close_modal={() => setModal(false)}
        action={deleteOffice}
        open={modal}
      />
      <div className="table_wrapper">
        <div className="component_wrapper table_container">
          <TableHeader
            icon={<FiUser />}
            primary_button_name="
          भूमिका थप्नुहोस्"
            table_title="भूमिका"
            on_add={() => navigate("add")}
            items_length={items ? items.length : 0}
          />
          <TableSubHeader
            item_select={items.length > 10}
            no_of_items={show_items}
            placeholder="Search roles...."
            input_handler={change_input}
          />
          <Table
            page_change={page_change}
            page_count={Math.ceil(filtered_data.length / items_per_page)}
            current_page={current_page}
            pagination_display={data.length >= 10}
            item_range={
              data.length * (current_page - 1) +
              "-" +
              current_page * data.length +
              " items"
            }
            table_headings={[
              "s.n.",
              "role name",
              "last modified by",
              "actions",
            ]}
          >
            {data.length > 0 ? (
              data?.map((p: any, i: any) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{p.role}</td>
                  <td>{p.updated_at ? p.updated_at : "Not Modified Yet"}</td>
                  <td className="align_items table_icons">
                    <AiOutlineEye
                      data-tooltip-id="view"
                      data-tooltip-content="View"
                      onClick={() => navigate("view/" + p.id)}
                    />
                    <AiOutlineDelete
                      data-tooltip-id="view"
                      data-tooltip-content="Delete"
                      onClick={() => deleteHandler(p.id)}
                    />
                    <FiEdit2
                      data-tooltip-id="view"
                      data-tooltip-content="Update"
                      onClick={() => navigate("update/" + p.id)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <td colSpan={4} className="no_match_text">
                No Search result found for roles.
              </td>
            )}
          </Table>
        </div>
      </div>
    </>
  );
};
export default Role;
