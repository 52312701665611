import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import { ToastContainer, toast } from "react-toastify";
import AllRoutes from "./route/app.routing";
import { useOnlineStatus } from "./utils/utitlity";
import { useEffect } from "react";
function App() {
  const isOnline = useOnlineStatus();
  useEffect(() => {
    if (!isOnline) {
      toast.error("तपाईंको नेटवर्क विच्छेद गरिएको छ!");
    }
  }, [isOnline]);
  return (
    <div className="app">
      <Router>
        <AllRoutes />
        <ToastContainer
          position="bottom-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="colored"
        />
      </Router>
    </div>
  );
}

export default App;
