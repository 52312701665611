import Http from "../../../httpClients";
export const get_districts = async () => Http.GET("/districts", true);
export const create_district = async (data: any) =>
  Http.POST("/districts", data, true);
export const update_district = async (id: number, data: any) =>
  Http.PUT("/districts/" + id, data, true);
export const delete_district = async (id: number) =>
  Http.DELETE("/districts/" + id, true);
export const get_district = async (id: number) =>
  Http.GET("/districts/" + id, true);
