import Table from "../../../component/table_container/main_table";
import { useAppSelector } from "../../../hooks/hook";
import Signature from "../../lang_nepali/signature";
import style from "../../content.module.scss";
const Content = () => {
  const content_data = useAppSelector(
    (state: any) => state.add_sifaris.form_fields
  );
  const table_data = useAppSelector((state) => state.add_sifaris.table_rows);
  console.log(table_data);
  return (
    <>
      <div style={{ marginBottom: "4rem" }}>
        <p className="paragraph_indent">
          काठमाडौँ महानगरपालिका वडा नं. {content_data.permanent_ward} को{" "}
          {content_data.permanentlocallevel} ({content_data.address}) मा रहेका श्री{" "}
          {content_data.fullname_en} नाममा रहेको निम्न उल्लेखित व्यवसाय मिति{" "}
          {content_data.start_date} देखि बन्द भएको भनी निजले पेश गरेको निवेदन
          उपर स्थल सर्जमिन बुझ्दा मिति {content_data.end_date} को स्थलगत सर्जमिन
          बमोजिम निजले पेश गरेको व्यहोरा मनासिब देहिएको हुँदा सोहि प्रमाणित
          गरिन्छ |
        </p>
        <div className={style.sifaris_docs_table}>
          <Table
            table_headings={[
              "क्र.स.",
              "व्यवसायको प्रकार",
              "व्यवसायको प्रकृति",
              "वडा नं.",
              "दर्ता नं.",
              "टोलको नाम",
              "बाटोको नाम",
              "घर नं.",
            ]}
          >
            {table_data.map((data: any, index: number) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{data.bussiness_types}</td>
                  <td>{data.bussiness_nature}</td>
                  <td>{data.ward_no}</td>
                  <td>{data.registration_number}</td>
                  <td>{data.tole}</td>
                  <td>{data.street_name} </td>
                  <td>{data.house_no}</td>
                </tr>
              );
            })}
          </Table>
        </div>
      </div>
      <Signature />
    </>
  );
};
export default Content;
