import { createSlice } from "@reduxjs/toolkit";
interface DocumentState {
  data: Array<Object>;
  specific_document: {
    name: string;
  };

  show_data: Array<Object>;
  items_per_page: any;
  current_page: any;
  filtered_data: Array<Object>;
}

const initialState: DocumentState = {
  data: [],
  specific_document: {
    name: "",
  },
  show_data: [],
  items_per_page: 10,
  current_page: 1,
  filtered_data: [],
};

export const documentSlice = createSlice({
  name: "documentSlice",
  initialState,
  reducers: {
    show_data_document: (state) => {
      const startIndex = (state.current_page - 1) * state.items_per_page;
      const endIndex = startIndex + state.items_per_page;
      const displayData = state.filtered_data.slice(startIndex, endIndex);
      state.show_data = displayData;
    },
    search_items: (state, action) => {
      state.filtered_data =
        action.payload.trim().length !== 0
          ? state.data.filter((p: any) => {
              return p.doc_name.includes(action.payload);
            })
          : state.data;
      state.current_page = 1;
    },
    set_items_per_page: (state, action) => {
      state.items_per_page = action.payload;
    },
    set_current_page: (state, action) => {
      state.current_page = action.payload;
    },

    setdocument: (state, action) => {
      state.data = action.payload;
      state.filtered_data = action.payload;
    },
    adddocument: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    setSpecificdocument: (state, action) => {
      state.specific_document = action.payload;
    },
    deletedocument: (state, action) => {
      state.data = [
        ...state.data.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
      state.filtered_data = [
        ...state.filtered_data.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
    },
  },
});

export const {
  setdocument,
  adddocument,
  deletedocument,
  setSpecificdocument,
  set_current_page,
  set_items_per_page,
  search_items,
  show_data_document,
} = documentSlice.actions;

export default documentSlice.reducer;
