import ProvinceContainer from "..";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useParams } from "react-router";
import Http from "../../../../../utils/httpClients";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/hook";
import {
  getSpecificProvince,
  setProvince,
  setSpecificProvince,
  set_loading_province,
} from "../../../../../redux/store/provinces/province";
const UpdatePradesh = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const data = useAppSelector((state: any) => state.province.specific_province);
  useEffect(() => {
    Http.GET("/provinces/" + id, true)
      .then((res) => {
        let data = res.data.data;
        dispatch(
          getSpecificProvince({
            province_en: data.province_en,
            province_np: data.province_np,
          })
        );
      })
      .catch((err) => {
        toast.error("निर्दिष्ट प्रदेश ल्याउँदा त्रुटि उत्पन्न भयो");
      });
  }, [id, dispatch]);
  const input_handler = (e: any) => {
    dispatch(
      setSpecificProvince({ name: e.target.name, value: e.target.value })
    );
  };
  const updateFormHandler = async (e: any) => {
    e.preventDefault();
    dispatch(set_loading_province(true));
    await Http.PUT(
      "/provinces/" + id,
      { province_en: data.province_en, province_np: data.province_np },
      true
    )
      .then((res) => {
        dispatch(set_loading_province(false));
        Http.GET("/provinces", true).then((res) => {
          dispatch(setProvince(res.data.data));
        });
        navigate(-1);
        toast.success("प्रदेश सफलतापूर्वक सम्पादन गरिएको छ!");
      })
      .catch((err) => {
        dispatch(set_loading_province(false));
        toast.error("प्रदेश सम्पादन गर्दा त्रुटि भयो!");
      });
  };
  return (
    <>
      {data ? (
        <ProvinceContainer
          title="सम्पादन"
          input_change={input_handler}
          province_en={data?.province_en}
          province_np={data?.province_np}
          update
          form_handler={updateFormHandler}
        />
      ) : null}
    </>
  );
};
export default UpdatePradesh;
