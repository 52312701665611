import "./index.scss";
import { Link } from "react-router-dom";
function Home(props: { path?: any; title?: string }) {
  return (
    <div className="card_component">
      <Link to={props.path} className="component">
        <div className="cardholder border">
          <div className="cards ">
            <img
              src={`https://ui-avatars.com/api/?background=EFEFEF&color=000000&name=JennyWilson`}
              alt="profile"
              className=""
              style={{ borderRadius: "50%" }}
            />
            <div className="text">
              <p className="desc">{props.title}</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default Home;
