import style from "./index.module.scss";
import React, { useState } from "react";
import InputField from "./input_field";
import SelectInput from "./input_field/select_input";
import OutputInputField from "./output_input_field";
import OutputSelectField from "./output_input_field/selectinput";
import "./index.scss";
import TextArea from "./output_input_field/textarea";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import {
  duplicateInputField,
  resetDynamicInputField,
  setFormInputField,
  setSifarisForm,
  set_drag_ids,
  updateFormInputField,
  updateSifarisForm,
} from "../../redux/store/sifaris_type";
interface formProps {
  on_submit?: any;
  forms?: any;
  title?: string;
  delete_handler?: any;
  preview_title?: string;
  create_title?: string;
  status?: string;
  form_title?: string;
  table_headings?: string;
  status_en?: string;
  current_status?: string;
  table?: boolean;
}
const options = [
  { value: "text", label: "Text" },
  { value: "number", label: "Number" },
  { value: "email", label: "E-mail" },
  { value: "date", label: "Date" },
  { value: "textarea", label: "Text Area" },
  { value: "select", label: "Select" },
];
const DynamicForm = (props: formProps) => {
  const dispatch = useAppDispatch();
  const input_fields = useAppSelector(
    (state) => state.sifaris_types.form_input_field
  );
  let form_data = useAppSelector((state) => state.sifaris_types.sifaris_forms);
  const drag_ids = useAppSelector((state) => state.sifaris_types.dragIds);
  let data =
    form_data.length > 0
      ? [...form_data].sort((a: any, b: any) =>
          Number(a.field_order) > Number(b.field_order) ? 1 : -1
        )
      : form_data;
  const [edit, setEdit] = useState({
    edit_id: 0,
    edit_data: [{}],
  });
  const [current_action, setCurrentAction] = useState<string>("confirm");
  const input_change_handler = (e: any) => {
    e.preventDefault();
    if (e.target.name === "name") {
      dispatch(setFormInputField({ name: "name", value: e.target.value }));
      dispatch(setFormInputField({ name: "input_id", value: e.target.value }));
    } else {
      dispatch(
        setFormInputField({ name: e.target.name, value: e.target.value })
      );
    }
  };
  const select_input_handler = (p: any) => {
    dispatch(setFormInputField({ name: "input_type", value: p.value }));
  };
  const form_handler = (e: any) => {
    e.preventDefault();
    if (current_action === "confirm") {
      dispatch(setSifarisForm());
      dispatch(resetDynamicInputField());
    }
    if (current_action === "edit") {
      let copy_data = [...data];
      copy_data[edit.edit_id] = { ...input_fields };
      dispatch(updateSifarisForm([...copy_data]));
      setCurrentAction("confirm");
      dispatch(resetDynamicInputField());
    }
  };
  const editInputField = (id: number) => {
    setCurrentAction("edit");
    dispatch(updateFormInputField(data[id]));
    setEdit({ edit_id: Number(id), edit_data: data });
  };
  const deleteInputField = (id: number) => {
    let copy_data = data.filter((p: any, i: number) => i !== id);
    if (props.current_status === "Creation") {
      dispatch(updateSifarisForm([...copy_data]));
    }
    if (props.current_status === "Update") {
      props.delete_handler({ data: copy_data, id });
    }
  };
  const duplicatefield = (id: number) => {
    dispatch(duplicateInputField(data[id]));
  };
  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    id: number
  ) => {
    dispatch(set_drag_ids({ name: "start_id", value: id.toString() }));
  };
  const handleDragEnter = (
    event: React.DragEvent<HTMLDivElement>,
    id: number
  ) => {
    dispatch(set_drag_ids({ name: "end_id", value: id.toString() }));
  };
  const handleDragEnd = () => {
    let copy_data = [...data];
    let start_data = data[Number(drag_ids?.start_id)];
    let end_data = data[Number(drag_ids?.end_id)];
    copy_data[Number(drag_ids?.start_id)] = {
      ...start_data,
      field_order: end_data.field_order,
    };
    copy_data[Number(drag_ids?.end_id)] = {
      ...end_data,
      field_order: start_data.field_order,
    };
    dispatch(updateSifarisForm([...copy_data]));
  };
  return (
    <div className="component_wrapper">
      <p className="title">
        {props.title} {props.status}
      </p>
      <div className={[style.align_screen].join(" ")}>
        <div>
          <p
            style={{
              fontSize: "1.3rem",
              textAlign: "center",
              fontWeight: "500",
            }}
          >
            {props.form_title}
          </p>
          <div className={style.align_form}>
            <form
              autoComplete="off"
              className={style.form_style}
              onSubmit={(e) => form_handler(e)}
            >
              <div
                style={{
                  width: "100%",
                  gap: "1rem",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <SelectInput
                  on_change={select_input_handler}
                  options={options}
                  label="Input Types"
                  value={options.filter(
                    (p) =>
                      p.value.toString() === input_fields?.input_type.toString()
                  )}
                />
                <InputField
                  type="text"
                  label={props.table ? "Table Header" : "Input Label"}
                  placeholder={
                    props.table ? "Enter table header" : "Enter input label"
                  }
                  name="label"
                  value={input_fields?.label}
                  required
                  on_change={input_change_handler}
                />
              </div>
              <div
                className="flex center space_between"
                style={{ width: "100%", gap: "1rem" }}
              >
                {input_fields?.input_type !== "date" ? (
                  <InputField
                    type="text"
                    label="Input Placeholder"
                    placeholder="Enter input placeholder"
                    value={input_fields?.placeholder}
                    on_change={input_change_handler}
                    name="placeholder"
                    required
                  />
                ) : null}
                <InputField
                  type="text"
                  label="Input Name"
                  placeholder="Enter input name"
                  value={input_fields?.name}
                  on_change={input_change_handler}
                  name="name"
                  required
                />
              </div>
              <InputField
                type="text"
                label="Outer Class"
                placeholder="Enter outer class"
                value={input_fields?.outer_class}
                on_change={input_change_handler}
                name="outer_class"
              />{" "}
              <InputField
                type="text"
                label="Label Class"
                placeholder="Enter label class"
                value={input_fields?.label_class}
                on_change={input_change_handler}
                name="label_class"
              />{" "}
              <div
                className="flex center space_between"
                style={{ width: "100%", gap: "1rem" }}
              >
                <InputField
                  type="text"
                  label="Input Class"
                  placeholder="Enter input class"
                  value={input_fields?.input_class}
                  on_change={input_change_handler}
                  name="input_class"
                />
              </div>
              {input_fields?.input_type === "textarea" ? (
                <InputField
                  type="text"
                  label="Rows"
                  placeholder="Enter no of rows"
                  value={input_fields?.rows.toString()}
                  on_change={input_change_handler}
                  required
                  name="rows"
                />
              ) : null}
              <div className="align_items">
                <button
                  type="button"
                  className="secondary_button"
                  onClick={() => dispatch(resetDynamicInputField())}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  style={{ textTransform: "capitalize", fontSize: "1rem" }}
                  className={["primary_button", style.submit_button].join(" ")}
                >
                  {current_action}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className={style.preview_form} style={{ paddingLeft: "1rem" }}>
          <form className={style.output_form}>
            {data && data.length > 0
              ? data.map((p: any, i: number) => (
                  <>
                    {p.input_type !== "textarea" &&
                    p.input_type !== "select" ? (
                      <OutputInputField
                        on_delete={() => deleteInputField(i)}
                        on_edit={() => editInputField(i)}
                        handleDragStart={(e: any) => handleDragStart(e, i)}
                        handleDragEnter={(e: any) => handleDragEnter(e, i)}
                        handleDragEnd={handleDragEnd}
                        duplicate_inputfield={() => duplicatefield(i)}
                        input_data={p}
                        display
                      />
                    ) : null}
                    {p.input_type === "textarea" ? (
                      <TextArea
                        on_delete={() => deleteInputField(i)}
                        on_edit={() => editInputField(i)}
                        duplicate_inputfield={() => duplicatefield(i)}
                        input_data={p}
                        display
                      />
                    ) : null}
                    {p.input_type === "select" ? (
                      <OutputSelectField
                        on_delete={() => deleteInputField(i)}
                        on_edit={() => editInputField(i)}
                        duplicate_inputfield={() => duplicatefield(i)}
                        input_data={p}
                        display
                      />
                    ) : null}
                  </>
                ))
              : null}
          </form>
          <div
            style={{
              borderTop: data.length > 0 ? "1px solid #ccc" : "",
              marginTop: "8px",
            }}
          >
            {input_fields?.input_type.toString() !== "textarea" &&
            input_fields?.input_type.toString() !== "select" ? (
              <OutputInputField input_data={input_fields} test />
            ) : null}
            {input_fields?.input_type.toString() === "textarea" ? (
              <TextArea input_data={input_fields} test />
            ) : null}
            {input_fields?.input_type.toString() === "select" ? (
              <OutputSelectField input_data={input_fields} test />
            ) : null}
          </div>
          {data.length > 0 ? (
            <button
              onClick={() => props.on_submit(data)}
              className="primary_button"
              style={{
                fontSize: "16px",
                display: "block",
                fontWeight: "500",
                alignSelf: "flex-end",
                marginTop: "16px",
              }}
            >
              {props.status_en} Layout
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default DynamicForm;
