import Table from "../../../component/table_container/main_table";
import TableHeader from "../../../component/table_container/table_header";
import TableSubHeader from "../../../component/table_container/table_sub_header";
import { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { FiEdit2, FiUserCheck } from "react-icons/fi";
import { useNavigate } from "react-router";
import Modal from "../../../component/modal";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  deletepermission,
  setpermission,
} from "../../../redux/store/permissions";
import { toast } from "react-toastify";
import {
  delete_permission,
  get_permissions,
} from "../../../utils/api/user_configuration/permissions";
const Permission = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState<Boolean>(false);
  const [delete_id, setDeleteId] = useState<Number>();
  const data = useAppSelector((state: any) => state.permissions?.data);
  const [search, setSearch] = useState("");
  console.log(data);
  useEffect(() => {
    get_permissions()
      .then((res) => {
        dispatch(setpermission(res.data.data));
      })
      .catch((err) => {
        toast.error("Error occured while fetching permissions!");
      });
  }, [dispatch]);
  const deletePermission = async () => {
    if (delete_id !== undefined) {
      delete_permission(delete_id?.toString())
        .then((res) => {
          dispatch(deletepermission(delete_id));
          toast.success("Permission has been deleted!");
          setModal(false);
        })
        .catch((err) => {
          toast.error("Error occured while deleting permision!");
        });
    }
  };
  const deleteHandler = (id: number) => {
    setDeleteId(id);
    setModal(true);
  };
  return (
    <>
      <Modal
        close_modal={() => setModal(false)}
        action={deletePermission}
        open={modal}
        title="permission"
      />
      <div className="table_wrapper">
        <div className="component_wrapper table_container">
          <TableHeader
            icon={<FiUserCheck />}
            primary_button_name="अनुमति थप्नुहोस्"
            table_title="
          अनुमति"
            url="user_configuration/permission/add"
            items_length={data ? data.length : 0}
            on_add={() => navigate("add")}
          />
          <TableSubHeader
            input_handler={(e: any) => setSearch(e.target.value)}
            placeholder="Filter by permission name"
          />
          <Table
            table_headings={[
              "s.n.",
              "permission",
              "guard name",
              "last updated by",
              "actions",
            ]}
          >
            {data
              ?.filter((p: any) => {
                return search.trim().length !== 0
                  ? p.permission_name
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                      p.guard_name.toLowerCase().includes(search.toLowerCase())
                  : p;
              })
              ?.map((p: any, i: any) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{p.permission_name}</td>
                  <td>{p.guard_name}</td>
                  <td>{p.updated_at ? p.updated_at : "Not Modified Yet"}</td>
                  <td className="align_items table_icons">
                    <AiOutlineEye onClick={() => navigate("view/" + p.id)} />
                    <AiOutlineDelete onClick={() => deleteHandler(p.id)} />
                    <FiEdit2
                      onClick={() =>
                        navigate(
                          "/user_configuration/permission/update/" + p.id
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
            {data.length < 1 && (
              <tr>
                <td colSpan={4} className="no_match_text">
                  No Permissions has been added
                </td>
              </tr>
            )}
          </Table>
        </div>
      </div>
    </>
  );
};
export default Permission;
