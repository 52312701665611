import { createSlice } from "@reduxjs/toolkit";
interface LocallevelState {
  data: Array<Object>;
  specific_llevel: {
    lgname_en: string;
    lgname_np: string;
    province_id: number;
    district_id: number;
  };
  show_data: Array<Object>;
  items_per_page: any;
  current_page: any;
  filtered_data: Array<Object>;
  loading: boolean;
}

const initialState: LocallevelState = {
  data: [],
  specific_llevel: {
    lgname_en: "",
    lgname_np: "",
    province_id: 0,
    district_id: 0,
  },
  show_data: [],
  items_per_page: 10,
  current_page: 1,
  filtered_data: [],
  loading: false,
};

export const llevelSlice = createSlice({
  name: "llevelSlice",
  initialState,
  reducers: {
    set_loading_llevel: (state, action) => {
      state.loading = action.payload;
    },
    show_data_llevel: (state) => {
      const startIndex = (state.current_page - 1) * state.items_per_page;
      const endIndex = startIndex + state.items_per_page;
      const displayData = state.filtered_data.slice(startIndex, endIndex);
      state.show_data = displayData;
    },
    setllevel: (state, action) => {
      state.data = action.payload;
      state.filtered_data = action.payload;
    },
    search_items: (state, action) => {
      state.filtered_data =
        action.payload.trim().length !== 0
          ? state.data.filter((p: any) => {
              return (
                p.lgname_en
                  .toLowerCase()
                  .includes(action.payload.toLowerCase()) ||
                p.lgname_np
                  .toLowerCase()
                  .includes(action.payload.toLowerCase()) ||
                p.district?.district_en
                  .toLowerCase()
                  .includes(action.payload.toLowerCase()) ||
                p.province?.province_en
                  .toLowerCase()
                  .includes(action.payload.toLowerCase())
              );
            })
          : state.data;
      state.current_page = 1;
    },
    set_items_per_page: (state, action) => {
      state.items_per_page = action.payload;
    },
    set_current_page: (state, action) => {
      state.current_page = action.payload;
    },
    addllevel: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    getSpecificllevel: (state, action) => {
      state.specific_llevel = {
        ...action.payload,
      };
    },
    setSpecificllevel: (state, action) => {
      state.specific_llevel = {
        ...state.specific_llevel,
        [action.payload.name]: action.payload.value,
      };
    },
    deletellevel: (state, action) => {
      state.data = [
        ...state.data.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
      state.filtered_data = [
        ...state.filtered_data.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
    },
  },
});

export const {
  setllevel,
  addllevel,
  deletellevel,
  getSpecificllevel,
  setSpecificllevel,
  show_data_llevel,
  set_current_page,
  set_items_per_page,
  search_items,
  set_loading_llevel
} = llevelSlice.actions;

export default llevelSlice.reducer;
