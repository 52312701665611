import TableHeader from "../../../../component/table_container/table_header";
import TableSubHeader from "../../../../component/table_container/table_sub_header";
import Table from "../../../../component/table_container/main_table";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit, FiEdit2 } from "react-icons/fi";
import { MdContentCopy } from "react-icons/md";
import { useNavigate, useParams } from "react-router";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
const SifarisTypeUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <>
      <div className="table_wrapper">
        <div className="component_wrapper table_container">
          <TableHeader
          icon={<HiOutlineDocumentDuplicate/>}
            on_add={() => navigate("/sifaris/add/template/create/" + id)}
            items_length={10}
            primary_button_name="फारम भर्नुहोस्"
            table_title="
            कागजात"
          />
          <TableSubHeader placeholder="प्रयोगकर्ता खोज्नुहोस्" />
          <Table
            table_headings={[
              "s.n.",
              "Family Id",
              "full name",
              "citizenship number",
              "phone number",
              "actions",
            ]}
          >
            {Array.from({ length: 8 }).map((p: any, i: number) => (
              <tr>
                <td>{i + 1}</td>
                <td>{i + 1}</td>
                <td>Rajan Raj Shah</td>
                <td>22-23-1343-344</td>
                <td>9823424823</td>
                <td className="align_items table_icons">
                  <AiOutlineDelete
                    data-tooltip-id="view"
                    data-tooltip-content="Delete"
                  />
                  <FiEdit2 
                    data-tooltip-id="view"
                    data-tooltip-content="Edit"
                  />
                  <MdContentCopy 
                    data-tooltip-id="view"
                    data-tooltip-content="Duplicate Document"
                  />
                </td>
              </tr>
            ))}
          </Table>
        </div>
      </div>
    </>
  );
};
export default SifarisTypeUser;
