import CustomModal from "../../../../../component/customModal";
import SelectInput from "../../../../dynamic_form/input_field/select_input";
import { useState, useRef } from "react";
import style from "../../../index.module.scss";
import Buttons from "../../../../../component/customModal/buttons";
import { useEffect } from "react";
import Http from "../../../../../utils/httpClients";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/hook";
import { setPublicUserDocument } from "../../../../../redux/store/public_users";
import { fileSizeHandler } from "../../../../../utils/utitlity";
const Modal = (props: {
  show_modal: boolean;
  on_close: () => void;
  on_submit: any;
  title?: string;
  document?: any;
  change_document_upload?: React.ChangeEventHandler<HTMLInputElement>;
}) => {
  const dispatch = useAppDispatch();
  const user_document = useAppSelector(
    (state: any) => state.public_user.user_document_id
  );
  const fileRef = useRef<any>();
  const [documents, setDocuments] = useState<any>();
  useEffect(() => {
    Http.GET("/documents", true).then((res) => {
      setDocuments(
        res.data.data
          .map((p: any) => {
            return { label: p.doc_name, value: p.id };
          })
      );
    });
  }, []);
  const fileHandler = () => {
    if (fileRef !== null) {
      fileRef.current.click();
    }
  };

  const select_change = (value: any) => {
    dispatch(setPublicUserDocument(value));
  };
  return (
    <CustomModal
      title={props.title + " Document"}
      show={props.show_modal}
      onClose={props.on_close}
    >
      <form onSubmit={props.on_submit}>
        <div
          style={{
            margin: "2rem 0",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <SelectInput
            options={documents}
            label="Document"
            label_class="bold"
            required
            name="document_id"
            value={
              user_document &&
              documents &&
              documents.filter((p: any) => p.value === user_document)
            }
            on_change={(e: any) => select_change(e.value)}
          />
          <div className="flex center" style={{ gap: "1rem" }}>
            <div
              onClick={fileHandler}
              className={style.file_button}
              style={{ width: "max-content", cursor: "pointer" }}
            >
              {props.document ? "Change Document" : props.title + " Document"}
            </div>
            {props.document && (
              <p className="flex center" style={{ gap: "10px" }}>
                {props.document?.name}
                <span style={{ color: "red" }}>
                  ({fileSizeHandler(props.document.size)})
                </span>
              </p>
            )}
          </div>

          <input
            onChange={props.change_document_upload}
            name="attachment"
            style={{ display: "none" }}
            type="file"
            ref={fileRef}
          />
        </div>
        <Buttons handleClose={props.on_close} action_btn_title="Save" />
      </form>
    </CustomModal>
  );
};
export default Modal;
