import Table from "../../../component/table_container/main_table";
import TableHeader from "../../../component/table_container/table_header";
import TableSubHeader from "../../../component/table_container/table_sub_header";
import { useEffect } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from "react-router";
import Modal from "../../../component/modal";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { setProvince } from "../../../redux/store/provinces/province";
import { toast } from "react-toastify";
import { deleteProvince } from "../../../redux/store/provinces/province";
import {
  delete_province,
  get_provinces,
} from "../../../utils/api/federal_stucture/province";
const Pradesh = () => {
  const [modal, setModal] = useState(false);
  const [delete_id, setDeleteId] = useState<number | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.province.data);
  const [search, setSearch] = useState<string>("");
  useEffect(() => {
    if (data && data?.length === 0) {
      get_provinces()
        .then((res) => {
          if (res.status === 200) {
            dispatch(setProvince(res?.data?.data));
          }
        })
        .catch(() => {
          toast.error("प्रदेश डाटा ल्याउने क्रममा त्रुटि भयो");
        });
    }
  }, [dispatch]);
  const deleteHandler = (id: number) => {
    setDeleteId(id);
    setModal(true);
  };
  const deleteData = () => {
    if (delete_id !== null && delete_id !== undefined) {
      delete_province(delete_id)
        .then(() => {
          toast.success("प्रदेश सफलतापूर्वक मेटिएको छ!");
          dispatch(deleteProvince(delete_id));
          setModal(false);
        })
        .catch((error) => {
          toast.error("प्रदेश हटाउँदा त्रुटि भयो!");
        });
    }
  };
  return (
    <>
      <Modal
        open={modal}
        close_modal={() => setModal(false)}
        action={deleteData}
        title="province"
      />
      <div className="table_wrapper">
        <div className="component_wrapper table_container">
          <TableHeader
            primary_button_name="
            प्रदेश थप्नुहोस्"
            table_title="प्रदेश"
            items_length={data ? data?.length : 0}
            on_add={() => navigate("add")}
          />
          <TableSubHeader
            placeholder="Filter province data...."
            input_handler={(e: any) => setSearch(e.target.value)}
          />
          {data && data.length > 0 ? (
            <Table
              table_headings={[
                "S.N.",
                "Province Name(EN)",
                "province name(np)",
                "last modified by",
                "actions",
              ]}
            >
              {data
                ?.filter((p: any) => {
                  return search.trim()?.length !== 0
                    ? p.province_en
                        .toLowerCase()
                        .includes(search?.toLowerCase()) ||
                        p.province_np
                          .toLowerCase()
                          .includes(search?.toLowerCase())
                    : p;
                })
                ?.map((p: any, i: any) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{p.province_en}</td>
                    <td>{p.province_np}</td>
                    <td>
                      {p.updated_at
                        ? p.updated_at.split("T")[0]
                        : "Not Modified Yet"}
                    </td>
                    <td className="align_items table_icons">
                      <AiOutlineDelete
                        data-tooltip-id="view"
                        data-tooltip-content="Delete Province"
                        onClick={() => deleteHandler(p.id)}
                      />
                      <FiEdit2
                        data-tooltip-id="view"
                        data-tooltip-content="Update Province"
                        onClick={() => navigate("update/" + p.id)}
                      />
                    </td>
                  </tr>
                ))}
            </Table>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default Pradesh;
