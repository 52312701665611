import InputField from "../../input_container";
import SelectInput from "../../input_container/select_input";
type Props = {
  placeholder?: string;
  input_handler?: any;
  no_of_items?: any;
  item_select?: boolean;
};
const options = [
  { label: "10 items", value: 10 },
  { label: "20 items", value: 20 },
  { label: "40 items", value: 40 },
  { label: "80 items", value: 80 },
];
const TableSubHeader = (props: Props) => {
  return (
    <div className="align_items space_between table_sub_header">
      <div style={{ width: "40%" }}>
        <InputField
          type="text"
          placeholder={props.placeholder}
          input_class="font-size-16"
          on_change={props.input_handler}
        />
      </div>
      {props.item_select && (
        <div style={{ width: "20%" }}>
          <SelectInput
            selectedOption={options[0]}
            placeholder="No of Items"
            options={options}
            on_change={props.no_of_items}
          />
        </div>
      )}
    </div>
  );
};
export default TableSubHeader;
