import { createSlice } from "@reduxjs/toolkit";
let form_status = {
  name: "",
  label: "",
  placeholder: "",
  input_type: "text",
  field_order: 0,
  input_id: "",
  outer_class: "col-12",
  label_class: "font-size-16",
  input_class: "",
  rows: 1,
};
interface SifarisType {
  types: Array<Object>;
  forms: any;
  sifaris_forms: Array<any>;
  form_status: string;
  table_status: string;
  form_input_field: any;
  form_title: string;
  table_title: string;
  table_header: string;
  tables: any;
  specific_sifaris_type: {
    name: string;
    route: string;
    method: string;
  };
  show_data: Array<Object>;
  items_per_page: any;
  current_page: any;
  filtered_data: Array<Object>;
  dragIds: { start_id: string; end_id: string };
}

const initialState: SifarisType = {
  types: [],
  forms: {},
  sifaris_forms: [],
  tables: [],
  form_input_field: { ...form_status },
  form_status: "create",
  table_status: "create",
  form_title: "",
  table_title: "",
  table_header: "",
  specific_sifaris_type: {
    name: "",
    route: "/demoroute",
    method: "POST",
  },
  show_data: [],
  items_per_page: 10,
  current_page: 1,
  filtered_data: [],
  dragIds: { start_id: "", end_id: "" },
};

export const sifarisTypeSlice = createSlice({
  name: "sifarisTypeSlice",
  initialState,
  reducers: {
    show_data_sifaristype: (state) => {
      const startIndex = (state.current_page - 1) * state.items_per_page;
      const endIndex = startIndex + state.items_per_page;
      const displayData = state.filtered_data.slice(startIndex, endIndex);
      state.show_data = displayData;
    },
    search_items: (state, action) => {
      state.filtered_data =
        action.payload.trim().length !== 0
          ? state.types.filter((p: any) => {
              return p.name.includes(action.payload);
            })
          : state.types;
      state.current_page = 1;
    },
    set_items_per_page: (state, action) => {
      state.items_per_page = action.payload;
    },
    set_current_page: (state, action) => {
      state.current_page = action.payload;
    },
    setSifarisTypes: (state, action) => {
      state.types = action.payload;
      state.filtered_data = action.payload;
    },
    setSifarisForms: (state, action) => {
      state.forms = action.payload;
    },
    updateSifarisForm: (state, action) => {
      state.sifaris_forms = action.payload;
    },
    setFormInputField: (state, action) => {
      state.form_input_field = {
        ...state.form_input_field,
        [action.payload.name]: action.payload.value,
      };
    },
    updateFormInputField: (state, action) => {
      state.form_input_field = action.payload;
    },
    duplicateInputField: (state, action) => {
      state.form_input_field = {
        ...action.payload,
        field_order: state.sifaris_forms.length,
      };
    },
    setTables: (state, action) => {
      state.tables = action.payload;
    },
    setSifarisForm: (state) => {
      state.sifaris_forms =
        state.sifaris_forms.length > 0
          ? [
              ...state.sifaris_forms,
              {
                ...state.form_input_field,
                field_order: state.sifaris_forms.length,
              },
            ]
          : [{ ...state.form_input_field, field_order: 0 }];
      console.log(state.sifaris_forms);
    },
    setSifarisFormStatus: (state, action) => {
      state.form_status = action.payload;
    },
    setSifarisTableStatus: (state, action) => {
      state.table_status = action.payload;
    },
    setSifarisFormTitle: (state, action) => {
      state.form_title = action.payload;
    },
    setSifarisTableTitle: (state, action) => {
      state.table_title = action.payload;
    },
    setSifarisTableHeader: (state, action) => {
      state.table_header = action.payload;
    },
    getSpecificSifaris: (state, action) => {
      state.specific_sifaris_type = {
        ...action.payload,
      };
    },
    setSpecificSifaris: (state, action) => {
      state.specific_sifaris_type = {
        ...state.specific_sifaris_type,
        [action.payload.name]: action.payload.value,
      };
    },
    resetSifarisForm: (state) => {
      state.specific_sifaris_type = {
        name: "",
        route: "/demoroute",
        method: "POST",
      };
      state.table_title = "";
      state.table_header = "";
      state.form_title = "";
      state.forms = { ...form_status };
    },
    resetDynamicInputField: (state) => {
      state.form_input_field = {
        ...form_status,
        field_order: state.sifaris_forms.length,
      };
    },
    delete_sifaris: (state, action) => {
      state.types = [
        ...state.types.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
      state.filtered_data = [
        ...state.filtered_data.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
    },
    set_drag_ids: (state, action) => {
      state.dragIds = {
        ...state.dragIds,
        [action.payload.name]: action.payload.value.toString(),
      };
    },
  },
});

export const {
  setSifarisForms,
  setSifarisTypes,
  setSifarisFormStatus,
  setSifarisTableStatus,
  setSifarisFormTitle,
  setSifarisTableTitle,
  setSifarisTableHeader,
  getSpecificSifaris,
  setSpecificSifaris,
  resetSifarisForm,
  delete_sifaris,
  duplicateInputField,
  setSifarisForm,
  setFormInputField,
  resetDynamicInputField,
  updateSifarisForm,
  updateFormInputField,
  setTables,
  set_items_per_page,
  set_current_page,
  show_data_sifaristype,
  search_items,
  set_drag_ids,
} = sifarisTypeSlice.actions;

export default sifarisTypeSlice.reducer;
