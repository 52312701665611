import React, { useState } from "react";
import "./style.scss";
import { BiRightArrowAlt } from "react-icons/bi";
import { useAppDispatch } from "../../hooks/hook";
import { setLogin } from "../../redux/store/slice/loginSlice";
import { toast } from "react-toastify";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";
import httpClients from "../../utils/httpClients";

function Login() {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<any>();
  const [password, setPassword] = useState<any>();
  const [status, setStatus] = useState<string>("");
  const login = async () => {
    httpClients
      .POST("/login", { email, password }, false)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setLogin());
          sessionStorage.setItem("sifaris-token", res.data.token);
          toast.success("सिफारिस प्रणालीमा स्वागत छ!", {
            position: "bottom-right",
          });
        }
      })
      .catch((e) => {
        toast.error("लगइन गर्दा त्रुटि भयो!");
      });
  };

  const onLogin: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (email === "" && password === "") {
      setStatus("इमेल र पासवर्ड दुवै आवश्यक छ!");
    } else if (email === "") {
      setStatus("इमेल आईडी आवश्यक छ!");
    } else if (password === "") {
      setStatus("पासवर्ड आवश्यक छ!");
    } else {
      login();
    }
  };
  return (
    <div className="outerLayer">
      <div className="container border p-3">
        <div className="header">
          <img
            className="loginImage"
            alt="loginImage"
            src="https://i.pinimg.com/564x/e1/c1/d7/e1c1d76acbb51846b915e540af5ae879.jpg"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
            }}
          >
            <span
              style={{
                fontSize: "17px",
                color: "#676767",
                fontFamily: "Mukta",
                textAlign: "left",
              }}
            >
              ई-सिफारिस प्रणाली
            </span>
            <span
              style={{ fontSize: "15px", textAlign: "left", color: "#28A745" }}
            >
              वेनी नगरपालिका, नगर कार्यपालिकाको कार्यालय
            </span>
            <small
              style={{ fontFamily: "Mukta", color: "red", textAlign: "left" }}
            >
              बेनी बजार, म्याग्दी
            </small>
          </div>
        </div>
        {/* <img
          className="loginImage"
          alt="loginImage"
          src="https://i.pinimg.com/564x/e1/c1/d7/e1c1d76acbb51846b915e540af5ae879.jpg"
        /> */}

        <div className="loginContains">
          <div className="top">
            <div>
              <h4 className="heading">सदस्य लगिन</h4>
            </div>

            <div className="middle">
              <form onSubmit={onLogin}>
                <input
                  className="input-with-email form-control"
                  placeholder="&#xF0e0;    इमेल"
                  type="email"
                  style={{ fontFamily: "FontAwesome" }}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <input
                  className="input-with-password form-control"
                  placeholder=" &#xF023;    पासवर्ड"
                  type="password"
                  style={{ fontFamily: "FontAwesome" }}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <button type="submit" className=" p-2 loginButton">
                  लगइन
                </button>
                {status.length > 0 ? (
                  <p
                    className={`small ${
                      status === "Successfull" ? "text-success" : "text-danger"
                    } text-left mt-1 text-center`}
                  >
                    {status}
                  </p>
                ) : (
                  <></>
                )}
              </form>
            </div>

            <p className="text-center">
              <small>प्रयोगकर्ता नाम/पासवर्ड बिर्सनुभयो ?</small>
            </p>
          </div>
          <div className="footer">
            <p>
              नयाँ खाता सिर्जना गर्नुहोस्?
              <BiRightArrowAlt />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
