import Form from "../../template_form/form";
import { useParams, useNavigate } from "react-router";
import { useState } from "react";
import Modal from "../../../component/modal";
import Http from "../../../utils/httpClients";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  setSifarisFormStatus,
  setSifarisFormTitle,
} from "../../../redux/store/sifaris_type";

const SifarisForm = () => {
  const { id } = useParams();
  const [modal, setModal] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let alternate_url = "/system_configuration/sifaris_type/form/title/create/";
  let update_url = "/system_configuration/sifaris_type/form/title/update/";
  const delete_ids = useAppSelector(
    (state: any) => state.sifaris_types?.forms?.sifarisforms
  );
  const delete_form = () => {
    let ids = delete_ids.map((p: any) => p.id);
    Http.DELETE("/sifarisforms/" + ids.join(","), true)
      .then((res) => {
        dispatch(setSifarisFormStatus("create"));
        dispatch(setSifarisFormTitle(""));
        navigate(alternate_url + id, { replace: true });
      })
      .catch((err) => {
        toast.error("Error occured while deleting form!");
      });
  };

  return (
    <>
      <Modal
        action={delete_form}
        open={modal}
        close_modal={() => setModal(false)}
        title="form"
      />
      <div
        className="flex center"
        style={{ flexDirection: "column", padding: "1rem" }}
      >
        <div className="flex" style={{ alignSelf: "flex-end", gap: "1rem" }}>
          <button
            className="primary_button"
            onClick={() => navigate(update_url + id)}
          >
            Update Form
          </button>
          <button className="secondary_button" onClick={() => setModal(true)}>
            Delete Form
          </button>
        </div>
        <div
          style={{
            width: "85%",
            flexDirection: "column",
            gap: "1rem",
            alignItems: "flex-end",
          }}
        >
          <Form />
        </div>
      </div>
    </>
  );
};
export default SifarisForm;
