import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

// Define a type for the slice state
interface LoginState {
  isLoggedIn: boolean;
}

// Define the initial state using that type
const initialState: LoginState = {
  isLoggedIn: sessionStorage.getItem("sifaris-token") ? true : false,
};

export const loginSlice = createSlice({
  name: "loginSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setLogin: (state) => {
      state.isLoggedIn = !state.isLoggedIn;
    },
  },
});

export const { setLogin } = loginSlice.actions;

// Other code such as selectors can use the imported `RootState` type
//export const selectCount = (state: RootState) => state.counter.value

export default loginSlice.reducer;
