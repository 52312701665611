import style from "./index.module.scss";
interface Subjects {
  subject: String;
  to: String;
}
const Subject = (props: Subjects) => {
  return (
    <>
      <p className={style.underline_center_text}>Subject: {props.subject}</p>
      <p className={style.underline_center_text}>To: {props.to}</p>
    </>
  );
};
export default Subject;
