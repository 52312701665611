import Http from "../../../../../utils/httpClients";
import OfficeContainer from "..";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/hook";
import {
  resetOfficeForm,
  set_loading_office,
} from "../../../../../redux/store/office";
const AddOffice = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const data = useAppSelector((state: any) => state.offices.specific_office);
  useEffect(() => {
    dispatch(resetOfficeForm());
  }, [dispatch]);
  const form_data = (e: any) => {
    e.preventDefault();
    dispatch(set_loading_office(true));
    Http.POST("/offices", data, true)
      .then((res) => {
        dispatch(set_loading_office(false));
        navigate(-1);
      })
      .catch((err) => {
        dispatch(set_loading_office(false));
        toast.error("कार्यालय थप्दा त्रुटि उत्पन्न भयो!");
      });
  };
  return (
    <OfficeContainer
      page_title="
  थप्नुहोस्
  "
      form_data={form_data}
    />
  );
};
export default AddOffice;
