import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { IoHelpCircleOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
const Table = () => {
  // const [items, setItems] = useState([]);
  // const [itemOffset, setItemOffset] = useState(0);
  // useEffect(() => {
  //   fetch("https://jsonplaceholder.typicode.com/users")
  //     .then((res) => res.json())
  //     .then((response) => {
  //       console.log(response);
  //       setItems(response);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  // const endOffset = itemOffset + 3;
  // let pageCount = 0;
  // let currentItems = 0;
  // if (items) {
  //   currentItems = items.slice(itemOffset, endOffset);
  //   pageCount = Math.ceil(items.length / 3);
  // }
  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * 3) % items.length;
  //   console.log(
  //     `User requested page number ${event.selected}, which is offset ${newOffset}`
  //   );
  //   setItemOffset(newOffset);
  // };
  return (
    <>
      <table className="table_color_alternate">
        <tr>
          <th className="checkbox_label">
            <input type="checkbox" />
            S.N.
          </th>
          <th>Name</th>
          <th className="align_items" style={{ gap: "2px" }}>
            Company
            <IoHelpCircleOutline />
          </th>
          <th>Email Address</th>
          <th>Created Date</th>
          <th></th>
        </tr>
        {/* <td colSpan={6} className="no_match_text">
          No matching record has been found.
        </td> */}
        {Array.from({ length: 12 }).map((p, i) => (
          <tr key={i}>
            <td className="checkbox_label">
              <input type="checkbox" />1
            </td>
            <td>Emily Smith</td>
            <td>Product Designer</td>
            <td>user@email.com</td>
            <td>14 Dec 2022, 8:43 am</td>
            <td className="align_items table_icons">
              <AiOutlineDelete />
              <FiEdit2 />
            </td>
          </tr>
        ))}
      </table>
      <div className="table_footer">
        <p className="table_pages">Page 1 of 10</p>
        <div className="align_items">
          <button className="table_page table_page_active">1</button>
          <button className="table_page">2</button>
          <button className="table_page">3</button>
          <button className="table_page">...</button>
          <button className="table_page">8</button>
          <button className="table_page">9</button>
        </div>
        <div className="align_items">
          <button className="font_size_regular secondary_button button_font_size align_items">
            Previous
          </button>
          <button className="font_size_regular secondary_button button_font_size align_items">
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default Table;
