import Http from "../../../httpClients";
export const get_permissions = async () => Http.GET("/permissions", true);
export const create_permission = async (data: any) =>
  Http.POST("/permissions", data, true);
export const update_permission = async (id: string, data: any) =>
  Http.PUT("/permissions/" + id, data, true);
export const delete_permission = async (id: string) =>
  Http.DELETE("/permissions/" + id, true);
export const get_permission = async (id: string) =>
  Http.GET("/permissions/" + id, true);
