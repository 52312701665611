import Footer from "../lang_nepali/footer";
import Header from "../lang_nepali/header";
import Subject from "../lang_nepali/subject";
import Content from "./content";
import "../index.scss";
import SifarisDocLayout from "..";
const TemplateNep4 = () => {

  return (
    <SifarisDocLayout>
        <div className="template_content_gap" style={{ gap: "2rem" }}>
          <Header>
            <Subject subject="व्यवसाय बन्द बारे" />
          </Header>
          <Content />
        </div>
        <Footer />
    </SifarisDocLayout>
  );
};

export default TemplateNep4;
