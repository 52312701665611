import styles from "./index.module.scss";
import { useState, useEffect } from "react";
interface InputFieldProps {
  type: string;
  placeholder?: string;
  label?: string;
  value?: string;
  on_change?: any;
  width?: any;
  name?: any;
  id?: any;
  outer_class?: string;
  label_class?: string;
  input_class?: string;
  required?: boolean;
}
const InputField = (props: InputFieldProps) => {
  const [label_size, setLabelSize] = useState("");
  const [label_color, setLabelColor] = useState("");
  const [input_font_size, setInputFontSize] = useState("");
  const [input_outline, setInputOutline] = useState("");
  useEffect(() => {
    if (props.label_class?.includes("font-size-")) {
      setLabelSize(props.label_class?.split("font-size-")[1]);
    }
    if (props.label_class?.includes("font-color-")) {
      setLabelColor(props.label_class?.split("font-color-")[1]);
    }
    if (props.input_class?.includes("font-size-")) {
      setInputFontSize(props.input_class?.split("font-size-")[1]);
    }
    if (props.input_class?.includes("font-color-")) {
      setInputOutline(props.input_class?.split("font-color-")[1]);
    }
  }, [props.label_class, props.input_class]);
  return (
    <div className={["dynamic_input", styles.input_container].join(" ")}>
      <label
        className={[styles.label, props.label_class].join(" ")}
        style={{
          fontSize: label_size.toString() + "px",
          color: "black",
        }}
      >
        {props.label}
        {props.required ? <span style={{ color: "red" }}>*</span> : null}
      </label>
        <input
          type={props.type}
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          id={props.id}
          onChange={props.on_change}
          className={props.input_class}
          required={props.required}
          style={{
            fontSize: input_font_size + "px",
            borderColor: input_outline,
          }}
        />
    </div>
  );
};

export default InputField;
