import "./index.scss";
import Links from "./component/links";
const SideBar = () => {
  return (
    <div className="side_bar">
      <Links/>
    </div>
  );
};
export default SideBar;
