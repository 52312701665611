import CustomModal from "../../../../component/customModal";
import SelectInput from "../../../../component/input_container/select_input";
import { useRef } from "react";
import style from "../../../../pages/profile/index.module.scss";
import Buttons from "../../../../component/customModal/buttons";
import { fileSizeHandler } from "../../../../utils/utitlity";
const Modal = (props: {
  show_modal: boolean;
  on_close: () => void;
  on_submit: any;
  title?: string;
  document?: any;
  change_document_upload?: React.ChangeEventHandler<HTMLInputElement>;
}) => {
  const fileRef = useRef<any>();
  const fileHandler = () => {
    if (fileRef !== null) {
      fileRef.current.click();
    }
  };
  return (
    <CustomModal
      title={props.title + " Document"}
      show={props.show_modal}
      onClose={props.on_close}
    >
      <form onSubmit={props.on_submit}>
        <div
          style={{
            margin: "2rem 0",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <SelectInput
            label="Family User"
            label_class="bold"
            required
            name="familymember_id"
          />

          <div className="flex center" style={{ gap: "1rem" }}>
            <div
              onClick={fileHandler}
              className={style.file_button}
              style={{ width: "max-content", cursor: "pointer" }}
            >
              {props.document ? "Change Document" : props.title + "Document"}
            </div>
            {props.document && (
              <p className="flex center" style={{ gap: "10px" }}>
                {props.document?.name}
                <span style={{ color: "red" }}>
                  ({fileSizeHandler(props.document.size)})
                </span>
              </p>
            )}
          </div>

          <input
            onChange={props.change_document_upload}
            name="attachment"
            style={{ display: "none" }}
            type="file"
            ref={fileRef}
          />
        </div>
        <Buttons handleClose={props.on_close} action_btn_title="Save" />
      </form>
    </CustomModal>
  );
};
export default Modal;
