import Table from "../../../component/table_container/main_table";
import TableHeader from "../../../component/table_container/table_header";
import TableSubHeader from "../../../component/table_container/table_sub_header";
import { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { FiEdit2, FiUsers } from "react-icons/fi";
import { useNavigate } from "react-router";
import Modal from "../../../component/modal";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  setUser,
  deleteuser,
  setEditId,
  show_data_users,
  set_items_per_page,
  set_current_page,
  search_items,
} from "../../../redux/store/user";
import { toast } from "react-toastify";
import {
  delete_user,
  get_users,
} from "../../../utils/api/user_configuration/user";
const User = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState<Boolean>(false);
  const [delete_id, setDeleteId] = useState<number>();
  const data = useAppSelector((state: any) => state.user.filtered_data);
  const items = useAppSelector((state: any) => state.user.data);
  const filtered_data = useAppSelector(
    (state: any) => state.user.filtered_data
  );
  const items_per_page = useAppSelector(
    (state: any) => state.user.items_per_page
  );
  const current_page = useAppSelector((state: any) => state.user.current_page);
  useEffect(() => {
    if (data.length === 0) {
      get_users()
        .then((res) => {
          console.log(res);
          dispatch(setUser(res.data.data));
          dispatch(show_data_users());
        })
        .catch((err) => toast.error("Error while fetching users data"));
    }
  }, [dispatch]);
  const delete_handler = async () => {
    if (delete_id !== undefined) {
      delete_user(delete_id.toString())
        .then((res) => {
          console.log(res);
          dispatch(deleteuser(delete_id));
          toast.success("User has been deleted successfully!");
          setModal(false);
        })
        .catch((err) => {
          toast.error("Error occured while deleting user!");
          setModal(false);
        });
    }
  };
  const deleteHandler = (id: number) => {
    setDeleteId(id);
    setModal(true);
  };
  const editHandler = (id: number, i: number) => {
    dispatch(setEditId(i));
    navigate("update/" + id);
  };
  const show_items = (e: any) => {
    dispatch(set_items_per_page(e.value));
    dispatch(show_data_users());
    console.log(e);
  };
  const page_change = (event: any, pageNumber: any) => {
    dispatch(set_current_page(pageNumber));
    dispatch(show_data_users());
    console.log(pageNumber);
  };
  const change_input = (e: any) => {
    dispatch(search_items(e.target.value));
    dispatch(show_data_users());
  };
  return (
    <>
      <Modal
        close_modal={() => setModal(false)}
        action={delete_handler}
        open={modal}
        title="user"
      />
      <div className="table_wrapper">
        <div className="component_wrapper table_container">
          <TableHeader
            icon={<FiUsers />}
            primary_button_name="
          प्रयोगकर्ता थप्नुहोस्"
            table_title="प्रयोगकर्ता"
            items_length={data ? data.length : 0}
            on_add={() => navigate("add")}
          />
          <TableSubHeader
            item_select={items.length > 10}
            no_of_items={show_items}
            placeholder="Search users...."
            input_handler={change_input}
          />
          <Table
            page_change={page_change}
            page_count={Math.ceil(filtered_data.length / items_per_page)}
            current_page={current_page}
            pagination_display={data.length >= 10}
            item_range={
              data.length * (current_page - 1) +
              "-" +
              current_page * data.length +
              " items"
            }
            table_headings={[
              "S.N.",
              "Full Name",
              "address",
              "Phone number",
              "status",
              "last modified by",
              "actions",
            ]}
          >
            {data.length > 0 ? (
              data?.map((p: any, i: any) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{p.name}</td>
                  <td className="overflow_control">{p.address}</td>
                  <td>{p.phone_number}</td>
                  <td>{p.active_status ? "isActive" : "NotActive"}</td>
                  <td>{p.updated_at ? p.updated_at : "Not Modified Yet"}</td>
                  <td className="align_items table_icons">
                    <AiOutlineEye
                      data-tooltip-id="view"
                      data-tooltip-content="View"
                      onClick={() =>
                        navigate("/user_configuration/user/view/" + p.id)
                      }
                    />
                    <AiOutlineDelete
                      data-tooltip-id="view"
                      data-tooltip-content="Delete"
                      onClick={() => deleteHandler(p.id)}
                    />
                    <FiEdit2
                      data-tooltip-id="view"
                      data-tooltip-content="Update"
                      onClick={() => editHandler(p.id, i)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <td colSpan={7} className="no_match_text">
                No search results found for users
              </td>
            )}
          </Table>
        </div>
      </div>
    </>
  );
};
export default User;
