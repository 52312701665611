import { IoClose } from "react-icons/io5";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import "./index.scss";
interface FilterProps {
  name: String;
}
const FilterItems = (props: FilterProps) => {
  return (
    <div className="align_items">
      <input type="checkbox" />
      <p style={{ textTransform: "capitalize" }}>{props.name}</p>
    </div>
  );
};
interface FilterModalProps {
  close_modal: any;
}
const FitlerModal = (props: FilterModalProps) => {
  return (
    <div className="filter_modal">
      <div className="align_items title space_between">
        <p className="super_bold">Filters Options</p>
        <IoClose style={{ cursor: "pointer" }} onClick={props.close_modal} />
      </div>
      <div className="align_items_col" style={{ gap: "1rem", padding: "1rem" }}>
        <p className="bold sub_title">Basic</p>
        <FilterItems name="name" />
        <FilterItems name="address" />
        <FilterItems name="company" />
        <p className="align_items bold show_more">
          Show more <MdOutlineKeyboardArrowDown />
        </p>
      </div>
      <div className="button_wrapper">
        <button className="secondary_button bold font_size_regular">
          Reset
        </button>
        <button className="primary_button bold font_size_regular">
          Show 30 results
        </button>
      </div>
    </div>
  );
};

export default FitlerModal;
