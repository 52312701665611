import { CiCircleRemove } from "react-icons/ci";
import { useNavigate } from "react-router";

const ReturnButton = (props: { btn_name?: string }) => {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      className="secondary_button align_items"
      onClick={() => navigate(-1)}
    >
      <CiCircleRemove />
      {props.btn_name ? props.btn_name : "Exit"}
    </button>
  );
};
export default ReturnButton;
