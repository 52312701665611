import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { AiOutlinePrinter } from "react-icons/ai";
import { useNavigate } from "react-router";
import { CiCircleRemove } from "react-icons/ci";
const SifarisDocLayout = (props: { children: JSX.Element[] }) => {
  const navigate = useNavigate();
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <div
        className="align_items space_between"
        style={{ width: "21cm", margin: "1rem auto" }}
      >
        <button
          className="align_items secondary_button"
          onClick={() => navigate(-1)}
        >
          <CiCircleRemove />
          Exit
        </button>
        <button className="align_items primary_button" onClick={handlePrint}>
          <AiOutlinePrinter />
          Submit & Print
        </button>
      </div>

      <div
        ref={componentRef}
        className="template_layout nepali_font template_space_between"
      >
        {props.children}
      </div>
    </>
  );
};
export default SifarisDocLayout;
