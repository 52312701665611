import { useEffect } from "react";
import Http from "../../../../../utils/httpClients";
import { toast } from "react-toastify";
import LocalLevelContainer from "..";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import {
  getSpecificllevel,
  set_loading_llevel,
} from "../../../../../redux/store/local_level";
import { useAppSelector } from "../../../../../hooks/hook";
const AddLocalLevel = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useAppSelector((state: any) => state.llevel.specific_llevel);
  useEffect(() => {
    Http.GET("/llevels/" + id, true)
      .then((res) => {
        let data = res.data.data;
        dispatch(
          getSpecificllevel({
            lgname_en: data.lgname_en,
            lgname_np: data.lgname_np,
            district_id: data.district?.id,
            province_id: data.province?.id,
          })
        );
      })
      .catch((err) => {
        toast.error("Error occured while fetching local level data!");
      });
  }, [id, dispatch]);
  const form_handler = async (e: any) => {
    e.preventDefault();
    const form = new FormData(e.target);
    dispatch(set_loading_llevel(true));
    await Http.PUT("/llevels/" + id, form, true)
      .then((res) => {
        toast.success("Local Level has been updated!");
        dispatch(set_loading_llevel(false));
        navigate(-1);
      })
      .catch((err) => {
        dispatch(set_loading_llevel(false));

        toast.error("Error occured while updating local level!");
      });
  };
  return (
    <>
      <LocalLevelContainer
        title="सम्पादन"
        update
        form_handler={form_handler}
        data={data}
      />
    </>
  );
};
export default AddLocalLevel;
