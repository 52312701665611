import DynamicForm from "../dynamic_form";
import Http from "../../utils/httpClients";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { useEffect } from "react";
import {
  resetDynamicInputField,
  updateSifarisForm,
} from "../../redux/store/sifaris_type";
import {
  setActiveState,
  setCurrentState,
  setWidth,
} from "../../redux/store/sifaris_progress";
const Form = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const form_title = useAppSelector(
    (state: any) => state.sifaris_types.form_title
  );
  useEffect(() => {
    dispatch(updateSifarisForm({}));
    dispatch(resetDynamicInputField());
  }, [dispatch]);
  const form_submit = (data: any) => {
    Http.POST(
      "/sifarisforms",
      data.map((p: any) => {
        return { ...p, form_title, sifaristype_id: id };
      }),
      true
    )
      .then((res) => {
        toast.success("Form has been created!");
        dispatch(setCurrentState(3));
        dispatch(setActiveState(2));
        dispatch(setWidth(2));
      })
      .catch((err) => {
        toast.error("Error while creating form!");
      });
  };
  return (
    <>
      <DynamicForm
        title="फारम"
        status="सिर्जना"
        status_en="Submit"
        on_submit={form_submit}
        form_title={form_title}
        table_headings="फारम आउटपुट"
        current_status="Creation"
        // create_title="Input Field"
      />
    </>
  );
};
export default Form;
