import { Route, Routes } from "react-router";
import LocalLevel from "..";
import AddLocalLevel from "../manage_local_level/add_local_level";
import UpdateLocallevel from "../manage_local_level/update_local_level";
export const LlevelRoutes = () => (
  <Routes>
    <Route path="/federal_structure/local_level" element={<LocalLevel />} />
    <Route
      path="/federal_structure/local_level/add"
      element={<AddLocalLevel />}
    />

    <Route
      path="/federal_structure/local_level/update/:id"
      element={<UpdateLocallevel />}
    />
  </Routes>
);
