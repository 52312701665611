import { useNavigate, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import Form from "../../pages/form";
import SifarisFile from "../../pages/sifarisFile";
import Table from "../../pages/table";
import TemplateForm from "../../pages/template_form";
import {
  resetActiveState,
  setCurrentState,
  setWidth,
} from "../../redux/store/sifaris_progress";
import InputField from "../input_container";
import { useEffect, useState } from "react";
import FormTitle from "./component/form_title";
import TableTitle from "./component/table_title";
import style from "./index.module.scss";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import {
  setSifarisFormTitle,
  setSifarisTableHeader,
  setSifarisTableTitle,
} from "../../redux/store/sifaris_type";
import httpClients from "../../utils/httpClients";
import { toast } from "react-toastify";
export const Indicator = (props: {
  id: number;
  title: string;
  current_status?: boolean;
}) => {
  return (
    <div className={style.indicator_container}>
      <div
        className={[
          style.indicator,
          props.current_status && style.indicator_active,
        ].join(" ")}
      >
        {/* <BsCheck /> */}
        <p>{props.id}</p>
      </div>
      <p className={style.title}>{props.title}</p>
    </div>
  );
};
export const InputFieldElement = (props: {
  title?: string;
  placeholder?: string;
  name?: string;
  on_change?: any;
  value?: any;
}) => {
  return (
    <div className={style.input_fields}>
      <p>
        {props.title}
        <span>*</span>
      </p>
      <InputField
        name={props.name}
        type="text"
        placeholder={props.placeholder}
        on_change={props.on_change}
        value={props.value}
      />
    </div>
  );
};
export const TitleElement = (props: { title: string; sub_title: string }) => {
  return (
    <div className={style.header_container}>
      <p className={style.header}>{props.title}</p>
      <p className={style.sub_header}>{props.sub_title}</p>
      <hr className={style.divider} />
    </div>
  );
};
export const PrevNxtButtons = (props: {
  prev_action?: any;
  next_action?: any;
  next_title?: string;
  skip?: boolean;
  skip_state?: number;
  cancel_title?: string;
}) => {
  const dispatch = useAppDispatch();

  return (
    <div className={["align_items", style.button].join(" ")}>
      <button
        type="button"
        onClick={props.prev_action}
        className={["primary_button align_items"].join(" ")}
      >
        <MdOutlineKeyboardArrowLeft />{" "}
        {props.cancel_title ? props.cancel_title : "Previous"}
      </button>
      <div className="align_items">
        {props.skip && (
          <button
            onClick={() => dispatch(setCurrentState(props.skip_state))}
            type="button"
            className="secondary_button"
          >
            Skip
          </button>
        )}

        <button
          onClick={props.next_action}
          type="submit"
          className={["primary_button align_items"].join(" ")}
        >
          {props.next_title ? props.next_title : "Next"}{" "}
          <MdOutlineKeyboardArrowRight />
        </button>
      </div>
    </div>
  );
};
const ProgressBar = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>();
  useEffect(() => {
    dispatch(setWidth(0));
    dispatch(resetActiveState());
    dispatch(setCurrentState(1));
    dispatch(setSifarisFormTitle(""));
    dispatch(setSifarisTableTitle(""));
    dispatch(setSifarisTableHeader(""));
    httpClients
      .GET("/sifaristypes/" + id, true)
      .then((res: any) => {
        let data = res.data.data;
        setData(data);
      })
      .catch((err: any) => {
        toast.error("Error occured while fetching sifaristype data")
      });
  }, [id]);
  const current_status = useAppSelector(
    (state: any) => state.sifaris_progress.current_state
  );
  const active_status = useAppSelector(
    (state: any) => state.sifaris_progress.active_state
  );
  const width = useAppSelector((state: any) => state.sifaris_progress.width);
  const finish_handler = () => {
    dispatch(setCurrentState(1));
    dispatch(resetActiveState());
    navigate("/system_configuration/sifaris_type");
  };
  return (
    <div className="component_wrapper" style={{backgroundColor:"transparent",border:"none"}}>
      <p className="title" style={{ color: "#555" }}>
        {data && data?.name}
      </p>
      <div className={style.progress_system}>
        <div className={style.preview_bar}>
          <Indicator
            current_status={active_status.includes(1)}
            id={1}
            title="Sifaris Form"
          />
          <Indicator
            current_status={active_status.includes(2)}
            id={2}
            title="Sifaris Table"
          />
          <Indicator
            current_status={active_status.includes(3)}
            id={3}
            title="Add Documents"
          />
          <Indicator
            current_status={active_status.includes(4)}
            id={4}
            title="Preview"
          />
          <div className={style.progress_bar}>
            <div
              style={{ width: width + "%" }}
              className={style.inside_progress}
            ></div>
          </div>
        </div>
      </div>
      {current_status === 1 && <FormTitle />}
      {current_status === 2 && (
        <div className="flex" style={{ flexDirection: "column", gap: "1rem" }}>
          <Form />
          <PrevNxtButtons
            prev_action={() => dispatch(setCurrentState(1))}
            next_action={() => dispatch(setCurrentState(3))}
          />
        </div>
      )}
      {current_status === 3 && <TableTitle />}
      {current_status === 4 && (
        <div className="flex" style={{ flexDirection: "column", gap: "1rem" }}>
          <Table />
          <PrevNxtButtons
            prev_action={() => dispatch(setCurrentState(3))}
            next_action={() => dispatch(setCurrentState(5))}
          />
        </div>
      )}
      {current_status === 5 && (
        <div className="flex" style={{ flexDirection: "column", gap: "1rem" }}>
          <SifarisFile />
          <PrevNxtButtons
            skip
            skip_state={6}
            prev_action={() => dispatch(setCurrentState(4))}
            next_action={() => dispatch(setCurrentState(6))}
          />
        </div>
      )}
      {current_status === 6 && (
        <div className="flex" style={{ flexDirection: "column", gap: "1rem" }}>
          <TemplateForm />
          <PrevNxtButtons
            next_title="Finish"
            prev_action={() => dispatch(setCurrentState(5))}
            next_action={finish_handler}
          />
        </div>
      )}
    </div>
  );
};
export default ProgressBar;
