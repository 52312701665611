import ManageLayout from "../../../../component/manage_layout";
import InputField from "../../../../component/input_container";
import { CenterElement } from "../../../../component/manage_layout/center_element";
import { useNavigate } from "react-router";
import { useAppSelector } from "../../../../hooks/hook";
interface Props {
  title: string;
  form_handler: any;
  province_en?: string;
  province_np?: string;
  input_change?: any;
  update?:boolean
}
const ProvinceContainer = (props: Props) => {
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.province.loading);
  return (
    <CenterElement>
      <ManageLayout
        on_cancel={() => navigate(-1)}
        form_handler={props.form_handler}
        page_title={"प्रदेश " + props.title}
        loading={loading}
        form_width="50%"
        update={props.update}
      >
        <div
          className="flex center space_between"
          style={{ gap: "2rem", width: "100%",flexDirection:"column" }}
        >
          <InputField
            placeholder="Enter Province in English"
            type="text"
            required
            name="province_en"
            label="Province Name (In English)"
            value={props.province_en}
            on_change={props.input_change}
          />
          <InputField
            placeholder="Enter Province in Nepali"
            type="text"
            required
            name="province_np"
            label="Province Name (In Nepali)"
            value={props.province_np}
            on_change={props.input_change}
          />
        </div>
      </ManageLayout>
    </CenterElement>
  );
};
export default ProvinceContainer;
