import { useEffect } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import AccountSettingsContainer from ".";
import { useAppDispatch } from "../../../../hooks/hook";
import { resetProfileForm } from "../../../../redux/store/public_users";
import {
  check_tab,
  remove_checks,
  set_tab_id,
} from "../../../../redux/store/tabs";
import Http from "../../../../utils/httpClients";
const AddPublicUser = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(remove_checks());
    dispatch(resetProfileForm());
  }, [dispatch]);
  const user_info_submit = (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);
    Http.POST("/familyprofiles", data, true)
      .then((res) => {
        dispatch(check_tab(0));
        toast.success("Family profile has been created successfully!", {
          position: "bottom-right",
        });
        navigate(
          "/user_configuration/publicuser/update/" + res.data.familyprofile.id,
          {
            replace: true,
          }
        );
        dispatch(set_tab_id(1));
        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .catch((err) => {
        toast.error("Error while creating family profile!", {
          position: "bottom-right",
        });
      });
  };
  return (
    <AccountSettingsContainer form_submit={user_info_submit} btn_title="Save" />
  );
};
export default AddPublicUser;
