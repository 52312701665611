import Table from "../../../component/table_container/main_table";
import TableHeader from "../../../component/table_container/table_header";
import TableSubHeader from "../../../component/table_container/table_sub_header";
import { useEffect, useState } from "react";
import Http from "../../../utils/httpClients";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2, FiUser } from "react-icons/fi";
import { useNavigate } from "react-router";
import Modal from "../../../component/modal";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { setdesignation } from "../../../redux/store/designation/index";
const Designation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState<Boolean>(false);
  const [delete_id, setDeleteId] = useState<Number>();
  const [search, setSearch] = useState("");
  const data = useAppSelector((state: any) => state.designation?.data);
  useEffect(() => {
    if (data.length === 0) {
      Http.GET("/designations", true)
        .then((res) => {
          dispatch(setdesignation(res.data.data));
        })
        .catch((err) => {
          toast.error("Error occured while fetching designations!");
        });
    }
  }, [dispatch]);
  const deleteOffice = async () => {
    await Http.DELETE("/designations/" + delete_id, true)
      .then(async (res) => {
        await Http.GET("/designations", true).then((res) => {
          dispatch(setdesignation(res.data.data));
        });
        toast.success("Designation has been deleted successfully!");
        setModal(false);
      })
      .catch((err) => {
        toast.error("Error occured while deleting designation!");
        setModal(false);
      });
  };
  const deleteHandler = (id: number) => {
    setDeleteId(id);
    setModal(true);
  };
  return (
    <>
      <Modal
        close_modal={() => setModal(false)}
        action={deleteOffice}
        open={modal}
        title="designation"
      />
      <div className="table_wrapper">
        <div className="component_wrapper table_container">
          <TableHeader
            icon={<FiUser />}
            primary_button_name="
          पदनाम थप्नुहोस्"
            table_title="पदनाम"
            on_add={() => navigate("add")}
            items_length={data ? data.length : 0}
          />
          <TableSubHeader
            input_handler={(e: any) => setSearch(e.target.value)}
            placeholder="Search designations ..."
          />
          <Table
            table_headings={[
              "s.n.",
              "designation",
              "last modified by",
              "actions",
            ]}
          >
            {data
              ?.filter((p: any) => {
                return search.trim().length !== 0
                  ? p.designation.toLowerCase().includes(search.toLowerCase())
                  : p;
              })
              ?.map((p: any, i: any) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{p.designation}</td>
                  <td>{p.updated_at ? p.updated_at : "Not Modified Yet"}</td>
                  <td className="align_items table_icons">
                    <AiOutlineDelete
                      data-tooltip-id="view"
                      data-tooltip-content="Delete"
                      onClick={() => deleteHandler(p.id)}
                    />
                    <FiEdit2
                      data-tooltip-id="view"
                      data-tooltip-content="Update"
                      onClick={() =>
                        navigate(
                          "/system_configuration/designation/update/" + p.id
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
            {data.length < 1 && (
              <tr>
                <td colSpan={3} className="no_match_text">
                  No Designation has been added
                </td>
              </tr>
            )}
          </Table>
        </div>
      </div>
    </>
  );
};
export default Designation;
