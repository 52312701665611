import Table from "../../../component/table_container/main_table";
import TableHeader from "../../../component/table_container/table_header";
import TableSubHeader from "../../../component/table_container/table_sub_header";
import { useEffect, useState } from "react";
import Http from "../../../utils/httpClients";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from "react-router";
import Modal from "../../../component/modal";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { setofficetype } from "../../../redux/store/office_type";
import { toast } from "react-toastify";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
const OfficeType = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState<Boolean>(false);
  const [delete_id, setDeleteId] = useState<Number>();
  const data = useAppSelector((state: any) => state.office_types.data);
  const [search, setSearch] = useState("");

  console.log(data);
  useEffect(() => {
    if (data.length === 0) {
      Http.GET("/officetypes", true)
        .then((res) => {
          dispatch(setofficetype(res.data.data));
        })
        .catch((err) => {
          toast.error("Error while fetching office types");
        });
    }
  }, [dispatch]);
  const deleteOffice = async () => {
    await Http.DELETE("/officetypes/" + delete_id, true)
      .then((res) => {
        toast.success("Office type has been deleted");
        setModal(false);
      })
      .catch((err) => {
        toast.error("Error while deleting office type");
      });
  };
  const deleteHandler = (id: number) => {
    setDeleteId(id);
    setModal(true);
  };
  return (
    <>
      {" "}
      <Modal
        close_modal={() => setModal(false)}
        action={deleteOffice}
        open={modal}
        title="office type"
      />
      <div className="table_wrapper">
        <div className="component_wrapper table_container">
          <TableHeader
            icon={<HiOutlineBuildingOffice2 />}
            primary_button_name="
          कार्यालय थप्नुहोस्"
            table_title="कार्यालय प्रकार"
            on_add={() => navigate("add")}
            items_length={data ? data.length : 0}
          />
          <TableSubHeader
            input_handler={(e: any) => setSearch(e.target.value)}
            placeholder="Search office types..."
          />
          <Table
            table_headings={[
              "s.n.",
              "office type",
              "last modified by",
              "actions",
            ]}
          >
            {data
              ?.filter((p: any) => {
                return search.trim().length !== 0
                  ? p.office_type.toLowerCase().includes(search.toLowerCase())
                  : p;
              })
              ?.map((p: any, i: any) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{p.office_type}</td>
                  <td>{p.updated_at ? p.updated_at : "Not Modified Yet"}</td>
                  <td className="align_items table_icons">
                    <AiOutlineDelete
                      data-tooltip-id="view"
                      data-tooltip-content="Delete"
                      onClick={() => deleteHandler(p.id)}
                    />
                    <FiEdit2
                      data-tooltip-id="view"
                      data-tooltip-content="Update"
                      onClick={() =>
                        navigate(
                          "/system_configuration/office_type/update/" + p.id
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
            {data.length < 1 && (
              <tr>
                <td colSpan={3} className="no_match_text">
                  No Office type has been added
                </td>
              </tr>
            )}
          </Table>
        </div>
      </div>
    </>
  );
};
export default OfficeType;
