import React, { useEffect, useState } from "react";
import TableSubHeader from "../../component/table_container/table_sub_header";
import Table from "../../component/table_container/main_table";
import { useParams } from "react-router-dom";
import Http from "../../utils/httpClients";
import TableHeader from "../../component/table_container/table_header";
import { toast } from "react-toastify";
import "./index.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import {
  setActiveState,
  setCurrentState,
  setWidth,
} from "../../redux/store/sifaris_progress";
import { CenterElement } from "../../component/manage_layout/center_element";
import {
  search_items,
  set_current_page,
  set_items_per_page,
  setdocument,
  show_data_document,
} from "../../redux/store/document";

const SifarisFile = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [sifarisFile, setSifarisFile] = useState<any>();
  const [sifarisDocumentId, setSifarisDocumentId] = useState<any>([]);
  const document = useAppSelector((state: any) => state.document.data);
  const data = useAppSelector((state: any) => state.document.show_data);
  const filtered_data = useAppSelector(
    (state: any) => state.document.filtered_data
  );
  const items_per_page = useAppSelector(
    (state: any) => state.document.items_per_page
  );
  const current_page = useAppSelector(
    (state: any) => state.document.current_page
  );
  useEffect(() => {
    Http.GET(`sifaristypes/${id}`, true).then(async (res: any) => {
      let data = res.data.data;
      setSifarisFile(data);
      let docs = res.data.data.sifarisdocuments;
      if (docs.length > 0) {
        setSifarisDocumentId(data.sifarisdocuments.map((p: any) => p.id));
      }
    });
    if (document.length === 0) {
      Http.GET(`documents`, true)
        .then((res: any) => {
          dispatch(setdocument(res.data.data));
          dispatch(show_data_document());
        })
        .catch((err: any) => {
          toast.error("Error occured while fetching documents!");
        });
    }
  }, [id, dispatch]);

  const submit_docs = () => {
    dispatch(setCurrentState(6));
    dispatch(setWidth(6));
    dispatch(setActiveState(4));
    Http.POST(
      `sifaristypes/${id}`,
      { sifarisdocuments: sifarisDocumentId },
      true
    )
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(setCurrentState(6));
          dispatch(setWidth(6));
          toast.success("Sifaris document has been added!");
        }
      })
      .catch((err: any) => {
        toast.error("Error occured while submitting sifaris document!");
      });
  };
  const add_sifaris = (id: number) => {
    if (sifarisDocumentId.includes(id)) {
      setSifarisDocumentId(sifarisDocumentId.filter((p: any) => p !== id));
    } else {
      setSifarisDocumentId([...sifarisDocumentId, id]);
    }
  };
  const select_all = () => {
    if (sifarisDocumentId.length === document?.length) {
      setSifarisDocumentId([]);
    } else {
      setSifarisDocumentId(document?.map((p: any) => p.id));
    }
  };
  const show_items = (e: any) => {
    dispatch(set_items_per_page(e.value));
    dispatch(show_data_document());
  };
  const page_change = (event: any, pageNumber: any) => {
    dispatch(set_current_page(pageNumber));
    dispatch(show_data_document());
  };
  const change_input = (e: any) => {
    dispatch(search_items(e.target.value));
    dispatch(show_data_document());
  };
  return (
    <CenterElement>
      <div
        className="flex"
        style={{
          padding: "1rem",
          paddingBottom: "0",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <div className="component_wrapper table_container document_table">
          <TableHeader
            items_length={document && document.length}
            table_title={sifarisFile?.name}
            no_btn
          />
          <TableSubHeader
            no_of_items={show_items}
            item_select
            input_handler={change_input}
            placeholder="Search anything related to table"
          />
          <Table
            page_change={page_change}
            current_page={current_page}
            page_count={Math.ceil(filtered_data.length / items_per_page)}
            pagination_display={document.length >= 10}
            item_range={
              data.length * (current_page - 1) +
              "-" +
              current_page * data.length +
              " items"
            }
            select_all={select_all}
            checkbox
            table_headings={["Sifaris Document"]}
          >
            {data?.map((sd: any, i: number) => (
              <tr>
                <td className="flex center" style={{ gap: "5px" }}>
                  <input
                    onClick={() => add_sifaris(sd.id)}
                    id={sd.id}
                    type="checkbox"
                    checked={
                      sifarisDocumentId &&
                      sifarisDocumentId.length > 0 &&
                      sifarisDocumentId.includes(sd.id)
                        ? true
                        : false
                    }
                  />
                  {sd.doc_name}
                </td>
              </tr>
            ))}
          </Table>
        </div>
        <button
          className="primary_button"
          style={{ alignSelf: "flex-end" }}
          onClick={submit_docs}
        >
          Submit
        </button>
      </div>
    </CenterElement>
  );
};

export default SifarisFile;
