import { createSlice } from "@reduxjs/toolkit";
interface PermissionState {
  data: Array<Object>;
  loading: boolean;
  specific_permission: {
    parent_id: number;
    permission_name: string;
    guard_name: string;
  };
  parent_children: Array<Object>;
}

const initialState: PermissionState = {
  data: [],
  loading: false,
  specific_permission: {
    parent_id: 0,
    permission_name: "",
    guard_name: "",
  },
  parent_children: [],
};

export const permissionSlice = createSlice({
  name: "permissionSlice",
  initialState,
  reducers: {
    set_loading_permissions: (state, action) => {
      state.loading = action.payload;
    },
    setpermission: (state, action) => {
      state.data = action.payload;
    },
    resetFormPermission: (state) => {
      state.specific_permission = {
        parent_id: 0,
        permission_name: "",
        guard_name: "",
      };
    },
    setparentchildern: (state, action) => {
      state.parent_children = action.payload;
    },
    addpermission: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    getSpecificpermission: (state, action) => {
      state.specific_permission = {
        ...action.payload,
      };
    },
    setSpecificpermission: (state, action) => {
      state.specific_permission = {
        ...state.specific_permission,
        [action.payload.name]: action.payload.value,
      };
    },
    deletepermission: (state, action) => {
      state.data = [
        ...state.data.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
    },
  },
});

export const {
  setpermission,
  addpermission,
  deletepermission,
  getSpecificpermission,
  setSpecificpermission,
  setparentchildern,
  resetFormPermission,
  set_loading_permissions,
} = permissionSlice.actions;

export default permissionSlice.reducer;
