import { createSlice } from "@reduxjs/toolkit";
interface ProvinceState {
  data: Array<Object>;
  loading: boolean;
  specific_province: {
    province_en: string;
    province_np: string;
  };
}

const initialState: ProvinceState = {
  data: [],
  loading: false,
  specific_province: {
    province_en: "",
    province_np: "",
  },
};

export const provinceSlice = createSlice({
  name: "provinceSlice",
  initialState,
  reducers: {
    set_loading_province: (state, action) => {
      state.loading = action.payload;
    },
    setProvince: (state, action) => {
      state.data = action.payload;
    },
    addProvince: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    getSpecificProvince: (state, action) => {
      state.specific_province = {
        ...state,
        province_en: action.payload.province_en,
        province_np: action.payload.province_np,
      };
    },
    setSpecificProvince: (state, action) => {
      state.specific_province = {
        ...state.specific_province,
        [action.payload.name]: action.payload.value,
      };
    },
    deleteProvince: (state, action) => {
      state.data = [
        ...state.data.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
    },
  },
});

export const {
  setProvince,
  addProvince,
  deleteProvince,
  getSpecificProvince,
  setSpecificProvince,
  set_loading_province,
} = provinceSlice.actions;

export default provinceSlice.reducer;
