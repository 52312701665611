import { createSlice } from "@reduxjs/toolkit";
interface RoleState {
  data: Array<Object>;
  loading: boolean;
  roles_permissions: any;
  specific_role: {
    role: string;
    permissions: Array<Number>;
  };
  show_data: Array<Object>;
  items_per_page: any;
  current_page: any;
  filtered_data: Array<Object>;
}

const initialState: RoleState = {
  data: [],
  loading: false,
  roles_permissions: {},
  specific_role: {
    role: "",
    permissions: [],
  },
  show_data: [],
  items_per_page: 10,
  current_page: 1,
  filtered_data: [],
};

export const roleSlice = createSlice({
  name: "roleSlice",
  initialState,
  reducers: {
    set_loading_role: (state, action) => {
      state.loading = action.payload;
    },
    show_data_roles: (state) => {
      const startIndex = (state.current_page - 1) * state.items_per_page;
      const endIndex = startIndex + state.items_per_page;
      const displayData = state.filtered_data.slice(startIndex, endIndex);
      state.show_data = displayData;
    },
    search_items: (state, action) => {
      state.filtered_data =
        action.payload.trim().length !== 0
          ? state.data.filter((p: any) => {
              return p.role
                .toLowerCase()
                .includes(action.payload.toLowerCase());
            })
          : state.data;
      state.current_page = 1;
    },
    set_items_per_page: (state, action) => {
      state.items_per_page = action.payload;
    },
    set_current_page: (state, action) => {
      state.current_page = action.payload;
    },
    setrolespermission: (state, action) => {
      state.roles_permissions = action.payload;
    },
    setrole: (state, action) => {
      state.data = action.payload;
      state.filtered_data = action.payload;
    },
    addrole: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    getSpecificrole: (state, action) => {
      state.specific_role = action.payload;
    },
    resetRoles: (state) => {
      state.specific_role = {
        role: "",
        permissions: [],
      };
    },
    setSpecificrole: (state, action) => {
      state.specific_role = {
        ...state.specific_role,
        [action.payload.name]: action.payload.value,
      };
    },
    deleterole: (state, action) => {
      state.data = [
        ...state.data.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
      state.filtered_data = [
        ...state.filtered_data.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
    },
  },
});

export const {
  setrole,
  addrole,
  deleterole,
  getSpecificrole,
  setSpecificrole,
  setrolespermission,
  resetRoles,
  set_current_page,
  set_items_per_page,
  show_data_roles,
  search_items,
  set_loading_role
} = roleSlice.actions;

export default roleSlice.reducer;
