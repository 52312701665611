import { HiArrowUp } from "react-icons/hi";
import styles from "./index.module.scss";
import CardLayout from "../../component/layout";
const MainCard = () => {
  return (
    <CardLayout bg_color="#28621a">
      <div className={styles.card}>
        <div className={styles.card_details}>
          <p className={styles.card_title}>4,155</p>
          <p className={styles.card_sub_title}>Total Anugaman reported</p>
        </div>
        <p className={styles.card_sub_title_2}>
          <span>36%</span> <HiArrowUp /> Average this month
        </p>
      </div>
    </CardLayout>
  );
};
export default MainCard;
