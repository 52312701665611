import Table from "../../../component/table_container/main_table";
import TableHeader from "../../../component/table_container/table_header";
import TableSubHeader from "../../../component/table_container/table_sub_header";
import { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  deletellevel,
  setllevel,
  set_current_page,
  set_items_per_page,
  show_data_llevel,
  search_items,
} from "../../../redux/store/local_level";
import { toast } from "react-toastify";
import Modal from "../../../component/modal";
import {
  delete_llevel,
  get_llevels,
} from "../../../utils/api/federal_stucture/llevel";
const LocalLevel = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState<boolean>(false);
  const [delete_id, setDeleteId] = useState<number>();
  const data = useAppSelector((state) => state.llevel.show_data);
  const items = useAppSelector((state) => state.llevel.data);
  const filtered_data = useAppSelector(
    (state) => state.llevel.filtered_data
  );
  const items_per_page = useAppSelector(
    (state) => state.llevel.items_per_page
  );
  const current_page = useAppSelector(
    (state) => state.llevel.current_page
  );
  useEffect(() => {
    if (data && data?.length === 0) {
      get_llevels()
        .then((res) => {
          dispatch(setllevel(res?.data?.data));
          dispatch(show_data_llevel());
        })
        .catch((err) => {
          toast.error("स्थानीय स्तर डाटा ल्याउने क्रममा त्रुटि");
        });
    }
  }, [dispatch]);
  const deleteHandler = (id: number) => {
    setDeleteId(id);
    setModal(true);
  };
  const deleteData = () => {
    if (delete_id !== null && delete_id !== undefined) {
      delete_llevel(delete_id)
        .then((res: any) => {
          dispatch(deletellevel(delete_id));
          toast.success("स्थानीय तह सफलतापूर्वक मेटिएको छ");
          setModal(false);
        })
        .catch((err) => {
          toast.error("डाटा मेटाउँदा त्रुटि भयो!");
          setModal(false);
        });
    }
  };
  const show_items = (e: any) => {
    dispatch(set_items_per_page(e.value));
    dispatch(show_data_llevel());
  };
  const page_change = (event: any, pageNumber: any) => {
    dispatch(set_current_page(pageNumber));
    dispatch(show_data_llevel());
  };
  const change_input = (e: any) => {
    dispatch(search_items(e.target.value));
    dispatch(show_data_llevel());
  };
  return (
    <>
      <Modal
        open={modal}
        close_modal={() => setModal(false)}
        action={deleteData}
        title="local level"
      />
      <div className="table_wrapper">
        <div className="component_wrapper table_container">
          <TableHeader
            primary_button_name="
            स्थानीय तह थप्नुहोस्"
            table_title="स्थानीय तह"
            items_length={items ? items?.length : 0}
            on_add={() => navigate("add")}
          />
          <TableSubHeader
            item_select={true}
            no_of_items={show_items}
            placeholder="Search local level data...."
            input_handler={change_input}
          />
          <Table
            page_change={page_change}
            current_page={current_page}
            page_count={Math.ceil(filtered_data?.length / items_per_page)}
            pagination_display={items.length >= 10}
            item_range={
              data && data?.length * (current_page - 1) +
              "-" +
              current_page * data?.length +
              " items"
            }
            table_headings={[
              "S.N.",
              "local level name(en)",
              "local level name(np)",
              "Province Name(en)",
              "district name(en)",
              "last modified by",
              "actions",
            ]}
          >
            {data && data?.map((p: any, i: any) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{p.lgname_en}</td>
                <td>{p.lgname_np}</td>
                <td> {p.province?.province_en}</td>
                <td>{p.district?.district_en}</td>
                <td>{p.updated_at ? p.updated_at : "Not Modified Yet"}</td>
                <td className="align_items table_icons">
                  <AiOutlineDelete
                    data-tooltip-id="view"
                    data-tooltip-content="Delete"
                    onClick={() => deleteHandler(p.id)}
                  />
                  <FiEdit2
                    data-tooltip-id="view"
                    data-tooltip-content="Update"
                    onClick={() =>
                      navigate("update/" + p.id)
                    }
                  />
                </td>
              </tr>
            ))}
          </Table>
        </div>
      </div>
    </>
  );
};
export default LocalLevel;
