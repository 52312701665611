import { HiOutlineUserCircle } from "react-icons/hi";
import { useRef, useState } from "react";
import { ControlledMenu, MenuItem, useHover } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { FiLogOut } from "react-icons/fi";
import { FiSettings } from "react-icons/fi";
import { FiHelpCircle } from "react-icons/fi";
import UserImg from "../../../assest/images/fummy.jpg";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import styles from "./index.module.scss";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../../hooks/hook";
import { setLogin } from "../../../redux/store/slice/loginSlice";
import { remove_checks } from "../../../redux/store/tabs";
import { resetProfileForm } from "../../../redux/store/public_users";
export default function AccountMenu() {
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen);
  const logout_handler = () => {
    sessionStorage.clear();
    dispatch(setLogin());
  };
  const profile_handler = () => {
    navigate("/profile");
  };
  return (
    <>
      <div
        className={["flex center", styles.user_modal].join(" ")}
        ref={ref}
        {...anchorProps}
      >
        <img src={UserImg} className="user_img" alt="user_img" />
        <MdOutlineKeyboardArrowDown />
      </div>
      <div className={styles.links}>
        <ControlledMenu
          {...hoverProps}
          state={isOpen ? "open" : "closed"}
          anchorRef={ref}
          arrow
          onClose={() => setOpen(false)}
        >
          <MenuItem>
            <div onClick={profile_handler}>
              <HiOutlineUserCircle /> Profile
            </div>
          </MenuItem>
          <hr />
          <MenuItem>
            <div>
              <FiSettings /> Settings
            </div>
          </MenuItem>
          <MenuItem>
            <div>
              <FiHelpCircle /> Helps
            </div>
          </MenuItem>
          <hr />
          <MenuItem>
            <div onClick={logout_handler}>
              <FiLogOut />
              Logout
            </div>
          </MenuItem>
        </ControlledMenu>
      </div>
    </>
  );
}
