import TableLayout from "../../component/table_container/main_table";
import style from "./index.module.scss";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import Http from "../../utils/httpClients";
import {
  setSifarisForm,
  setSifarisTableHeader,
  setSifarisTableStatus,
  setSifarisTableTitle,
} from "../../redux/store/sifaris_type";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import PreviewSelectField from "../dynamic_form/output_input_field/selectinput";
import PreviewTextField from "../dynamic_form/output_input_field/textarea";
import PreviewInputField from "../dynamic_form/output_input_field";
import { TiDeleteOutline } from "react-icons/ti";
import Modal from "../../component/modal";
import {
  addTableRows,
  changeInputFields,
  deleteTableRows,
} from "../../redux/store/add_sifaris";
import { AiOutlineInsertRowAbove } from "react-icons/ai";
const Table = (props: {
  not_add?: boolean;
  table?: any;
  actions?: boolean;
  delete?: boolean;
  data?: any;
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const table_row = useAppSelector(
    (state: any) => state.add_sifaris.table_rows
  );
  let alternate_url = "/system_configuration/sifaris_type/table/title/create/";
  let update_url = "/system_configuration/sifaris_type/table/title/update/";
  const delete_cell = (index: number) => {
    dispatch(deleteTableRows({ id: index }));
  };
  const table_cell_add = () => {
    dispatch(addTableRows());
  };
  const delete_form = () => {
    Http.DELETE(
      "/sifaristables/" + props.table.map((p: any) => p.id).join(","),
      true
    )
      .then((res) => {
        toast.success("Table has been deleted!");
        dispatch(setSifarisTableStatus("create"));
        dispatch(setSifarisTableTitle(""));
        dispatch(setSifarisTableHeader(""));
        setModal(false);
        navigate(alternate_url + id, { replace: true });
      })
      .catch((err) => {
        toast.error("Error occured while deleting table!");
      });
  };
  const edit_handler = () => {
    dispatch(setSifarisTableTitle(props.table[0].table_title));
    dispatch(setSifarisTableHeader(props.table[0].table_header));
    dispatch(setSifarisForm(props.table));
    navigate(update_url + id);
  };
  const input_change_handler = (value: string, name: string, id: number) => {
    dispatch(changeInputFields({ value, name, id }));
  };
  return (
    <>
      {props.actions ? (
        <>
          <Modal
            action={delete_form}
            open={modal}
            close_modal={() => setModal(false)}
            title="table"
          />
          <div className="flex" style={{ alignSelf: "flex-end", gap: "1rem" }}>
            <>
              {" "}
              <button className="primary_button" onClick={edit_handler}>
                Update Table
              </button>
              <button
                className="secondary_button"
                onClick={() => setModal(true)}
              >
                Delete Table
              </button>
            </>
          </div>
        </>
      ) : null}

      <div
      style={{width:"100%"}}
      >
        <fieldset
          style={{
            border: "1px solid #ccc",
            borderRadius: "5px",
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            backgroundColor: "#fff",
          }}
        >
          <legend
            className="bold"
            style={{
              color: "#1f60ba",
              fontSize: "1.5rem",
              padding: "0 8px",
            }}
          >
            {props.table && props.table.length > 0
              ? props.table[0].table_title
              : null}
          </legend>
          <div className={style.table_design}>
            <TableLayout
              table_headings={
                props.table && props.table.length > 0
                  ? props.table[0].table_header.split("|")
                  : [""]
              }
            >
              {table_row.map((r: any, ri: number) => (
                <tr style={{ position: "relative" }}>
                  {props.table.map((p: any) => (
                    <td className={style.table_data}>
                      {p.input_type !== "select" &&
                      p.input_type !== "textarea" ? (
                        <PreviewInputField
                          input_data={{
                            placeholder: p.placeholder,
                            input_type: p.input_type,
                            value: r[p.name],
                          }}
                          on_change={(e: any) =>
                            input_change_handler(e.target.value, p.name, ri)
                          }
                        />
                      ) : null}
                      {p.input_type === "select" ? (
                        <PreviewSelectField
                          input_data={{
                            placeholder: p.placeholder,
                            input_type: p.input_type,
                          }}
                        />
                      ) : null}
                      {p.input_type === "textarea" ? (
                        <PreviewTextField
                          input_data={{
                            placeholder: p.placeholder,
                            input_type: p.input_type,
                          }}
                        />
                      ) : null}
                    </td>
                  ))}
                  {!props.delete && (
                    <>
                      <div
                        onClick={() => delete_cell(ri)}
                        className={style.delete_cell}
                      >
                        <TiDeleteOutline />
                      </div>
                    </>
                  )}
                </tr>
              ))}
            </TableLayout>
          </div>
          {!props.not_add && (
            <button
              onClick={table_cell_add}
              style={{ alignSelf: "flex-end" }}
              className="primary_button align_items"
            >
              <AiOutlineInsertRowAbove />
              Add Row
            </button>
          )}
        </fieldset>
      </div>
    </>
  );
};
export default Table;
