import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./modal.module.scss";
import Backdrop from "../backdrop/black_backdrop";
interface Props {
  show: boolean;
  onClose: () => void;
  title?: string;
  message?: string;
  children?: React.ReactNode;
  sub_title?: string;
  fix_height?: boolean;
}

const CustomModal: React.FC<Props> = ({
  show,
  onClose,
  title,
  children,
  sub_title,
  fix_height
}) => {
  const [isOpen, setIsOpen] = useState(show);

  useEffect(() => {
    setIsOpen(show);
  }, [show]);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <Backdrop open={isOpen}>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="modal"
            style={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div
              className={[
                styles.modalcontainer,
                fix_height ? styles.modal_fix_height : "",
              ].join(" ")}
            >
              <div className={styles.modalcontent}>
                <div className={styles.modalheading}>
                  <div>
                    <h5 className={styles.modal_title}>{title}</h5>
                    <p>{sub_title}</p>
                  </div>
                  <button
                    type="button"
                    className={styles.xbutton}
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={handleClose}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {/* <p>{message}</p> */}
                  {children}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Backdrop>
  );
};

export default CustomModal;
