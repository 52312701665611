import Http from "../../../../utils/httpClients";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import Container from "../container";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getSpecificpermission,
  set_loading_permissions,
  setpermission,
} from "../../../../redux/store/permissions";
import {
  get_permission,
  update_permission,
} from "../../../../utils/api/user_configuration/permissions";
const UpdatePermission = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (id !== undefined) {
      get_permission(id)
        .then((res) => {
          dispatch(getSpecificpermission(res.data.data));
        })
        .catch((err) => {
          toast.error("Error occured while fetching permission data!", {
            position: "bottom-right",
          });
        });
    }
  }, [dispatch]);
  const form_handler = (e: any) => {
    e.preventDefault();
    const form_data = new FormData(e.target);
    dispatch(set_loading_permissions(true));
    if (id !== undefined) {
      update_permission(id?.toString(), form_data)
        .then(async (res) => {
          dispatch(set_loading_permissions(false));
          await Http.GET("/permissions", true).then((res) => {
            console.log(res);
            dispatch(setpermission(res.data.data));
          });
          toast.success("Permission has been updated!", {
            position: "bottom-right",
          });
          navigate(-1);
        })
        .catch((err) => {
          dispatch(set_loading_permissions(false));
          console.log(err);
          toast.error("Error occued while updating permission!", {
            position: "bottom-right",
          });
        });
    }
  };
  return (
    <>
      <Container
        form_handler={form_handler}
        title="सम्पादन"
        update
      />
    </>
  );
};
export default UpdatePermission;
