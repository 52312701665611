import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Http from "../../../../../utils/httpClients";
import { toast } from "react-toastify";
import { CenterElement } from "../../../../../component/manage_layout/center_element";
import { IoReturnUpBack } from "react-icons/io5";
import { FiEdit2 } from "react-icons/fi";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import ReturnButton from "../../../../../component/buttons/return_button";
import { UpdateButton } from "../../../../../component/buttons/update_button";
const Childs = (props: { label: string; value: string }) => {
  return (
    <div className="input_container">
      <p className="input_label">{props.label}</p>
      <p className="input_p">{props.value}</p>
    </div>
  );
};
const Container = (props: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
      }}
    >
      {props.children}
    </div>
  );
};
const ViewOffice = () => {
  const navigate = useNavigate();
  const [office_data, setOfficeData] = useState<any>();
  const { id } = useParams();
  useEffect(() => {
    Http.GET("/offices/" + id, true)
      .then((res) => {
        setOfficeData(res.data.data);
      })
      .catch((err) => {
        toast.error("Error occured while fetching office data");
      });
  }, [id]);
  return (
    <CenterElement>
      <div
        className={"component_wrapper"}
        style={{ width: "70%", margin: "0 auto", justifyContent: "center" }}
      >
        <div
          className="flex center"
          style={{ justifyContent: "space-between", paddingBottom: "1rem" }}
        >
          <ReturnButton />
          <p className="title bold align_items" style={{ fontSize: "2rem" }}>
            <HiOutlineOfficeBuilding />
            कार्यालय विवरण
          </p>
          <UpdateButton url={"/system_configuration/office/update/" + id} />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Container>
            <Childs label="Office Name" value={office_data?.office_name} />
            <Childs
              label="Office Address"
              value={office_data?.office_address}
            />
          </Container>
          <Container>
            <Childs label="Office Phone" value={office_data?.office_phone} />
            <Childs label="Office Email" value={office_data?.office_email} />
          </Container>{" "}
          <Container>
            <Childs label="Longitude" value={office_data?.longitude} />
            <Childs label="Latitude" value={office_data?.latitude} />
          </Container>{" "}
          <Container>
            <Childs
              label="Province"
              value={office_data?.province?.province_en}
            />
            <Childs
              label="District"
              value={office_data?.district?.district_en}
            />
          </Container>
          <Container>
            <Childs
              label="Office Type"
              value={office_data?.office_type?.office_type}
            />
            <Childs
              label="Local Level Type"
              value={office_data?.locallevel?.lgname_en}
            />
          </Container>
        </div>
      </div>
    </CenterElement>
  );
};
export default ViewOffice;
