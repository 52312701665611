import style from "../../index.module.scss";
import Tabs from "../../../../component/tabs";
import AccountSettings from "../account_settings/add";
import AccountSettingsUpdate from "../account_settings/update";
import FamilyInfo from "../family_info";
import Documents from "../documents";
import FamilyDocuments from "../family_member_document";
import { useParams } from "react-router";
import FamilyRelations from "../family_relation";
import { useAppSelector } from "../../../../hooks/hook";
import React from "react";
const Fields = () => {
  const { id } = useParams();
  const [hide_button, setButton] = React.useState<boolean>(false);
  const checkedTabs = useAppSelector((state: any) => state.tab?.checked_tabs);
  const tabs = [
    {
      title: "Family Profile",
      content: id ? <AccountSettingsUpdate /> : <AccountSettings />,
    },
    {
      title: "Family Document",
      content: <Documents />,
      exit_button: true,
    },
    {
      title: "Family Member",
      content: <FamilyInfo hide_buttons={() => setButton(!hide_button)} />,
      exit_button: hide_button ? false : true,
    },
    {
      title: checkedTabs.includes(3)
        ? "Family Member Docs..."
        : "Family Member Documents",
      content: <FamilyDocuments />,
      exit_button: true,
    },
    {
      title: "Family Relationship",
      content: <FamilyRelations />,
      exit_button: true,
    },
  ];
  return (
    <div className={style.fields}>
      <div style={{ padding: "1.5rem" }}>
        <p className={style.medium_bold_font}>Family Profiles Settings</p>
        <p className={style.light_grey_font}>
          Allowed file types: png,jpg,jpeg
        </p>
      </div>
      <Tabs tabs={tabs} />
    </div>
  );
};
export default Fields;
