import { useEffect, useState } from "react";
import style from "./index.module.scss";
import Http from "../../../../../utils/httpClients";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { CenterElement } from "../../../../../component/manage_layout/center_element";
import { get_role } from "../../../../../utils/api/user_configuration/roles";
import ReturnButton from "../../../../../component/buttons/return_button";
import { UpdateButton } from "../../../../../component/buttons/update_button";
const ViewRole = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const [users, setUser] = useState<any>();
  const [permissions, setPermissions] = useState<any>();
  useEffect(() => {
    if (id !== undefined) {
      get_role(id)
        .then((res: any) => {
          setData(res.data.data);
          setUser(res.data.data.users);
          setPermissions(res.data.data.permissions);
        })
        .catch((err: any) => {
          console.log(err);
          toast.error("Error occured while fetching role data!");
        });
    }
  }, [id]);
  return (
    <CenterElement>
      <div
        className="component_wrapper"
        style={{ width: "70%", margin: "auto" }}
      >
        <div className="flex" style={{ justifyContent: "space-between" }}>
          <ReturnButton />
          <p className="title">{data?.role}</p>
          <UpdateButton url={"/user_configuration/role/update/" + id} />
        </div>
        <div className={style.table}>
          <div className={style.section}>
            <p className={style.head}>allowed permission</p>
            <div>
              {permissions &&
                permissions.map((p: any) => (
                  <p
                    className={style.children}
                    onClick={() =>
                      navigate("/user_configuration/permission/view/" + p.id)
                    }
                  >
                    {p.permission_name}
                  </p>
                ))}
            </div>
          </div>
          <div className={style.section}>
            <p className={style.head}>allowed users</p>
            <div>
              {users &&
                users.map((p: any) => (
                  <p
                    onClick={() =>
                      navigate("/user_configuration/user/view/" + p.id)
                    }
                    className={style.children}
                  >
                    {p.name}
                  </p>
                ))}
            </div>
          </div>
        </div>
      </div>
    </CenterElement>
  );
};
export default ViewRole;
