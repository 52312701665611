import { Route, Routes } from "react-router";
import Designation from "..";
import AddDesignation from "../manage_designation/add";
import UpdateDesignation from "../manage_designation/update";
export const DesignationRoutes = () => {
  return (
    <Routes>
      <Route
        path="/system_configuration/designation"
        element={<Designation />}
      />
      <Route
        path="/system_configuration/designation/add"
        element={<AddDesignation />}
      />
      <Route
        path="/system_configuration/designation/update/:id"
        element={<UpdateDesignation />}
      />
    </Routes>
  );
};
