import ManageLayout from "../../../../component/manage_layout";
import Http from "../../../../utils/httpClients";
import { toast } from "react-toastify";
import React, { useEffect } from "react";
import InputField from "../../../../component/input_container";
import SelectInput from "../../../../component/input_container/select_input";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { setdesignation } from "../../../../redux/store/designation";
import { setoffice } from "../../../../redux/store/office";
import { setrole } from "../../../../redux/store/roles";
import { setSpecificuser } from "../../../../redux/store/user";
import { CenterElement } from "../../../../component/manage_layout/center_element";
interface Props {
  form_handler: React.FormEventHandler;
  title?: string;
  btn_name?: string;
  password_field?: boolean;
  icon?: JSX.Element;
  update?:boolean;
}
const active_options = [
  {
    label: "Active",
    value: 0,
  },
  { label: "InActive", value: 1 },
];
const UserContainer = (props: Props) => {
  const dispatch = useAppDispatch();
  const designations = useAppSelector((state) => state.designation.data);
  const loading = useAppSelector((state) => state.user.loading);
  const roles = useAppSelector((state) => state.roles.data);
  const offices = useAppSelector((state) => state.offices.data);
  const specific_user = useAppSelector((state) => state.user.specific_user);
  useEffect(() => {
    if (offices.length === 0) {
      Http.GET("/offices", true)
        .then((res: any) => {
          dispatch(setoffice(res.data.data));
        })
        .catch((err) => {
          toast.error("Error occured while fetching offices!");
        });
    }
    if (designations.length === 0) {
      Http.GET("/designations", true)
        .then((res: any) => {
          dispatch(setdesignation(res.data.data));
        })
        .catch((err) => {
          toast.error("Error occured while fetching designations!");
        });
    }
    if (roles.length === 0) {
      Http.GET("/roles", true)
        .then((res: any) => {
          dispatch(setrole(res.data.data));
        })
        .catch((err) => {
          toast.error("Error occured while fetching roles!");
        });
    }
  }, [dispatch]);
  const input_change = (e: any) => {
    dispatch(setSpecificuser({ name: e.target.name, value: e.target.value }));
  };
  const select_change = (name: string, value: any) => {
    console.log(name, value);
    dispatch(setSpecificuser({ name, value }));
  };
  console.log(specific_user);
  return (
    <>
      {designations.length > 0 && offices.length > 0 && roles.length > 0 ? (
        <CenterElement>
          <ManageLayout
            icon={props.icon}
            form_handler={props.form_handler}
            page_title={"प्रयोगकर्ता " + props.title}
            loading={loading}
            update={props.update}
          >
            <div
              className="flex center space_between"
              style={{ gap: "2rem", width: "100%" }}
            >
              <InputField
                placeholder="Enter your full name"
                type="text"
                label="Fullname"
                value={specific_user?.name}
                required
                on_change={input_change}
                name="name"
              />
              <InputField
                placeholder="Enter your full address"
                type="text"
                label="Address"
                value={specific_user?.address}
                required
                name="address"
                on_change={input_change}
              />
            </div>
            <div
              className="flex center space_between"
              style={{ gap: "2rem", width: "100%" }}
            >
              <InputField
                placeholder="Enter your email address"
                type="email"
                required
                label="Email Address"
                name="email"
                value={specific_user?.email}
                on_change={input_change}
              />
              <InputField
                placeholder="Enter your phone number"
                type="number"
                required
                label="Phone Number"
                value={specific_user?.phone_number}
                on_change={input_change}
                name="phone_number"
              />
            </div>
            <div
              className="flex center space_between"
              style={{ gap: "2rem", width: "100%" }}
            >
              {props.password_field ? (
                <InputField
                  placeholder="Enter your password"
                  type="password"
                  required
                  label="Password"
                  value={specific_user?.password}
                  on_change={input_change}
                  name="password"
                />
              ) : null}
              {!props.password_field ? (
                <SelectInput
                  placeholder="Select Status"
                  label="Status"
                  options={active_options}
                  required
                  name="active_status"
                  value={active_options.filter(
                    (p: any) =>
                      p.value.toString() ===
                      specific_user?.active_status.toString()
                  )}
                  on_change={(e: any) =>
                    select_change("active_status", e.value)
                  }
                />
              ) : (
                <></>
              )}

              <SelectInput
                placeholder="Select Office"
                label="Office"
                name="office_id"
                required
                options={offices.map((p: any) => {
                  return { label: p.office_name, value: p.id };
                })}
                value={offices
                  .map((p: any) => {
                    return { label: p.office_name, value: p.id };
                  })
                  ?.filter(
                    (p: any) =>
                      p.value.toString() ===
                      specific_user?.office_id?.toString()
                  )}
                on_change={(e: any) => select_change("office_id", e.value)}
              />
            </div>
            <div
              className="flex center space_between"
              style={{ gap: "2rem", width: "100%" }}
            >
              <SelectInput
                placeholder="Select designation"
                label="Designation"
                options={designations.map((p: any) => {
                  return { label: p.designation, value: p.id };
                })}
                required
                name="designation_id"
                value={designations
                  .map((p: any) => {
                    return { label: p.designation, value: p.id };
                  })
                  ?.filter(
                    (p: any) =>
                      p.value.toString() ===
                      specific_user?.designation_id?.toString()
                  )}
                on_change={(e: any) => select_change("designation_id", e.value)}
              />
              <SelectInput
                placeholder="Select Role"
                label="Role"
                options={roles.map((p: any) => {
                  return { label: p.role, value: p.id };
                })}
                required
                name="roles"
                multi
                value={roles
                  .map((p: any) => {
                    return { label: p.role, value: p.id };
                  })
                  .filter((p: any) => specific_user?.roles.includes(p.value))}
                on_change={(e: any) =>
                  select_change(
                    "roles",
                    e.map((p: any) => p.value)
                  )
                }
              />
            </div>
            {props.password_field ? (
              <div style={{ width: "48%", alignSelf: "flex-start" }}>
                <SelectInput
                  placeholder="Select Status"
                  label="Status"
                  options={active_options}
                  required
                  name="active_status"
                  value={active_options.filter(
                    (p: any) =>
                      p.value.toString() ===
                      specific_user?.active_status.toString()
                  )}
                  on_change={(e: any) =>
                    select_change("active_status", e.value)
                  }
                />
              </div>
            ) : (
              <></>
            )}
          </ManageLayout>
        </CenterElement>
      ) : null}
    </>
  );
};
export default UserContainer;
