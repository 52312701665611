import OfficeContainer from "..";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/hook";
import { useParams } from "react-router";
import { useEffect } from "react";
import Http from "../../../../../utils/httpClients";
import {
  getSpecificoffice,
  set_loading_office,
} from "../../../../../redux/store/office";
import { toast } from "react-toastify";
const UpdateOffice = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const data = useAppSelector((state: any) => state.offices.specific_office);
  useEffect(() => {
    Http.GET("/offices/" + id, true)
      .then((res) => {
        let data = res.data.data;
        dispatch(
          getSpecificoffice({
            ...data,
            office_type_id: data.office_type.id,
            province_id: data.province.id,
            district_id: data.district.id,
            lg_id: data.locallevel.id,
          })
        );
      })
      .catch((err) => {
        toast.error("Error occured while fetching office data!");
      });
  }, [id, dispatch]);
  const form_handler = (e: any) => {
    e.preventDefault();
    dispatch(set_loading_office(true));
    Http.PUT("/offices/" + id, data, true)
      .then((res) => {
        dispatch(set_loading_office(false));
        toast.success("Office has been updated");
      })
      .catch((err) => {
        dispatch(set_loading_office(false));
        toast.error("Error occured while updating office!");
      });
  };
  return (
    <OfficeContainer
      page_title="सम्पादन"
      form_data={form_handler}
      update
    />
  );
};
export default UpdateOffice;
