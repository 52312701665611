import Table from "../../../component/table_container/main_table";
import Signature from "../../lang_nepali/signature";
import style from "../../content.module.scss";
const Content = () => {
  let headings = ["क्र.स.", "नाम", "नाता", "घर नं."];
  return (
    <>
      <div style={{ marginBottom: "4rem" }}>
        <p className="paragraph_indent">
          .............महा/उपमहा/नगरपालिका/गाउँपालिका वडा नं.........(साबिकको
          ठेगाना..........................) अन्तर्गत स्थायी/अस्थायी बसोबास गर्ने
          श्री....................तथा श्रीमती ......................... को
          आर्थिक अवस्था कमजोर भएको / आयश्रोत न्यून भएको हुदा निजहरुका देहाय
          बमोजिमका छोरा / छोरी लाई नियम अनुसार छात्रवृत्तिको लागि सिफारिस गरिन्छ
          |
        </p>
        <div className={style.sifaris_docs_table}>
          <Table table_headings={headings}></Table>
        </div>
      </div>
      <Signature />
    </>
  );
};
export default Content;
