import Table from "../../template_form/test";
import { useParams, useNavigate } from "react-router";
import { useAppDispatch } from "../../../hooks/hook";
import Http from "../../../utils/httpClients";
import { useEffect, useState } from "react";
import style from "./index.module.scss";
import { resetSifarisForm } from "../../../redux/store/sifaris_type";
import {  toast } from "react-toastify";
import { resetSearchData, setTableFields } from "../../../redux/store/add_sifaris";
const SifarisTable = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [tables, setTables] = useState<any>();
  useEffect(() => {
    dispatch(resetSearchData())
    Http.GET("/sifaristypes/" + id, true)
      .then((res: any) => {
        let data = res.data.data;
        console.log(res.data.data);
        const groupedArrays = data.sifaristables.reduce(
          (result: any, obj: any) => {
            const tabValue = obj.table_header;
            if (!result[tabValue]) {
              result[tabValue] = [];
            }
            result[tabValue].push(obj);
            return result;
          },
          {}
        );
        setTables(Object.values(groupedArrays));
        dispatch(
          setTableFields(
            data.sifaristables.map((p: any) => {
              return { [p.name]: "" };
            })
          )
        );
      })
      .catch((err) => {
        toast.error("Error while fetching forms");
      });
  }, [id, dispatch]);
  const addHandler = () => {
    dispatch(resetSifarisForm());
    navigate("/system_configuration/sifaris_type/table/title/create/" + id);
  };
  return (
    <>
      <div
        className="flex center"
        style={{ flexDirection: "column", padding: "1rem", gap: "1rem" }}
      >
        <button
          className={["primary_button flex center", style.svg_style].join("")}
          style={{ alignSelf: "flex-start" }}
          onClick={addHandler}
        >
          Add Table
        </button>
        <div
          className="flex"
          style={{
            width: "85%",
            flexDirection: "column",
            gap: "1rem",
            alignItems: "flex-end",
          }}
        >
          {tables &&
            tables.map((p: any) => <Table delete actions table={p} not_add />)}
        </div>
      </div>
    </>
  );
};
export default SifarisTable;
