import Http from "../../../httpClients";
export const get_provinces = async () => Http.GET("/provinces", true);
export const create_province = async (data: any) =>
  Http.POST("/provinces", data, true);
export const update_province = async (id: number, data: any) =>
  Http.PUT("/provinces/" + id, data, true);
export const delete_province = async (id: number) =>
  Http.DELETE("/provinces/" + id, true);
export const get_province = async (id: number) =>
  Http.GET("/provinces/" + id, true);
