import React from "react";
import { Route, Routes } from "react-router";
import TemplateNep4 from "../template_nep_4";
import Template2 from "../template_2";
import Template3 from "../template_3";
import TemplateNep1 from "../template_nep_1";
import TemplateNep2 from "../template_nep_2";
import TemplateNep3 from "../template_nep_3";
import TemplateNep5 from "../template_nep_5";
import TemplateNep6 from "../template_nep_6";
import TemplateNep7 from "../template_nep_7";
import TemplateNep8 from "../template_nep_8";
import TemplateNep9 from "../template_nep_9";
import TemplateNep10 from "../template_nep_10";
import TemplateNep11 from "../template_nep_11";
import TemplateNep12 from "../template_nep_12";
import { SuspenseWrapper } from "../../component/suspense";
const Template1 = React.lazy(() => import("../template_1"));

export const SifarisDocument = () => (
  <Routes>
    <Route
      path="/sifaris_document/1"
      element={
        <SuspenseWrapper>
          <TemplateNep7 />
        </SuspenseWrapper>
      }
    />
    <Route path="/sifaris_document/3" element={<TemplateNep6 />} />
    <Route path="/sifaris_document/4" element={<TemplateNep5 />} />
    <Route path="/sifaris_document/8" element={<TemplateNep12 />} />
    <Route path="/sifaris_document/14" element={<TemplateNep10 />} />
    <Route path="/sifaris_document/18" element={<TemplateNep4 />} />
    <Route path="/sifaris_document/7" element={<Template1 />} />
    <Route path="/sifaris_document/9" element={<Template2 />} />
    <Route path="/sifaris_document/3" element={<Template3 />} />
    <Route path="/sifaris_document/4" element={<TemplateNep1 />} />
    <Route path="/sifaris_document/5" element={<TemplateNep2 />} />
    <Route path="/sifaris_document/11" element={<TemplateNep8 />} />
    <Route path="/sifaris_document/12" element={<TemplateNep9 />} />
    <Route path="/sifaris_document/14" element={<TemplateNep11 />} />
    <Route path="/sifaris_document/20" element={<TemplateNep3 />} />
  </Routes>
);
