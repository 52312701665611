import SifarisDocLayout from "..";
import Header from "../lang_english/header";
import Footer from "../lang_nepali/footer";
import Content from "./contents";

const Template2 = () => {
  return (
    <SifarisDocLayout>
      <div className="template_content_gap">
        <Header />
        <Content />
      </div>
      <Footer />
    </SifarisDocLayout>
  );
};
export default Template2;
