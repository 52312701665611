import { createSlice } from "@reduxjs/toolkit";
interface sifarisProgress {
  width: number;
  current_state: number;
  active_state: Array<Number>;
}

const initialState: sifarisProgress = {
  width: 0,
  current_state: 1,
  active_state: [1],
};

export const sifarisProgressSlice = createSlice({
  name: "sifarisProgressSlice",
  initialState,
  reducers: {
    setWidth: (state, action) => {
      console.log(action.payload)
      state.width = 17 * action.payload;
    },
    setCurrentState: (state, action) => {
      state.current_state = action.payload;
    },
    setActiveState: (state, action) => {
      state.active_state = [...state.active_state, action.payload];
    },
    resetActiveState: (state) => {
      state.active_state = [1];
    },
  },
});

export const { setWidth, setCurrentState, setActiveState,resetActiveState } =
  sifarisProgressSlice.actions;

export default sifarisProgressSlice.reducer;
