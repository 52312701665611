import TemplateForm from "../../../template_form";
import InputField from "../../../../component/input_container";
import style from "./index.module.scss";
import Http from "../../../../utils/httpClients";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../hooks/hook";
import {
  resetSearchData,
  setUserPersonalData,
  setUserRelationData,
} from "../../../../redux/store/add_sifaris";
import { IoSearchOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { AnimatePresence, motion } from "framer-motion";
import ReturnButton from "../../../../component/buttons/return_button";
const FillUpForm = () => {
  const [dispaly_search, setDisplaySearch] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(resetSearchData());
  }, [dispatch]);
  const SearchUser = async (e: any) => {
    e.preventDefault();
    const form_data = new FormData(e.target);
    let url = "?";
    let mobile_url = form_data.get("mobile");
    if (mobile_url) {
      url += "mobile=" + mobile_url;
    }
    if (form_data.get("ctz_num")) {
      if (url.length > 0) {
        url += "&ctz_num=" + form_data.get("ctz_num");
      } else {
        url += "ctz_num=" + form_data.get("ctz_num");
      }
    }
    if (form_data.get("fullname_en")) {
      if (url.length > 0) {
        url += "&fullname_en=" + form_data.get("fullname_en");
      } else {
        url += "fullname_en=" + form_data.get("fullname_en");
      }
    }
    await Http.GET("/familymember/search" + url, true)
      .then(async (res) => {
        let data = res.data.data[0];
        dispatch(setUserPersonalData(data));
        toast.success("हामीले मिल्दो विवरण भएको प्रयोगकर्ता फेला पार्‍यौं!", {
          position: "bottom-right",
        });

        if (res.status === 200 && res.data.data.length > 0) {
          await Http.GET(
            "/relations/familymembers/" + res.data.data[0].id,
            true
          )
            .then((response) => {
              let data = response.data.data;
              dispatch(setUserRelationData(data));
            })

            .catch((err) => {
              toast.error("Sorry we couldn't find family members details");
            });
        }
      })
      .catch((err) => {
        toast.error("माफ गर्नुहोस् हामीले प्रयोगकर्ता फेला पार्न सकेनौं", {
          position: "bottom-right",
        });
      });
  };
  return (
    <div style={{ backgroundColor: "#f9f9ff", border: "none" }}>
      <AnimatePresence>
        {!dispaly_search && (
          <motion.div
            className="align_items"
            style={{
              justifyContent: "space-between",
              width: "88%",
              margin: "0 auto",
              marginTop: "1rem",
            }}
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <ReturnButton />
            <button
              onClick={() => setDisplaySearch(true)}
              className="primary_button align_items"
            >
              <IoSearchOutline />
              Search User
            </button>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {dispaly_search && (
          <motion.form
            onSubmit={SearchUser}
            className={style.search_container}
            style={{
              display: dispaly_search ? "block" : "none",
              position: dispaly_search ? "sticky" : "absolute",
              top: dispaly_search ? "145px" : "0",
            }}
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            transition={{ duration: 1 }}
            autoComplete="off"
          >
            <div className={style.search_bar}>
              <InputField
                type="text"
                name="ctz_num"
                placeholder="Search by citizenship number"
              />
              <InputField
                type="number"
                name="mobile"
                placeholder="Search by mobile number"
              />
              <InputField
                type="text"
                name="fullname_en"
                placeholder="Search by full name(EN)"
              />
              <button
                style={{ alignSelf: "flex-end" }}
                className="primary_button align_items"
              >
                <IoSearchOutline />
                Search
              </button>
            </div>
            <RxCross2
              className={style.remove_search}
              onClick={() => setDisplaySearch(false)}
            />
          </motion.form>
        )}
      </AnimatePresence>
      <TemplateForm button />
    </div>
  );
};
export default FillUpForm;
