import Pagination from "@mui/material/Pagination";
import "./index.scss";
interface TableProps {
  table_headings?: Array<String>;
  footer?: boolean;
  children?: JSX.Element | JSX.Element[];
  checkbox?: boolean;
  select_all?: any;
  item_range?: string;
  pagination_display?: boolean;
  page_count?: number;
  current_page?: any;
  page_change?: any;
}
const Table = (props: TableProps) => {
  return (
    <>
      <table className="table_color_alternate">
        <tr>
          {props.table_headings && (
            <th>
              {props.checkbox ? (
                <input
                  type="checkbox"
                  onClick={props.select_all}
                  style={{ marginRight: "5px" }}
                />
              ) : null}
              {props.table_headings && props.table_headings?.length > 0
                ? props.table_headings[0]
                : ""}
            </th>
          )}

          {props.table_headings?.slice(1).map((p, i) => (
            <th key={i}>{p}</th>
          ))}
        </tr>
        {props.children}
      </table>
      {props.pagination_display && (
        <>
          <div
            className={["flex", "pagination"].join(" ")}
            style={{
              padding: "1rem 2rem",
              boxSizing: "border-box",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p style={{ fontSize: "1rem" }}>{props.item_range}</p>
            <Pagination
              count={props.page_count}
              variant="outlined"
              shape="rounded"
              showFirstButton
              showLastButton
              size="large"
              page={props.current_page}
              style={{ fontWeight: "600" }}
              onChange={props.page_change}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Table;
