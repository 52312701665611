import Table from "../../../component/table_container/main_table";
import TableHeader from "../../../component/table_container/table_header";
import TableSubHeader from "../../../component/table_container/table_sub_header";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { FiEdit2, FiUsers } from "react-icons/fi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Http from "../../../utils/httpClients";
import Modal from "../../../component/modal";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  deletepublicuser,
  search_items,
  set_current_page,
  setpublicuser,
  show_data_public_users,
} from "../../../redux/store/public_users";
import { set_tab_id } from "../../../redux/store/tabs";
const PublicUser = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState<boolean>(false);
  const [delete_id, setDeleteId] = useState<number>();
  const data = useAppSelector((state) => state.public_user.filtered_data);
  const items = useAppSelector((state) => state.public_user.data);
  const filtered_data = useAppSelector(
    (state) => state.public_user.filtered_data
  );
  const items_per_page = useAppSelector(
    (state) => state.public_user.items_per_page
  );
  const current_page = useAppSelector(
    (state) => state.public_user.current_page
  );
  useEffect(() => {
    Http.GET("/familyprofiles", true)
      .then((res) => {
        console.log(res.data);
        dispatch(setpublicuser(res.data.data));
        dispatch(show_data_public_users());
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch]);
  const public_user_view = (id: number) => {
    navigate(`view/${id}/`);
  };
  const public_user_edit = (id: number) => {
    dispatch(set_tab_id(0));
    navigate(`update/${id}/`);
  };
  const deleteHandler = (id: number) => {
    setDeleteId(id);
    setModal(true);
  };
  const deleteUser = () => {
    Http.DELETE("/familyprofiles/" + delete_id, true)
      .then((res) => {
        setModal(false);
        toast.success("Family profile has been successfully removed!", {
          position: "bottom-right",
        });
        dispatch(deletepublicuser(delete_id));
      })
      .catch((err) => {
        toast.success("Error occured while removing family profile!", {
          position: "bottom-right",
        });
      });
  };
  const show_items = (e: { value: string }) => {
    dispatch(set_current_page(e.value));
    dispatch(show_data_public_users());
  };
  const page_change = (event: any, pageNumber: number) => {
    dispatch(set_current_page(pageNumber));
    dispatch(show_data_public_users());
    console.log(pageNumber);
  };
  const change_input = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(search_items(e.target.value));
    dispatch(show_data_public_users());
  };
  const add_public_user = () => {
    dispatch(set_tab_id(0));
    navigate("create");
  };
  return (
    <>
      <Modal
        open={modal}
        action={deleteUser}
        close_modal={() => setModal(false)}
        title="user"
      />
      <div className="table_wrapper">
        <div className="component_wrapper table_container">
          <TableHeader
            icon={<FiUsers />}
            primary_button_name="
          प्रयोगकर्ता थप्नुहोस्"
            table_title="सार्वजनिक प्रयोगकर्ता"
            on_add={add_public_user}
            items_length={items && items.length}
          />
          <TableSubHeader
            item_select={items.length > 10}
            no_of_items={show_items}
            placeholder="Search users...."
            input_handler={change_input}
          />
          <Table
            page_change={page_change}
            page_count={Math.ceil(filtered_data.length / items_per_page)}
            current_page={current_page}
            pagination_display={data.length >= 10}
            item_range={
              data.length * (current_page - 1) +
              "-" +
              current_page * data.length +
              " items"
            }
            table_headings={[
              "s.n.",
              "tax payer number",
              "tole",
              "ward no.",
              "Mobile number",
              "actions",
            ]}
          >
            {data ? (
              data.map((p: any, i: number) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{p.kardata_number}</td>
                  <td>{p.permanent_tole}</td>
                  <td>{p.permanent_ward}</td>
                  <td>{p.mobile_no}</td>
                  <td className="align_items table_icons">
                    <AiOutlineEye
                      data-tooltip-id="view"
                      data-tooltip-content="View"
                      onClick={() => public_user_view(p.id)}
                    />
                    <AiOutlineDelete
                      data-tooltip-id="view"
                      data-tooltip-content="Delete"
                      onClick={() => deleteHandler(p.id)}
                    />
                    <FiEdit2
                      data-tooltip-id="view"
                      data-tooltip-content="Update"
                      onClick={() => public_user_edit(p.id)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <td colSpan={5} className="no_match_text">
                No search found for public user
              </td>
            )}
          </Table>
        </div>
      </div>
    </>
  );
};
export default PublicUser;
