import { MdOutlineAdd } from "react-icons/md";
import { FiUploadCloud } from "react-icons/fi";
import { MdOutlineFileDownload } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
interface TableHeaderProps {
  primary_button_name?: String;
  table_title: String;
  url?: string;
  items_length?: number;
  sub_title?: string;
  on_add?: () => void;
  no_btn?: boolean;
  icon?:JSX.Element;
}
const TableHeader = (props: TableHeaderProps) => {
  return (
    <div className="align_items table_header space_between">
      <div className="align_items_col">
        <div className="align_items">
          <p className="large_text super_bold align_items">
            {props.icon} {props.table_title}
          </p>
          {props.items_length ? (
            <p className="small_text blue_border_box">{props.items_length}</p>
          ) : null}
        </div>
        {props.sub_title ? (
          <p className="medium_size_shaded_text">
            {/* आफ्नो {props.table_title} जानकारी व्यवस्थापन */}
            {props.sub_title}
          </p>
        ) : null}
      </div>

      <div className="align_items">
        {!props.no_btn && (
          <button
            className="align_items bold button_font_size primary_button"
            onClick={props.on_add}
          >
            <MdOutlineAdd />
            {props.primary_button_name}
          </button>
        )}
        {/* <button className="bold secondary_button button_font_size align_items">
          <FiUploadCloud />
          Import
        </button>
        <button className="bold secondary_button button_font_size align_items">
          <MdOutlineFileDownload />
          Export
        </button>
        <button className="bold secondary_button button_font_size align_items">
          <MdOutlineFileDownload />
          Download PDF Report
        </button> */}
      </div>
    </div>
  );
};

export default TableHeader;
