import ManageLayout from "../../../component/manage_layout";
import InputField from "../../../component/input_container";
import SelectInput from "../../../component/input_container/select_input";
import Http from "../../../utils/httpClients";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/hook";
import {
  setparentchildern,
  setSpecificpermission,
} from "../../../redux/store/permissions";
import { CenterElement } from "../../../component/manage_layout/center_element";
import { AiOutlineUserAdd } from "react-icons/ai";
interface Props {
  form_handler?: React.FormEventHandler;
  data?: any;
  input_change_handler?: React.ChangeEventHandler<HTMLInputElement>;
  title?: string;
  update?: boolean;
}
const PermissionContainer = (props: Props) => {
  const dispatch = useDispatch();
  const loading = useAppSelector((state: any) => state.permissions?.loading);
  const data = useAppSelector(
    (state: any) => state.permissions.parent_children
  );
  const specific_data = useAppSelector(
    (state: any) => state.permissions.specific_permission
  );
  console.log(data);
  useEffect(() => {
    if (data.length === 0) {
      Http.GET("/permissions/parent/children", true)
        .then((res) => {
          dispatch(
            setparentchildern([
              { permission_name: "Parent", id: 0 },
              ...res.data.data[0].childrens,
            ])
          );
        })
        .catch((err) => console.log(err));
    }
  }, [dispatch]);
  const input_change = (e: any) => {
    dispatch(
      setSpecificpermission({ name: e.target.name, value: e.target.value })
    );
  };
  const select_change = (name: string, value: any) => {
    dispatch(setSpecificpermission({ name, value }));
  };
  console.log(
    data
      .map((p: any) => {
        return {
          label: p.permission_name,
          value: p.id,
        };
      })
      .filter((p: any) => p.value === specific_data?.parent_id)
  );
  console.log(specific_data?.parent_id);
  return (
    <CenterElement>
      <ManageLayout
        form_handler={props.form_handler}
        page_title={"अनुमति " + props.title}
        loading={loading}
        form_width="60%"
        icon={<AiOutlineUserAdd />}
        update={props.update}
      >
        <div
          className="flex center space_between"
          style={{ gap: "1rem", width: "100%", flexDirection: "column" }}
        >
          <InputField
            placeholder="Enter permission name"
            type="text"
            label="Permission Name"
            value={specific_data?.permission_name}
            on_change={input_change}
            name="permission_name"
            required
          />
          <InputField
            placeholder="Enter guard name"
            type="text"
            label="Guard Name"
            value={specific_data?.guard_name}
            on_change={input_change}
            name="guard_name"
            required
          />
          {data.length > 0 ? (
            <SelectInput
              options={data.map((p: any) => {
                return {
                  label: p.permission_name,
                  value: p.id,
                };
              })}
              value={data
                .map((p: any) => {
                  return {
                    label: p.permission_name,
                    value: p.id,
                  };
                })
                .filter(
                  (p: any) =>
                    p.value.toString() === specific_data?.parent_id.toString()
                )}
              label="Parent Permission"
              name="parent_id"
              on_change={(e: any) => select_change("parent_id", e.value)}
              required
            />
          ) : null}
        </div>
      </ManageLayout>
    </CenterElement>
  );
};
export default PermissionContainer;
