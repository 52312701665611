import { Routes, Route } from "react-router";
import Role from "../";
import AddRoles from "../manage_roles/add_roles/index";
import UpdateRoles from "../manage_roles/update_roles/index";
import ViewRole from "../manage_roles/view_role/index";
export const RolesRoutes = () => (
  <Routes>
    <Route path="/user_configuration/role" element={<Role />} />
    <Route path="/user_configuration/role/add" element={<AddRoles />} />
    <Route
      path="/user_configuration/role/update/:id"
      element={<UpdateRoles />}
    />
    <Route path="/user_configuration/role/view/:id" element={<ViewRole />} />
  </Routes>
);
