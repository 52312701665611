import Content from "./content";
import Header from "../lang_nepali/header";
import Footer from "../lang_nepali/footer";
import Subject from "../lang_nepali/subject";
import SifarisDocLayout from "..";
const Template_Nep_2 = () => {
  let subject = "खुलाई पठाएको";
  return (
    <SifarisDocLayout>
      <div className="template_content_gap" style={{ gap: "2rem" }}>
        <Header>
          <Subject subject={subject} />
          <div>
            <p>श्री..................कार्यालय,</p>
            <p>.................................... |</p>
          </div>
        </Header>
        <Content />
      </div>
      <Footer />
    </SifarisDocLayout>
  );
};
export default Template_Nep_2;
