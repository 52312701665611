import styles from "./index.module.scss";
interface CardProps {
  children: JSX.Element | JSX.Element[];
  bg_color?: string;
  className?: string;
}
const CardLayout = (props: CardProps) => {
  let class_name = [styles.layout , props.className].join(" ");
  return (
    <div className={class_name} style={{ backgroundColor: props.bg_color }}>
      {props.children}
    </div>
  );
};
export default CardLayout;
