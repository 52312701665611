import Http from "../../../../utils/httpClients";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Table from "../../../../component/table_container/main_table";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import {
  getFamilyData,
  getSpecificpublicuser,
} from "../../../../redux/store/public_users";
import { toast } from "react-toastify";
import { AiOutlineEye } from "react-icons/ai";
import FamilyInfoModal from "../family_info/view_info";
import { UpdateButton } from "../../../../component/buttons/update_button";
import ReturnButton from "../../../../component/buttons/return_button";
import style from "./index.module.scss";
const Container = (props: {
  children?: JSX.Element[] | JSX.Element;
  title: string;
}) => {
  return (
    <fieldset
      style={{
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <legend
        className="bold"
        style={{
          color: "#1f60ba",
          fontSize: "1.2rem",
          padding: "0 8px",
        }}
      >
        {props.title}
      </legend>
      {props.children}
    </fieldset>
  );
};
const Childs = (props: { label: string; value: string }) => {
  return (
    <div className="input_container">
      <p className="input_label">{props.label}</p>
      <p className="input_p">{props.value}</p>
    </div>
  );
};
const Preview = () => {
  const { id } = useParams();
  const [family_modal, setFamilyModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const user_details = useAppSelector(
    (state: any) => state.public_user.specific_public_user
  );
  useEffect(() => {
    Http.GET("/familyprofiles/" + id, true)
      .then((res) => {
        let data = res.data.data;
        dispatch(
          getSpecificpublicuser({
            ...data,
          })
        );
      })
      .catch((err) => {
        toast.error("Error occured while fetching family details!");
      });
  }, [id, dispatch]);
  const view_handler = (id: number) => {
    setFamilyModal(true);
    dispatch(
      getFamilyData(
        user_details.familymembers.filter((p: any) => p.id === id)[0]
      )
    );
  };
  return (
    <>
      <FamilyInfoModal
        close={() => setFamilyModal(false)}
        show={family_modal}
      />
      <div
        className="flex"
        style={{ flexDirection: "column", padding: "1.5rem" }}
      >
        {user_details ? (
          <>
            <div
              className="flex"
              style={{
                flexDirection: "column",
                gap: "2rem",
                padding: "0 2rem",
              }}
            >
              <div
                className="flex align_items"
                style={{ justifyContent: "space-between" }}
              >
                <ReturnButton />

                <UpdateButton
                  url={"/user_configuration/publicuser/update/" + id}
                />
              </div>

              <Container title="Family Basic Information">
                <div className="input_wrapper">
                  <Childs value={user_details?.email_address} label="Email" />
                  <Childs
                    value={user_details?.mobile_no}
                    label="Mobile Number"
                  />
                </div>
                <div className="input_wrapper">
                  <Childs
                    value={user_details?.kardata_number}
                    label="Tax Payer No."
                  />
                  <Childs
                    value={user_details?.landline_no}
                    label="Landline Number"
                  />
                </div>
              </Container>
              <Container title="Family Permanent Address">
                <div className="input_wrapper">
                  <Childs
                    value={user_details?.permanentprovince?.province_en}
                    label="Province"
                  />
                  <Childs
                    value={user_details?.permanentdistrict?.district_en}
                    label="District"
                  />
                </div>
                <div className="input_wrapper">
                  <Childs
                    value={user_details?.permanentlocallevel?.lgname_en}
                    label="Local Level"
                  />
                  <Childs value={user_details?.permanent_tole} label="Tole" />
                </div>
                <div className="input_wrapper" style={{ width: "49%" }}>
                  <Childs
                    value={user_details?.permanent_ward}
                    label="Ward No."
                  />
                </div>
              </Container>
              <Container title=" Family Temporary Address">
                <div className="input_wrapper">
                  <Childs
                    value={user_details?.temporaryprovince?.province_en}
                    label="Province"
                  />
                  <Childs
                    value={user_details?.temporarydistrict?.district_en}
                    label="District"
                  />
                </div>
                <div className="input_wrapper">
                  <Childs
                    value={user_details?.temporarylocallevel?.lgname_en}
                    label="Local Level"
                  />
                  <Childs value={user_details?.temporary_tole} label="Tole" />
                </div>
                <div className="input_wrapper" style={{ width: "49%" }}>
                  <Childs
                    value={user_details?.temporary_ward}
                    label="Ward No."
                  />
                </div>
              </Container>
              <Container title="Family Members">
                <div
                  className={[style.table_container, style.table_head].join(
                    " "
                  )}
                >
                  <Table
                    table_headings={[
                      "S.N.",
                      "Full Name(EN)",
                      "Full Name(NP)",
                      "Mobile",
                      "View",
                    ]}
                  >
                    {user_details.familymembers &&
                    user_details.familymembers?.length > 0 ? (
                      user_details?.familymembers?.map((p: any, i: number) => (
                        <tr>
                          <td>{p.id}</td>
                          <td>{p.fullname_en}</td>
                          <td>{p.fullname_ne}</td>
                          <td>{p.mobile}</td>
                          <td className="align_items table_icons">
                            <AiOutlineEye onClick={() => view_handler(p.id)} />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <td colSpan={5} className="no_match_text">
                        No family relation has been added yet.
                      </td>
                    )}
                  </Table>
                </div>
              </Container>

              <Container title="Family Documents">
                <div
                  className={[style.table_container, style.table_head].join(
                    " "
                  )}
                >
                  <Table table_headings={["S.N.", "Document Name", "Action"]}>
                    {user_details && user_details.familydocuments ? (
                      user_details?.familydocuments?.map(
                        (p: any, i: number) => (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{p.document?.doc_name}</td>
                            <td className="align_items table_icons">
                              <a
                                rel="noreferrer"
                                href={
                                  process.env.REACT_APP_BASE_URL?.replace(
                                    "api",
                                    "storage"
                                  ) +
                                  "/" +
                                  p?.upload_path
                                }
                                style={{ height: "21px" }}
                                target="_blank"
                              >
                                <AiOutlineEye />
                              </a>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <td colSpan={3} className="no_match_text">
                        No user documents has been added yet.
                      </td>
                    )}
                  </Table>
                </div>
              </Container>
              <Container title="Family Member Documents">
                <div
                  className={[style.table_container, style.table_head].join(
                    " "
                  )}
                >
                  <Table
                    table_headings={[
                      "S.N.",
                      "Full Name",
                      "Document Name",
                      "Actions",
                    ]}
                  >
                    {user_details?.familymemberdocuments &&
                    user_details?.familymemberdocuments.length > 0 ? (
                      user_details?.familymemberdocuments.map(
                        (p: any, i: number) => (
                          <tr>
                            <td
                              className="checkbox_label"
                              style={{ width: "100%" }}
                            >
                              {i + 1}
                            </td>
                            <td>{p?.familymember?.fullname_en}</td>
                            <td>{p.document?.doc_name}</td>
                            <td className="align_items table_icons">
                              <a
                                rel="noreferrer"
                                href={
                                  process.env.REACT_APP_BASE_URL?.replace(
                                    "api",
                                    "storage"
                                  ) +
                                  "/" +
                                  p?.upload_path
                                }
                                style={{ height: "21px" }}
                                target="_blank"
                              >
                                <AiOutlineEye />
                              </a>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <td colSpan={4} className="no_match_text">
                        No family member document has been uploaded yet.
                      </td>
                    )}
                  </Table>
                </div>
              </Container>
              <Container title="Family Relationship">
                <div
                  className={[style.table_container, style.table_head].join(
                    " "
                  )}
                >
                  <Table table_headings={["S.N.", "From", "Relation", "To"]}>
                    {user_details &&
                    user_details.familyrelationship &&
                    user_details.familyrelationship.length > 0 ? (
                      user_details.familyrelationship.map(
                        (p: any, i: number) => (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{p.fromfamilymember?.fullname_en}</td>
                            <td>{p.relation?.relation_en}</td>
                            <td>{p.tofamilymember?.fullname_en}</td>
                          </tr>
                        )
                      )
                    ) : (
                      <td colSpan={5} className="no_match_text">
                        No family relation has been set yet.
                      </td>
                    )}
                  </Table>
                </div>
              </Container>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};
export default Preview;
