import ManageLayout from "../../../../component/manage_layout";
import InputField from "../../../../component/input_container";
import SelectInput from "../../../../component/input_container/select_input";
import { useEffect } from "react";
import Http from "../../../../utils/httpClients";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { setofficetype } from "../../../../redux/store/office_type";
import { toast } from "react-toastify";
import { setllevel } from "../../../../redux/store/local_level";
import { setProvince } from "../../../../redux/store/provinces/province";
import { setDistrict } from "../../../../redux/store/districts";
import { setSpecificoffice } from "../../../../redux/store/office";
import { HiBuildingOffice } from "react-icons/hi2";
import { CenterElement } from "../../../../component/manage_layout/center_element";
const active_options = [
  {
    label: "InActive",
    value: 0,
  },
  { label: "Active", value: 1 },
];
const OfficeContainer = (props: {
  title?: string;
  form_data: any;
  page_title?: string;
  update?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const office_types = useAppSelector((state: any) => state.office_types.data);
  const provinces = useAppSelector((state: any) => state.province.data);
  const districts = useAppSelector((state: any) => state.district.data);
  const locallevel = useAppSelector((state: any) => state.llevel.data);
  const data = useAppSelector((state: any) => state.offices.specific_office);
  const loading = useAppSelector((state: any) => state.offices.loading);
  useEffect(() => {
    if (office_types.length === 0) {
      Http.GET("/officetypes", true)
        .then(async (res: any) => {
          dispatch(setofficetype(res.data.data));
        })
        .catch((err: any) => {
          toast.error("Error occured while fetching office types!");
        });
    }
    if (locallevel.length === 0) {
      Http.GET("/llevels", true)
        .then((res) => {
          dispatch(setllevel(res.data.data));
        })
        .catch((err) => {
          toast.error("Error occured while fetching locallevel data!");
        });
    }
    if (provinces.length === 0) {
      Http.GET("/provinces", true)
        .then((res) => {
          dispatch(setProvince(res.data.data));
        })
        .catch((err) => {
          toast.error("Error occured while fetching provinces data!");
        });
    }
    if (districts.length === 0) {
      Http.GET("/districts", true)
        .then((res) => {
          dispatch(setDistrict(res.data.data));
        })
        .catch((err) => {
          toast.error("Error occured while fetching districts data");
        });
    }
  }, [dispatch]);
  const input_change_handler = (e: any) => {
    dispatch(setSpecificoffice({ name: e.target.name, value: e.target.value }));
  };
  const select_change_handler = (name: string, value: string) => {
    dispatch(setSpecificoffice({ name, value }));
  };
  return (
    <>
      <CenterElement>
        {office_types && provinces && districts && locallevel && (
          <ManageLayout
            form_handler={props.form_data}
            page_title={"कार्यालय " + props.page_title}
            loading={loading}
            icon={<HiBuildingOffice />}
            update={props.update}
          >
            <div
              className="flex center space_between"
              style={{ gap: "2rem", width: "100%" }}
            >
              <SelectInput
                options={provinces.map((p: any) => {
                  return {
                    label: p.province_en,
                    value: p.id,
                  };
                })}
                label="Province"
                name="province_id"
                placeholder="Select Province Type"
                required
                value={provinces
                  .map((p: any) => {
                    return {
                      label: p.province_en,
                      value: p.id,
                    };
                  })
                  ?.filter((p: any) => p.value === data.province_id)}
                on_change={(e: any) =>
                  select_change_handler("province_id", e.value)
                }
              />
              <SelectInput
                options={districts.map((p: any) => {
                  return {
                    label: p.district_en,
                    value: p.id,
                  };
                })}
                label="District"
                name="district_id"
                placeholder="Select District Type"
                required
                value={districts
                  .map((p: any) => {
                    return {
                      label: p.district_en,
                      value: p.id,
                    };
                  })
                  ?.filter((p: any) => p.value === data.district_id)}
                on_change={(e: any) =>
                  select_change_handler("district_id", e.value)
                }
              />
            </div>
            <div
              className="flex center space_between"
              style={{ gap: "2rem", width: "100%" }}
            >
              <SelectInput
                options={office_types.map((p: any) => {
                  return {
                    label: p.office_type,
                    value: p.id,
                  };
                })}
                label="Office Type"
                name="office_type_id"
                placeholder="Select Office Type"
                on_change={(e: any) =>
                  select_change_handler("office_type_id", e.value)
                }
                value={office_types
                  .map((p: any) => {
                    return {
                      label: p.office_type,
                      value: p.id,
                    };
                  })
                  ?.filter((p: any) => p.value === data.office_type_id)}
                required
              />
              <SelectInput
                options={locallevel.map((p: any) => {
                  return {
                    label: p.lgname_en,
                    value: p.id,
                  };
                })}
                label="Local level Type"
                name="lg_id"
                placeholder="Select Local Level"
                value={locallevel
                  .map((p: any) => {
                    return {
                      label: p.lgname_en,
                      value: p.id,
                    };
                  })
                  ?.filter((p: any) => p.value === data.lg_id)}
                on_change={(e: any) => select_change_handler("lg_id", e.value)}
                required
              />
            </div>
            <div
              className="flex center space_between"
              style={{ gap: "2rem", width: "100%" }}
            >
              <InputField
                placeholder="Enter office/dept name"
                type="text"
                label="Office/Department Name"
                name="office_name"
                value={data?.office_name}
                on_change={input_change_handler}
                required
              />
              <InputField
                placeholder="Enter office address"
                type="text"
                label="Office Address"
                name="office_address"
                value={data?.office_address}
                on_change={input_change_handler}
                required
              />
            </div>
            <div
              className="flex center space_between"
              style={{ gap: "2rem", width: "100%" }}
            >
              <InputField
                placeholder="Enter office phone"
                type="number"
                label="Office Phone"
                name="office_phone"
                value={data?.office_phone}
                on_change={input_change_handler}
                required
              />
              <InputField
                placeholder="Enter office email"
                type="text"
                label="Office Email"
                name="office_email"
                value={data?.office_email}
                on_change={input_change_handler}
                required
              />
            </div>
            <div
              className="flex center space_between"
              style={{ gap: "2rem", width: "100%" }}
            >
              <InputField
                placeholder="Enter latitude"
                type="text"
                label="Latitude"
                name="latitude"
                value={data?.latitude}
                on_change={input_change_handler}
                required
              />
              <InputField
                placeholder="Enter longitude"
                type="text"
                label="Longitude"
                name="longitude"
                value={data?.longitude}
                on_change={input_change_handler}
                required
              />
            </div>
            <div style={{ width: "48%", alignSelf: "flex-start" }}>
              <SelectInput
                placeholder="Select Status"
                label="Active Status"
                options={active_options}
                required
                name="is_active"
                value={active_options.filter(
                  (p: any) => p.value.toString() === data?.is_active.toString()
                )}
                on_change={(e: any) =>
                  select_change_handler("is_active", e.value)
                }
              />
            </div>
          </ManageLayout>
        )}
      </CenterElement>
    </>
  );
};
export default OfficeContainer;
