import style from "./index.module.scss";
import InputField from "../input_field";
import { MdOutlineDelete } from "react-icons/md";
import { FiEdit2 } from "react-icons/fi";
import { useState } from "react";
import { HiOutlineDuplicate } from "react-icons/hi";
interface InputFieldProps {
  input_data?: any;
  on_delete?: VoidFunction;
  on_edit?: VoidFunction;
  display?: boolean;
  children?: JSX.Element | JSX.Element[];
  test?: boolean;
  on_change?: any;
  duplicate_inputfield?: VoidFunction;
  handleDragStart?: any;
  handleDragEnter?: any;
  handleDragEnd?: any;
}
const PreviewInputField = (props: InputFieldProps) => {
  const [hoverState, setHoverState] = useState<boolean>(false);
  return (
    <div
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
      draggable="true"
      onDragStart={props.handleDragStart}
      onDragEnter={props.handleDragEnter}
      onDragEnd={props.handleDragEnd}
      className={props.input_data.outer_class}
      style={{
        marginTop: props.test ? "0rem" : "1rem",
        position: "relative",
        cursor: "grab",
        width: props.test ? "100%" : "default",
      }}
    >
      {props.display ? (
        <div
          style={{
            visibility: hoverState ? "visible" : "hidden",
          }}
          className={[
            "align_items",
            "table_icons",
            style.input_field_widget,
          ].join(" ")}
        >
          <HiOutlineDuplicate
            onClick={props.duplicate_inputfield}
            data-tooltip-id="view"
            data-tooltip-content="Duplicate"
          />
          <FiEdit2
            onClick={props.on_edit}
            data-tooltip-id="view"
            data-tooltip-content="Edit"
          />
          <MdOutlineDelete
            data-tooltip-id="view"
            data-tooltip-content="Remove"
            onClick={props.on_delete}
          />
        </div>
      ) : null}
      <InputField
        type={props.input_data?.input_type}
        name={props.input_data.name}
        placeholder={props.input_data.placeholder}
        value={props.input_data.value}
        id={props.input_data.id}
        label={props.input_data.label}
        outer_class={props.input_data.outer_class}
        input_class={props.input_data.input_class}
        label_class={props.input_data.label_class}
        on_change={props.on_change}
      />
      {props.children}
    </div>
  );
};
export default PreviewInputField;
