import { useSyncExternalStore, useDebugValue } from "react";
function subscribe(callback: any) {
  window.addEventListener("online", callback);
  window.addEventListener("offline", callback);
  return () => {
    window.removeEventListener("online", callback);
    window.removeEventListener("offline", callback);
  };
}
export function useOnlineStatus() {
  const isOnline = useSyncExternalStore(
    subscribe,
    () => navigator.onLine,
    () => true
  );
  useDebugValue(isOnline ? "Online" : "Offline");
  return isOnline;
}
export const updateObject = (oldObject: object, updatedProperties: object) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};
export const fileExtensionCheck = (file: any) => {
  let ext = ["jpeg", "png", "jpg"];
  for (let i = 0; i < ext.length; i++) {
    if (
      file.type.split("/")[1]?.toString()?.toLowerCase() ===
      ext[i].toLowerCase()
    ) {
      return file;
    }
  }
};

export const fileSizeHandler = (fileSize: number) => {
  let a = fileSize / 1024;
  console.log(a);
  if (a < 0) {
    return fileSize + "Bytes";
  }
  if (a < 1024) {
    return Math.round(a) + "KB";
  }
  return (a / 1024).toFixed(1) + "MB";
};
