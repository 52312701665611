import { createSlice } from "@reduxjs/toolkit";
const layout = {
  office_type_id: 0,
  province_id: 0,
  district_id: 0,
  lg_id: 0,
  office_name: "",
  office_address: "",
  office_phone: "",
  office_email: "",
  latitude: "",
  longitude: "",
  is_active: 0,
};
interface OfficeState {
  data: Array<Object>;
  loading:Boolean;
  specific_office: {
    office_type_id: number;
    province_id: number;
    district_id: number;
    lg_id: number;
    office_name: string;
    office_address: string;
    office_phone: string;
    office_email: string;
    latitude: string;
    longitude: string;
    is_active: number;
  };
}

const initialState: OfficeState = {
  loading:false,
  data: [],
  specific_office: {
    ...layout,
  },
};

export const officeSlice = createSlice({
  name: "officeSlice",
  initialState,
  reducers: {
    set_loading_office: (state, action) => {
      state.loading = action.payload;
    },
    setoffice: (state, action) => {
      state.data = action.payload;
    },
    addoffice: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    getSpecificoffice: (state, action) => {
      state.specific_office = {
        ...action.payload,
      };
    },
    setSpecificoffice: (state, action) => {
      state.specific_office = {
        ...state.specific_office,
        [action.payload.name]: action.payload.value,
      };
    },
    resetOfficeForm: (state) => {
      state.specific_office = { ...layout };
    },
    deleteoffice: (state, action) => {
      state.data = [
        ...state.data.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
    },
  },
});

export const {
  setoffice,
  addoffice,
  deleteoffice,
  getSpecificoffice,
  setSpecificoffice,
  resetOfficeForm,
  set_loading_office
} = officeSlice.actions;

export default officeSlice.reducer;
