import CustomModal from "../../../../component/customModal";
import SelectInput from "../../../dynamic_form/input_field/select_input";
import { useState, useEffect } from "react";
import Buttons from "../../../../component/customModal/buttons";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import {
  manageFamilyRelation,
  setFamilyMemberId,
} from "../../../../redux/store/public_users";
import Http from "../../../../utils/httpClients";
import Table from "../../../../component/table_container/main_table";
import { toast } from "react-toastify";
const Modal = (props: {
  show_modal: boolean;
  on_close: () => void;
  on_submit: any;
  title?: string;
}) => {
  const dispatch = useAppDispatch();
  const [filter_data, setFilterData] = useState<Array<Object>>();
  const family_profiles = useAppSelector(
    (state: any) => state.public_user.specific_public_user
  );
  const family_member_id = useAppSelector(
    (state: any) => state.public_user.family_member_id
  );
  const [relations, setRelations] = useState<any>();
  useEffect(() => {
    Http.GET("/relations", true).then((res) => {
      setRelations(
        res.data.data.map((p: any) => {
          return { label: p.relation_en, value: p.id };
        })
      );
    });
  }, []);
  const select_change = async (value: any) => {
    dispatch(setFamilyMemberId(value));
    await Http.GET("/relations/familymembers/" + value, true)
      .then((res) => {
        setFilterData(res.data.data.map((p: any) => p.tofamilymember.id));
      })
      .catch((err) => {
        toast.error(err.data.message);
      });
  };
  const manage_relation = (fam_id: number, value: any) => {
    dispatch(
      manageFamilyRelation({
        familymemberfrom_id: family_member_id,
        familymemberto_id: fam_id,
        relation_id: value,
      })
    );
  };
  return (
    <CustomModal
      title={props.title}
      show={props.show_modal}
      onClose={props.on_close}
    >
      <form onSubmit={props.on_submit}>
        <div
          style={{
            margin: "2rem 0",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <SelectInput
            options={
              family_profiles.familymembers &&
              family_profiles.familymembers.length > 0 &&
              family_profiles.familymembers.map((p: any) => {
                return { label: p.fullname_en, value: p.id };
              })
            }
            label="Family Members"
            label_class="bold"
            required
            name="familymemberform_id"
            value={
              family_profiles.familymembers &&
              family_profiles.familymembers.length > 0 &&
              family_profiles.familymembers
                .map((p: any) => {
                  return { label: p.fullname_en, value: p.id };
                })
                .filter((p: any) => p.value === family_member_id)
            }
            on_change={(e: any) => select_change(e.value)}
          />

          {family_member_id && (
            <>
              <p>
                <span style={{ color: "red" }}>Note:</span> Select family
                relation of members of select member.
              </p>
              <Table table_headings={["Family Members", "Relation"]}>
                {family_profiles.familymembers &&
                  family_profiles.familymembers.length > 1 &&
                  family_profiles.familymembers
                    .filter(
                      (p: any) =>
                        p.id.toString() !== family_member_id.toString() &&
                        filter_data &&
                        !filter_data.includes(p.id)
                    )
                    .map((p: any) => (
                      <tr>
                        <td>{p.fullname_en}</td>
                        <div style={{ padding: "10px", minWidth: "100%" }}>
                          <SelectInput
                            on_change={(e: any) =>
                              manage_relation(p.id, e.value)
                            }
                            options={relations}
                          />
                        </div>
                      </tr>
                    ))}
                {
                  <tr>
                    {filter_data &&
                      filter_data?.length + 1 ===
                        family_profiles.familymembers.length && (
                        <td colSpan={2} className="no_match_text">
                          परिवारका सबै सदस्यहरूसंग नाता स्थापित भैसकेको छ!
                        </td>
                      )}
                  </tr>
                }
              </Table>
            </>
          )}
        </div>
        <Buttons
          handleClose={props.on_close}
          action_btn_title="Submit Relations"
        />
      </form>
    </CustomModal>
  );
};
export default Modal;
