import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "./store/slice/loginSlice";
import provinceSlice from "./store/provinces/province";
import districtsSlice from "./store/districts";
import llevelSlice from "./store/local_level";
import userSlice from "./store/user";
import roleSlice from "./store/roles";
import officeSlice from "./store/office";
import permissionSlice from "./store/permissions";
import publicUserSlice from "./store/public_users";
import tabSlice from "./store/tabs";
import sifarisTypeSlice from "./store/sifaris_type";
import officeTypeSlice from "./store/office_type";
import designationSlice from "./store/designation";
import documentSlice from "./store/document";
import addSifarisSlice from "./store/add_sifaris";
import sifaris_progress from "./store/sifaris_progress";
import familyRelationSlice from "./store/family_relations";
import sidebarSlice from "./store/sidebar";
export const store = configureStore({
  reducer: {
    login: loginSlice,
    province: provinceSlice,
    district: districtsSlice,
    llevel: llevelSlice,
    user: userSlice,
    roles: roleSlice,
    offices: officeSlice,
    permissions: permissionSlice,
    public_user: publicUserSlice,
    tab: tabSlice,
    sifaris_types: sifarisTypeSlice,
    office_types: officeTypeSlice,
    designation: designationSlice,
    document: documentSlice,
    sifaris_progress: sifaris_progress,
    add_sifaris: addSifarisSlice,
    family_relation: familyRelationSlice,
    sidebar: sidebarSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
