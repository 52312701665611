import styles from "./index.module.scss";
import Cards from "./component/card";
import FineCollectionChart from "./component/fine_collection_chart";
import FineCollection from "./component/fine_collection";
import Suppliers from "./component/suppliers";
import Monitoring from "./component/monitoring";
import SuppliersGraph from "./component/suppliers_graph";
import AnugamanScore from "./component/anugaman_score";
const DashBoard = () => {
  return (
    <>
      <div className={styles.dashboard}>
        <Cards />
        <div className={styles.grid_container}>
          <FineCollectionChart />
          <FineCollection />
        </div>
        <Monitoring />
        <div className={styles.grid_container}>
          <SuppliersGraph />
          <Suppliers />
        </div>
        <AnugamanScore />
      </div>
    </>
  );
};
export default DashBoard;
