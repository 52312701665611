import { useNavigate, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  setSifarisTableHeader,
  setSifarisTableTitle,
} from "../../../redux/store/sifaris_type";
import TableTitleContainer from "./table_title";
const TableTitle = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const formHandle = async (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);
    dispatch(setSifarisTableTitle(data.get("table_title")));
    dispatch(setSifarisTableHeader(data.get("table_headings")));
    navigate("/system_configuration/sifaris_type/dynamic_table/update/" + id);
  };
  return <TableTitleContainer formHandle={formHandle} title="Update" />;
};
export default TableTitle;
