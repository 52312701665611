import Table from "../../../../component/table_container/main_table";
import TableHeader from "../../../../component/table_container/table_header";
import TableSubHeader from "../../../../component/table_container/table_sub_header";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { useState } from "react";
import Modal from "./modal";
import Http from "../../../../utils/httpClients";
import { toast } from "react-toastify";
import DeleteModal from "../../../../component/modal";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { check_tab } from "../../../../redux/store/tabs";
import {
  deleteUserDocument,
  getPublicUserFamilyDocument,
  getSpecificpublicuser,
  resetUserDocumentId,
  setModalStatus,
} from "../../../../redux/store/public_users";
const FamilyMemberDocuments = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();
  const modal_status = useAppSelector(
    (state: any) => state.public_user.modal_status
  );
  const public_info = useAppSelector(
    (state: any) => state.public_user.specific_public_user
  );
  const [modal, setModal] = useState<boolean>(false);
  const [document, setDocument] = useState<any>();
  const submitDocument = (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);
    if (document !== undefined && document !== null) {
      if (modal_status === "create") {
        Http.POST(
          "/familymemberdocuments",
          {
            familyprofile_id: id,
            familymember_id: data.get("familymember_id"),
            document_id: data.get("document_id"),
            attachment: data.get("attachment"),
          },
          true,
          true
        )
          .then(async (res: any) => {
            await Http.GET("/familyprofiles/" + id, true).then((res) => {
              let data = res.data.data;
              dispatch(
                getSpecificpublicuser({
                  ...data,
                })
              );
            });
            toast.success("Family Member Document has been uploaded!", {
              position: "bottom-right",
            });
            setModal(false);
            dispatch(check_tab(3));
            setDocument(null);
          })
          .catch((err: any) => {
            setModal(false);
            toast.error("Error while uploading document!");
          });
      } else if (modal_status === "update") {
        Http.PUT(
          "/familymemberdocuments",
          {
            public_user_family_id: id,
            familymember_id: data.get("familymember_id"),
            document_id: data.get("document_id"),
            attachment: data.get("attachment"),
          },
          true,
          true
        )
          .then((res: any) => {
            Http.GET("/familyprofiles/" + id, true).then((res) => {
              let data = res.data.data;
              dispatch(
                getSpecificpublicuser({
                  ...data,
                })
              );
            });
            toast.success("Family Member Document has been updated!", {
              position: "bottom-right",
            });
            setModal(false);
            setDocument(null);
          })
          .catch((err: any) => {
            toast.error("Error while updating document!");
            setModal(false);
          });
      }
    } else {
      toast.error("Please upload your document!");
    }
  };
  const edit_handler = (data: { family_id: number; doc_id: number }) => {
    dispatch(getPublicUserFamilyDocument(data));
    dispatch(setModalStatus("update"));
    setModal(true);
  };
  const change_document_upload = (e: any) => {
    setDocument(e.target.files[0]);
  };
  const deleteDocument = () => {
    Http.DELETE(`/familymemberdocuments/${deleteId}`, true, true)
      .then((res) => {
        toast.success("Family user documents has been deleted!");
        setDeleteModal(false);
        dispatch(
          deleteUserDocument({
            name: "familymemberdocuments",
            value: public_info.familymemberdocuments.filter(
              (p: any) => p.id !== deleteId
            ),
          })
        );
      })
      .catch((res) => {
        toast.error("Error while deleting user documents");
      });
  };
  const addDocuments = () => {
    dispatch(setModalStatus("create"));
    dispatch(resetUserDocumentId());
    setModal(true);
  };
  const delete_handler = (id: number) => {
    setDeleteId(id);
    setDeleteModal(true);
  };
  const close_modal = () => {
    setModal(false);
    setDocument(null);
  };
  return (
    <div>
      <Modal
        on_submit={submitDocument}
        show_modal={modal}
        on_close={close_modal}
        title={modal_status === "create" ? "Upload " : "Update "}
        document={document}
        change_document_upload={change_document_upload}
      />
      <DeleteModal
        action={deleteDocument}
        open={deleteModal}
        title="document"
        close_modal={() => setDeleteModal(false)}
      />
      <div className="table_wrapper" style={{ backgroundColor: "#fff" }}>
        <div className="component_wrapper table_container">
          <TableHeader
            primary_button_name="
            Add Documents"
            table_title="Family Member Documents"
            sub_title="Manage family member
             documents JPG PDF"
            on_add={addDocuments}
          />
          <TableSubHeader placeholder="Search family member documents..." />
          <Table
            table_headings={["S.N.", "Full Name", "Document Name", "Actions"]}
          >
            {public_info.familymemberdocuments &&
            public_info.familymemberdocuments.length > 0 ? (
              public_info.familymemberdocuments.map((p: any, i: number) => (
                <tr>
                  <td className="checkbox_label" style={{ width: "100%" }}>
                    {i + 1}
                  </td>
                  <td>{p?.familymember?.fullname_en}</td>
                  <td>{p.document?.doc_name}</td>
                  <td className="align_items table_icons">
                    <a
                      rel="noreferrer"
                      href={
                        process.env.REACT_APP_BASE_URL?.replace('api','storage') + "/" +
                        p?.upload_path
                      }
                      style={{ height: "21px" }}
                      target="_blank"
                    >
                      <AiOutlineEye />
                    </a>
                    <FiEdit2
                      onClick={() =>
                        edit_handler({
                          family_id: p?.familymember?.id,
                          doc_id: p?.document?.id,
                        })
                      }
                    />
                    <AiOutlineDelete onClick={() => delete_handler(p.id)} />
                  </td>
                </tr>
              ))
            ) : (
              <td colSpan={4} className="no_match_text">
                No family member document has been uploaded yet.
              </td>
            )}
          </Table>
        </div>
      </div>
    </div>
  );
};
export default FamilyMemberDocuments;
