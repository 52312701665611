import ManageLayout from "../../../../component/manage_layout";
import { toast } from "react-toastify";
import InputField from "../../../../component/input_container";
import SelectInput from "../../../../component/input_container/select_input";
import { useEffect } from "react";
import Http from "../../../../utils/httpClients";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { setProvince } from "../../../../redux/store/provinces/province";
import { setDistrict } from "../../../../redux/store/districts";
import { setSpecificllevel } from "../../../../redux/store/local_level";
import { CenterElement } from "../../../../component/manage_layout/center_element";
import { useNavigate } from "react-router";
interface Props {
  form_handler: React.FormEventHandler;
  province_id?: string;
  district_id?: string;
  data?: any;
  input_change_handler?: React.ChangeEventHandler<HTMLInputElement>;
  title?: string;
  update?:boolean;
}
const LocalLevelContainer = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const provinces = useAppSelector((state) => state.province.data);
  const jillas = useAppSelector((state) => state.district.data);
  const data = useAppSelector((state) => state.llevel.specific_llevel);
  const loading = useAppSelector((state) => state.llevel.loading);
  useEffect(() => {
    if (provinces.length === 0) {
      Http.GET("/provinces", true)
        .then((res) => {
          dispatch(setProvince(res.data.data));
        })
        .catch((err) => {
          toast.error("Error while fetching provinces!");
        });
    }
    if (jillas.length === 0) {
      Http.GET("/districts", true)
        .then((res) => {
          dispatch(setDistrict(res.data.data));
        })
        .catch((err) => {
          toast.error("Error while fetching districts!");
        });
    }
  }, [dispatch]);
  const input_change = (e: any) => {
    dispatch(setSpecificllevel({ name: e.target.name, value: e.target.value }));
  };
  const select_change = (name: string, value: string) => {
    dispatch(setSpecificllevel({ name, value }));
  };
  return (
    <>
      {provinces && jillas ? (
        <CenterElement>
          <ManageLayout
            form_handler={props.form_handler}
            loading={loading}
            update={props.update}
            on_cancel={() => navigate(-1)}
            page_title={"स्थानीय तह " + props.title}
          >
            <div
              className="flex center space_between"
              style={{ gap: "2rem", width: "100%" }}
            >
              <SelectInput
                options={provinces?.map((p: any) => {
                  return { label: p.province_en, value: p.id };
                })}
                label="Province"
                name="province_id"
                placeholder="Select Province"
                required
                value={provinces
                  ?.map((p: any) => {
                    return { label: p.province_en, value: p.id };
                  })
                  ?.filter(
                    (p: any) =>
                      p?.value?.toString() === data?.province_id?.toString()
                  )}
                on_change={(e: any) => select_change("province_id", e.value)}
              />
              <SelectInput
                options={jillas?.map((p: any) => {
                  return { label: p.district_en, value: p.id };
                })}
                label="District"
                name="district_id"
                placeholder="Select District"
                required
                value={jillas
                  ?.map((p: any) => {
                    return { label: p.district_en, value: p.id };
                  })
                  ?.filter(
                    (p: any) =>
                      p?.value?.toString() === data?.district_id?.toString()
                  )}
                on_change={(e: any) => select_change("district_id", e.value)}
              />
            </div>
            <div
              className="flex center space_between"
              style={{ gap: "2rem", width: "100%" }}
            >
              <InputField
                placeholder="Enter Local Level in English"
                type="text"
                name="lgname_en"
                required
                label="Local Level Name (In English)"
                value={data?.lgname_en}
                on_change={input_change}
              />
              <InputField
                placeholder="Enter Local Level in Nepali"
                type="text"
                name="lgname_np"
                required
                value={data?.lgname_np}
                label="Local Level Name (In Nepali)"
                on_change={input_change}
              />
            </div>
          </ManageLayout>
        </CenterElement>
      ) : null}
    </>
  );
};
export default LocalLevelContainer;
