import styles from "./index.module.scss";
const Logo = () => (
  <div className={styles.logo_container}>
    <p className={styles.logo}>रु</p>
    <div>
      <p className={styles.bold_text}>Provincial fine collection</p>
      <p className={styles.small_light_text}>provincial fine collection</p>
    </div>
  </div>
);
const FineCollection = () => {
  return (
    <div className={["component_wrapper",styles.fine_container].join(" ")}>
      <Logo />
      <table>
        <tr>
          <th>Province Name</th>
          <th>Fines Collected</th>
          <th>Rate</th>
        </tr>
        <tr>
          <td>Province Name</td>
          <td>3034.00</td>
          <td>12.1%</td>
        </tr>
        <tr>
          <td>Province Name</td>
          <td>3034.00</td>
          <td>12.1%</td>
        </tr>{" "}
        <tr>
          <td>Province Name</td>
          <td>3034.00</td>
          <td>12.1%</td>
        </tr>{" "}
        <tr>
          <td>Province Name</td>
          <td>3034.00</td>
          <td>12.1%</td>
        </tr>
      </table>
    </div>
  );
};
export default FineCollection;
