import Table from "../../../../component/table_container/main_table";
import TableHeader from "../../../../component/table_container/table_header";
import TableSubHeader from "../../../../component/table_container/table_sub_header";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { useState } from "react";
import Form from "./form";
import Http from "../../../../utils/httpClients";
import Modal from "../../../../component/modal";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import {
  getFamilyData,
  getSpecificpublicuser,
  resetFamilyData,
  setFamilyEditId,
  setModalStatus,
} from "../../../../redux/store/public_users";
import FamilyInfoModal from "./view_info";
import { remove_check } from "../../../../redux/store/tabs";
const FamilyInfo = (props: { hide_buttons?: any }) => {
  const [formDisplay, setDisplay] = useState<boolean>(false);
  const [modal, setModal] = useState(false);
  const dispatch = useAppDispatch();
  const [delete_id, setDeleteId] = useState<number>();
  const [family_modal, setFamilyModal] = useState<boolean>(false);
  const public_info = useAppSelector(
    (state: any) => state.public_user.specific_public_user
  );
  const deleteHandler = (id: number) => {
    setDeleteId(id);
    setModal(true);
  };
  const edit_handler = (id: number) => {
    dispatch(setFamilyEditId(id));
    setDisplay(true);
    props.hide_buttons();
    dispatch(setModalStatus("update"));
    dispatch(
      getFamilyData(
        public_info?.familymembers.filter((p: any) => p.id === id)[0]
      )
    );
  };
  const view_handler = (id: number) => {
    setFamilyModal(true);
    dispatch(
      getFamilyData(
        public_info?.familymembers.filter((p: any) => p.id === id)[0]
      )
    );
  };
  const deleteFamilyInfo = () => {
    Http.DELETE("/familymembers/" + delete_id, true)
      .then((res) => {
        if (public_info?.familymembers?.length <= 1) {
          dispatch(remove_check(2));
        }
        let container = { ...public_info };
        let data = container?.familymembers?.filter(
          (p: any) => p.id.toString() !== delete_id?.toString()
        );
        container["familymembers"] = data;
        dispatch(getSpecificpublicuser(container));
        setModal(false);
        toast.success("Family member has been deleted!", {
          position: "bottom-right",
        });
      })
      .catch((err) => {
        toast.error("Error while deleting family member!", {
          position: "bottom-right",
        });
        setModal(false);
      });
  };
  const add_handler = () => {
    props.hide_buttons();
    dispatch(setModalStatus("create"));
    dispatch(resetFamilyData());
    setDisplay(true);
  };
  const close_handler = () => {
    setDisplay(false);
    dispatch(resetFamilyData());
    props.hide_buttons();
  };
  return (
    <>
      <Modal
        action={deleteFamilyInfo}
        open={modal}
        close_modal={() => setModal(false)}
        title="relation"
      />
      <FamilyInfoModal
        close={() => setFamilyModal(false)}
        show={family_modal}
      />
      {formDisplay && <Form close_form={close_handler} />}
      {!formDisplay && (
        <div className="table_wrapper" style={{ backgroundColor: "#fff" }}>
          <div className="component_wrapper table_container">
            <TableHeader
              primary_button_name="
          Add Member"
              table_title="Family Member"
              sub_title="Manage family members of user"
              on_add={add_handler}
            />
            <TableSubHeader placeholder="Search family members..." />
            <Table
              table_headings={[
                "S.N.",
                "Full Name(NP)",
                "Full Name(EN)",
                "Mobile",
                "actions",
              ]}
            >
              {public_info?.familymembers &&
              public_info?.familymembers?.length > 0 ? (
                public_info?.familymembers?.map((p: any, i: number) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{p.fullname_ne}</td>
                    <td>{p.fullname_en}</td>
                    <td>{p.mobile}</td>
                    <td className="align_items table_icons">
                      <AiOutlineEye onClick={() => view_handler(p.id)} />
                      <AiOutlineDelete onClick={() => deleteHandler(p.id)} />
                      <FiEdit2 onClick={() => edit_handler(p.id)} />
                    </td>
                  </tr>
                ))
              ) : (
                <td colSpan={5} className="no_match_text">
                  No family relation has been added yet.
                </td>
              )}
            </Table>
          </div>
        </div>
      )}
    </>
  );
};
export default FamilyInfo;
