import React, { useState, useEffect } from "react";
import styles from "./form.module.scss";

interface FormField {
  id: string;
  type: "text" | "select" | "number";
  name: string;
  label: string;
  class1?: string;
  class2?: string;
  class3?: string;
  placeholder?: string;
  options?: { value: string; label: string }[];
  route: string;
}

function Forms() {
  const [formFields, setFormFields] = useState<FormField[]>([
    {
      id: "1",
      type: "text",
      name: "firstName",
      label: "First Name",
      class1: "",
      class2: "",
      class3: "",
      route: "/api/submit-form",
    },
    {
      id: "2",
      type: "text",
      name: "lastName",
      label: "Last Name",
      class1: "",
      class2: "",
      class3: "",
      route: "/api/submit-form",
    },
    {
      id: "4",
      type: "number",
      name: "score",
      label: "Score",
      class1: "",
      class2: "",
      class3: "",
      route: "/api/submit-form",
    },
    {
      id: "3",
      type: "select",
      name: "gender",
      label: "Gender",
      class1: "",
      class2: "",
      class3: "",
      options: [
        {
          value: "male",
          label: "Male",
        },
        {
          value: "female",
          label: "Female",
        },
        {
          value: "other",
          label: "Other",
        },
      ],
      route: "/api/submit-form",
    },
  ]);

  useEffect(() => {
    fetch("/api/form-fields")
      .then((response) => response.json())
      .then((data) => setFormFields(data));
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    fetch("/api/form-submission", {
      method: "POST",
      body: formData,
    });
  };

  let fieldCount = 0;
  return (
    <div className={styles.formContainer}>
      <div className={styles.dforms}>
        <div>
          <h3 className={styles.formHeading}>Heading</h3>
        </div>
        <div className="p-2">
          <form onSubmit={handleSubmit}>
            {formFields.map((field, index) => {
              const id = `field-${fieldCount++}`;
              if (field.type === "text") {
                return (
                  <div
                    key={index}
                    className={field.class1}
                    style={{ marginBottom: "10px" }}
                  >
                    <label htmlFor={id} className={field.class2}>
                      {field.label} :
                    </label>
                    <input
                      type="text"
                      name={field.name}
                      id={id}
                      className="form-control col-6"
                    />
                  </div>
                );
              } else if (field.type === "select") {
                return (
                  <div
                    key={index}
                    className={field.class1}
                    style={{ marginBottom: "10px" }}
                  >
                    <label
                      htmlFor={id}
                      className={[field.class2, field.class1].join(" ")}
                    >
                      {field.label} :
                    </label>
                    <select name={field.name} id={id} className={field.class3}>
                      {field.options?.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                );
              } else if (field.type === "number") {
                return (
                  <div
                    key={index}
                    className={field.class1}
                    style={{ marginBottom: "10px" }}
                  >
                    <label htmlFor={id} className={field.class2}>
                      {field.label} :
                    </label>
                    <input
                      type="number"
                      name={field.name}
                      id={id}
                      multiple
                      className={field.class3}
                    />
                  </div>
                );
              }

              return null;
            })}
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Forms;
