import { MdKeyboardArrowRight } from "react-icons/md";
import style from "./index.module.scss";
import { useLocation, useNavigate } from "react-router";
const BottomNav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let paths = location.pathname.split("/").splice(1);
  const navigationHandler = (index: number) => {
    let p = paths.slice(0, index + 1);
    navigate("/" + p.join("/"));
  };
  return (
    <div className={style.bottom_nav}>
      <p onClick={() => navigate("/")}>Home</p>
      <MdKeyboardArrowRight />
      {paths.length > 1 ? (
        paths.map((p, i) => (
          <>
            <p
              onClick={() => navigationHandler(i)}
              className={i + 1 === paths.length ? style.active : ""}
            >
              {p.split("_").join(" ")}
            </p>
            {i + 1 !== paths.length ? <MdKeyboardArrowRight /> : null}{" "}
          </>
        ))
      ) : (
        <p className={style.active}>{paths[0].split("_").join(" ")}</p>
      )}
    </div>
  );
};
export default BottomNav;
