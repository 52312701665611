import Signature from "../../lang_nepali/signature";
import style from "../../content.module.scss";
import Table from "../../../component/table_container/main_table";
const Content = () => {
  let headings = [
    "क्र.स.",
    "वडा",
    "सिट नंकि नं",
    "क्षेत्रफल",
    "घर नं",
    "बाटोको नाम",
    "घर निर्माण भएको साल/नाम अनुमति लिएको",
  ];
  return (
    <>
        श्री ....................<br/>
    ................... |
      <p className="paragraph_indent">
        ...............महा/उपमहा/नगरपालिका/गाउँपालिका वडा नं..............)
        (साबिकको ठेगाना ..............................) अन्तर्गत
        श्री/सुश्री/श्रीमती ...................................... को नाममा त्यस
        कार्यालयमा श्रेस्ता दर्ता कायम रहेको निम्नमा उल्लेखित जग्गामा घर निर्माण
        गरि यस वडा कार्यालयमा निजले चालु आर्थिक वर्ष सम्मको घरजग्गा कर / एकीकृत
        सम्पत्ति कर चुक्ता गरिसकेको हुनाले निजको जग्गा ध प्रमाणपुर्जामा घर कायम
        गरिदिन हुन सिफारिस साथ अनुरोध गरिन्छ |
      </p>
      <h4 style={{textAlign:"center"}}>घर कायम गर्नु पर्ने जग्गाको विवरण</h4>
      <div className={style.sifaris_docs_table}>
        <Table table_headings={headings}></Table>
      </div>
      <Signature />
    </>
  );
};
export default Content;
