import { PayloadAction, createSlice } from "@reduxjs/toolkit";
let data = {
  mobile_no: "",
  landline_no: "",
  email_address: "",
  kardata_number: "",
  permanent_province_id: "",
  permanent_district_id: "",
  permanent_locallevel_id: "",
  permanent_ward: "",
  permanent_tole: "",
  temporary_province_id: "",
  temporary_district_id: "",
  temporary_locallevel_id: "",
  temporary_ward: "",
  temporary_tole: "",
};
const family_data = {
  ctz_backside_attachement: null,
  ctz_frontside_attachment: null,
  ctz_issuedate: null,
  ctz_issuedplace: "",
  ctz_num: "",
  dob: "",
  email: "",
  fullname_en: "",
  fullname_ne: "",
  id: 0,
  mobile: "",
  profilepicture_attachment: null,
  gender_id: 0,
};
interface PublicUsersState {
  data: Array<Object>;
  specific_public_user: any;
  user_document_id: number | null;
  modal_status: string;
  family_data: any;
  family_info_edit_id: number | null;
  user_family_document: {
    family_user_id: number | null;
    doc_id: number | null;
  };
  show_data: Array<Object>;
  items_per_page: any;
  current_page: any;
  filtered_data: Array<Object>;
  family_member_id: Number | null;
  family_relations: Array<object>;
}

const initialState: PublicUsersState = {
  data: [],
  specific_public_user: { ...data },
  user_document_id: null,
  modal_status: "create",
  family_data: { ...family_data },
  family_info_edit_id: null,
  user_family_document: {
    family_user_id: null,
    doc_id: null,
  },
  show_data: [],
  items_per_page: 10,
  current_page: 1,
  filtered_data: [],
  family_member_id: null,
  family_relations: [],
};

export const publicUserSlice = createSlice({
  name: "publicUserSlice",
  initialState,
  reducers: {
    show_data_public_users: (state) => {
      const startIndex = (state.current_page - 1) * state.items_per_page;
      const endIndex = startIndex + state.items_per_page;
      const displayData = state.filtered_data.slice(startIndex, endIndex);
      state.show_data = displayData;
    },
    search_items: (state, action) => {
      state.filtered_data =
        action.payload.trim().length !== 0
          ? state.data.filter((p: any) => {
              return (
                p.fullname_en
                  .toLowerCase()
                  .includes(action.payload.toLowerCase()) ||
                p.mobile.toLowerCase().includes(action.payload.toLowerCase()) ||
                p.ctz_num.toLowerCase().includes(action.payload.toLowerCase())
              );
            })
          : state.data;
      state.current_page = 1;
    },
    set_items_per_page: (state, action) => {
      state.items_per_page = action.payload;
    },
    set_current_page: (state, action) => {
      state.current_page = action.payload;
    },
    setpublicuser: (state, action) => {
      state.data = action.payload;
      state.filtered_data = action.payload;
    },
    addpublicuser: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    getSpecificpublicuser: (state, action) => {
      state.specific_public_user = {
        ...action.payload,
      };
    },
    setModalStatus: (state, action) => {
      state.modal_status = action.payload;
    },
    getPublicUserDocument: (state, action) => {
      state.user_document_id = action.payload;
    },
    getPublicUserFamilyDocument: (state, action) => {
      state.user_family_document = {
        ...action.payload,
      };
    },
    setPublicUserDocument: (state, action) => {
      state.user_document_id = action.payload;
    },
    setPublicUserFamilyDocument: (state, action) => {
      state.user_family_document = {
        ...state.user_family_document,
        [action.payload.name]: action.payload.value,
      };
    },
    setSpecificpublicuser: (state, action) => {
      state.specific_public_user = {
        ...state.specific_public_user,
        [action.payload.name]: action.payload.value,
      };
    },
    setFamilyData: (state, action) => {
      state.family_data = {
        ...state.family_data,
        [action.payload.name]: action.payload.value,
      };
    },
    getFamilyData: (state, action) => {
      state.family_data = {
        ...action.payload,
      };
    },
    resetFamilyData: (state) => {
      state.family_data = { ...family_data };
    },
    resetUserDocumentId: (state) => {
      state.user_document_id = 0;
      state.user_family_document = {
        family_user_id: null,
        doc_id: null,
      };
    },
    resetProfileForm: (state) => {
      state.specific_public_user = { ...data };
      state.user_document_id = 0;
      state.modal_status = "create";
    },
    deletepublicuser: (state, action) => {
      state.filtered_data = state.filtered_data.filter(
        (p: any) => p.id.toString() !== action.payload?.toString()
      );
      state.data = state.data.filter(
        (p: any) => p.id.toString() !== action.payload?.toString()
      );
    },
    setFamilyEditId: (state, action) => {
      state.family_info_edit_id = action.payload;
    },
    deleteUserDocument: (state, action) => {
      state.specific_public_user = {
        ...state.specific_public_user,
        [action.payload.name]: action.payload.value,
      };
    },
    setFamilyMemberId: (state, action) => {
      state.family_member_id = action.payload;
    },
    manageFamilyRelation: (state, action) => {
      state.family_relations = [...state.family_relations, action.payload];
    },
    transferAddressData: (state) => {
      state.specific_public_user = {
        ...state.specific_public_user,
        temporary_province_id: state.specific_public_user.permanent_province_id,
        temporary_district_id: state.specific_public_user.permanent_district_id,
        temporary_locallevel_id:
          state.specific_public_user.permanent_locallevel_id,
        temporary_ward: state.specific_public_user.permanent_ward,
        temporary_tole: state.specific_public_user.permanent_tole,
      };
    },
    clearTempAddressData: (state) => {
      state.specific_public_user = {
        ...state.specific_public_user,
        temporary_province_id: "",
        temporary_district_id: "",
        temporary_locallevel_id: "",
        temporary_ward: "",
        temporary_tole: "",
      };
    },
  },
});

export const {
  setpublicuser,
  addpublicuser,
  deletepublicuser,
  getSpecificpublicuser,
  setSpecificpublicuser,
  resetProfileForm,
  setPublicUserDocument,
  getPublicUserDocument,
  setModalStatus,
  deleteUserDocument,
  resetUserDocumentId,
  setFamilyData,
  getFamilyData,
  setFamilyEditId,
  setPublicUserFamilyDocument,
  getPublicUserFamilyDocument,
  resetFamilyData,
  search_items,
  show_data_public_users,
  set_items_per_page,
  set_current_page,
  setFamilyMemberId,
  manageFamilyRelation,
  transferAddressData,
  clearTempAddressData
} = publicUserSlice.actions;

export default publicUserSlice.reducer;
