import { useNavigate } from "react-router";
import Table from "../../../component/table_container/main_table";
import TableHeader from "../../../component/table_container/table_header";
import TableSubHeader from "../../../component/table_container/table_sub_header";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Http from "../../../utils/httpClients";
import Modal from "../../../component/modal";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  deletedocument,
  search_items,
  set_current_page,
  set_items_per_page,
  setdocument,
  show_data_document,
} from "../../../redux/store/document";
import { HiOutlineNewspaper } from "react-icons/hi";
const Document = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const document = useAppSelector((state: any) => state.document.data);
  const [modal, setModal] = useState<Boolean>(false);
  const [delete_id, setDeleteId] = useState<Number>();
  const data = useAppSelector((state: any) => state.document.show_data);
  const filtered_data = useAppSelector(
    (state: any) => state.document.filtered_data
  );
  const items_per_page = useAppSelector(
    (state: any) => state.document.items_per_page
  );
  const current_page = useAppSelector(
    (state: any) => state.document.current_page
  );
  useEffect(() => {
    Http.GET("/documents", true)
      .then((res) => {
        dispatch(setdocument(res.data.data));
        dispatch(show_data_document());
      })
      .catch((err) => {
        toast.error("Error occured while fetching documents!");
      });
  }, [dispatch]);
  const deleteDocument = async () => {
    await Http.DELETE("/documents/" + delete_id, true)
      .then((res: any) => {
        dispatch(deletedocument(delete_id));
        toast.success("Document has been deleted");
        setModal(false);
      })
      .catch((err: any) => {
        toast.error("Error occured while deleting document");
        setModal(false);
      });
  };
  const deleteHandler = (id: number) => {
    setDeleteId(id);
    setModal(true);
  };
  const show_items = (e: any) => {
    dispatch(set_items_per_page(e.value));
    dispatch(show_data_document());
  };
  const page_change = (event: any, pageNumber: any) => {
    dispatch(set_current_page(pageNumber));
    dispatch(show_data_document());
  };
  const change_input = (e: any) => {
    dispatch(search_items(e.target.value));
    dispatch(show_data_document());
  };
  return (
    <>
      <Modal
        close_modal={() => setModal(false)}
        title="Document"
        action={deleteDocument}
        open={modal}
      />
      <div className="table_wrapper">
        <div className="component_wrapper table_container">
          <TableHeader
            icon={<HiOutlineNewspaper />}
            primary_button_name="
            कागजात थप्नुहोस्"
            table_title="
            कागजात"
            items_length={document && document.length}
            on_add={() => navigate("add")}
          />
          <TableSubHeader
            no_of_items={show_items}
            item_select
            input_handler={change_input}
            placeholder="कागजात खोज्नुहोस्"
          />
          <Table
            page_change={page_change}
            current_page={current_page}
            page_count={Math.ceil(filtered_data.length / items_per_page)}
            pagination_display={document.length >= 10}
            item_range={
              data.length * (current_page - 1) +
              "-" +
              current_page * data.length +
              " items"
            }
            table_headings={["s.n.", "Document Name", "actions"]}
          >
            {data?.map((p: any, i: any) => (
              <tr key={i}>
                <td>{p.id}</td>
                <td className="overflow_control">{p.doc_name}</td>
                <td className="align_items table_icons">
                  <AiOutlineDelete
                    data-tooltip-id="view"
                    data-tooltip-content="Delete"
                    onClick={() => deleteHandler(p.id)}
                  />
                  <FiEdit2
                    data-tooltip-id="view"
                    data-tooltip-content="Update"
                    onClick={() =>
                      navigate("/system_configuration/document/update/" + p.id)
                    }
                  />
                </td>
              </tr>
            ))}
            {document.length < 1 && (
              <tr>
                <td colSpan={3} className="no_match_text">
                  No Document has been added
                </td>
              </tr>
            )}
          </Table>
        </div>
      </div>
    </>
  );
};
export default Document;
