import { useNavigate, useParams } from "react-router";
import { useAppDispatch } from "../../../hooks/hook";
import { setSifarisFormTitle } from "../../../redux/store/sifaris_type";
import FormTitleContainer from ".";
const FormTitleUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const form_handler = (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);
    dispatch(setSifarisFormTitle(data.get("form_title")));
    navigate(
      "/system_configuration/sifaris_type/form/dynamic_form/update/" + id
    );
  };
  return (
    <>
      <FormTitleContainer title="Update" form_handler={form_handler} />
    </>
  );
};

export default FormTitleUpdate;
