import { AnimatePresence, motion } from "framer-motion";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { NavLink } from "react-router-dom";
import "./index.scss";
import { useRef, useState } from "react";
import { ControlledMenu, MenuItem, useHover } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { useAppSelector } from "../../../../../hooks/hook";
const menuAnimation = {
  hidden: {
    opacity: 0,
    height: 0,
    padding: 0,
    transition: { duration: 0.3, when: "afterChildren" },
  },
  show: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0.3,
      when: "beforeChildren",
    },
  },
};
interface SubMenuProps {
  route_name: string;
  icon?: JSX.Element;
  children?: any;
  show?: boolean;
  toggle_sidebar_link?: any;
  active?:boolean
}
const SubMenu = (props: SubMenuProps) => {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen);
  const minimize_link = useAppSelector(
    (state: any) => state.sidebar.sidebar_max
  );
  return (
    <>
      <div style={{ width: "100%" }} ref={ref} {...anchorProps}>
        <div
          onClick={props.toggle_sidebar_link}
          className={[
            "sidebar_link flex row center space_between",
            minimize_link && "minimize_sidebar_link",
            minimize_link && props.active && "sidebar_link_active",
            props.active && "sidebar_sub_menu_link_active",
          ].join(" ")}
        >
          <div className="flex row center" style={{ gap: "8px" }}>
            {props.icon}
            {!minimize_link ? (
              <p style={{ textTransform: "capitalize" }}>{props.route_name}</p>
            ) : null}
          </div>
          {!minimize_link ? (
            <motion.span
              animate={{ rotate: props.show ? 180 : 0 }}
              style={{ height: "23px" }}
            >
              <MdOutlineKeyboardArrowDown />
            </motion.span>
          ) : null}
        </div>
        {!minimize_link ? (
          <AnimatePresence>
            {props.show && (
              <motion.div
                variants={menuAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                className="sidebar_sub_menu"
              >
                {props.children.map((p: any) => (
                  <NavLink
                    to={p.path}
                    key={p.name}
                    className={({ isActive }) =>
                      isActive
                        ? "sidebar_sub_menu_link sidebar_sub_menu_link_active"
                        : "sidebar_sub_menu_link"
                    }
                  >
                    {p.icon}
                    <p style={{ textTransform: "capitalize" }}>{p.name}</p>
                  </NavLink>
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        ) : null}
      </div>
      {minimize_link ? (
        <ControlledMenu
          {...hoverProps}
          state={isOpen ? "open" : "closed"}
          anchorRef={ref}
          direction="right"
          arrow
          onClose={() => setOpen(false)}
        >
          {props.children.map((p: any) => (
            <MenuItem style={{ padding: 0 }} key={p.name}>
              <NavLink
                to={p.path}
                style={{ width: "100%" }}
                className={({ isActive }) =>
                  isActive
                    ? "hidden_sidebar_menu hidden_sidebar_menu_active"
                    : "hidden_sidebar_menu"
                }
              >
                {p.name}
              </NavLink>
            </MenuItem>
          ))}
        </ControlledMenu>
      ) : null}
    </>
  );
};
export default SubMenu;
