import Signature from "../../lang_nepali/signature";
const Content = () => {
  
  return (
    <>
      <p className="paragraph_indent">
        निवेदक श्री .................. तथा श्रीमती .................. को निवेदन अनुसार
        उहाँहरुको छोरा/छोरी .................. महा/उपमहा/नगरपालिका/गाउँपालिका वडा नं. ..................
        स्थाई ठेगाना (साबिकको ठेगाना .................. ) भएको श्री/सुश्री/श्रीमती .................. को
        जन्म मिति .................. गते पेश गरेको कागजातका आधारमा .................. स्थानमा भएको
        व्यहोरा प्रमाणित गरिन्छ |
      </p>
      <Signature />
    </>
  );
};
export default Content;
