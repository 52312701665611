import { useNavigate, useParams } from "react-router";
import { useAppDispatch } from "../../../hooks/hook";
import {
  resetDynamicInputField,
  resetSifarisForm,
  setSifarisFormTitle,
} from "../../../redux/store/sifaris_type";
import FormTitleContainer from ".";
import { useEffect } from "react";
const FormTitleAdd = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    dispatch(resetSifarisForm());
    dispatch(resetDynamicInputField());
  }, [dispatch]);
  const form_handler = (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);
    dispatch(setSifarisFormTitle(data.get("form_title")));
    navigate(
      "/system_configuration/sifaris_type/form/dynamic_form/create/" + id
    );
  };
  return (
    <>
      <FormTitleContainer title="Create" form_handler={form_handler} />
    </>
  );
};

export default FormTitleAdd;
