import Table from "../../../component/table_container/main_table";
import TableHeader from "../../../component/table_container/table_header";
import TableSubHeader from "../../../component/table_container/table_sub_header";
import { useEffect, useState } from "react";
import Http from "../../../utils/httpClients";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from "react-router";
import Modal from "../../../component/modal";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { setoffice } from "../../../redux/store/office";
import { toast } from "react-toastify";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
const Office = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState<Boolean>(false);
  const [delete_id, setDeleteId] = useState<Number>();
  const data = useAppSelector((state: any) => state.offices?.data);
  const [search, setSearch] = useState("");
  useEffect(() => {
    Http.GET("/offices", true)
      .then((res) => {
        dispatch(setoffice(res.data.data));
      })
      .catch((err) => {
        toast.error("Error occured while fetching office data!");
      });
  }, [dispatch]);
  const deleteOffice = async () => {
    await Http.DELETE("/offices/" + delete_id, true)
      .then((res) => {
        setModal(false);
      })
      .then((err) => {
        toast.error("Error occured while deleting office");
        setModal(false);
      });
  };
  const deleteHandler = (id: number) => {
    setDeleteId(id);
    setModal(true);
  };
  return (
    <>
      <Modal
        close_modal={() => setModal(false)}
        action={deleteOffice}
        open={modal}
        title="office"
      />
      <div className="table_wrapper">
        <div className="component_wrapper table_container">
          <TableHeader
            icon={<HiOutlineBuildingOffice />}
            primary_button_name="
          कार्यालय थप्नुहोस्"
            table_title="कार्यालय"
            on_add={() => navigate("add")}
            items_length={data ? data.length : 0}
          />
          <TableSubHeader
            input_handler={(e: any) => setSearch(e.target.value)}
            placeholder="Search office....."
          />
          <Table
            table_headings={[
              "s.n.",
              "office type",
              "name/dept",
              "address",
              "contact",
              "status",
              "last modified by",
              "actions",
            ]}
          >
            {data
              ?.filter((p: any) => {
                return search.trim().length !== 0
                  ? p.office_type?.office_type
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                      p.office_name
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      p.office_address
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      p.office_phone
                        .toLowerCase()
                        .includes(search.toLowerCase())
                  : p;
              })
              ?.map((p: any, i: any) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{p.office_type?.office_type}</td>
                  <td className="overflow_control">{p.office_name}</td>
                  <td>{p.office_address}</td>
                  <td>{p.office_phone}</td>
                  <td>{p.is_active ? "IsActive" : "NotActive"}</td>
                  <td>{p.updated_at ? p.updated_at : "Not Modified Yet"}</td>
                  <td className="align_items table_icons">
                    <AiOutlineEye
                      data-tooltip-id="view"
                      data-tooltip-content="Delete"
                      onClick={() => navigate("view/" + p.id)}
                    />
                    <AiOutlineDelete
                      data-tooltip-id="view"
                      data-tooltip-content="Delete"
                      onClick={() => deleteHandler(p.id)}
                    />
                    <FiEdit2
                      data-tooltip-id="view"
                      data-tooltip-content="Update"
                      onClick={() =>
                        navigate("/system_configuration/office/update/" + p.id)
                      }
                    />
                  </td>
                </tr>
              ))}
            {data && data.length < 1 && (
              <tr>
                <td colSpan={7} className="no_match_text">
                  No Office has been added
                </td>
              </tr>
            )}
          </Table>
        </div>
      </div>
    </>
  );
};
export default Office;
