import { PayloadAction, createSlice } from "@reduxjs/toolkit";
interface AddSifarisState {
  data: Object;
  personal_data: Object;
  relation_data: Object;
  form_fields: any;
  table_fields: any;
  table_rows: Array<Object>;
  current_lang: string;
}

const initialState: AddSifarisState = {
  data: {},
  personal_data: {},
  relation_data: {},
  form_fields: {},
  table_fields: {},
  table_rows: [],
  current_lang: "eng",
};

export const addSifarisSlice = createSlice({
  name: "addSifarisSlice",
  initialState,
  reducers: {
    change_search_lang: (state, action:PayloadAction<string>) => {
      state.current_lang = action.payload;
    },
    setUserPersonalData: (state, action:PayloadAction<any>) => {
      let value = state.current_lang === "eng" ? "en" : "np";
      state.data = {
        ...state.data,
        ...action.payload.familyprofile,
        ...action.payload,
        permanentdistrict:
          action.payload.familyprofile?.permanentdistrict["district_" + value],
        permanentprovince:
          action.payload.familyprofile?.permanentprovince["province_" + value],
        permanentlocallevel:
          action.payload.familyprofile?.permanentlocallevel["lgname_" + value],
        temporarydistrict:
          action.payload.familyprofile?.temporarydistrict["district_" + value],
        temporaryprovince:
          action.payload.familyprofile?.temporaryprovince["province_" + value],
        temporarylocallevel:
          action.payload.familyprofile?.temporarylocallevel["lgname_" + value],
        address:
          action.payload.familyprofile?.permanent_tole +
          "-" +
          action.payload.familyprofile?.permanent_ward +
          ", " +
          action.payload.familyprofile?.permanentlocallevel["lgname_" + value] +
          ", " +
          action.payload.familyprofile?.permanentdistrict["district_" + value] +
          ", " +
          action.payload.familyprofile?.permanentprovince["province_" + value],
      };
      state.form_fields = { ...state.data };
      state.table_rows[0] = { ...state.data };
    },
    setUserRelationData: (state, action) => {
      let value = state.current_lang === "eng" ? "en" : "np";
      let data = action.payload;
      let relations = data.map((p: any) => p.relation);
      let relation_data = data.map((p: any) => p.tofamilymember);
      for (let i = 0; i < relation_data.length; i++) {
        for (let key in relation_data[i]) {
          const newName = `${relations[i]["relation_" + value]
            .toString()
            .toLowerCase()}_${key}`;
          relation_data[i][newName] = relation_data[i][key];
          delete relation_data[i][key];
        }
        state.data = { ...state.data, ...relation_data[i] };
        state.form_fields = { ...state.data };
        state.table_rows[0] = { ...state.data };
        console.log(state.form_fields);
      }
    },
    setSearchData: (state, action) => {
      let data = action.payload;
      state.data = {
        ...state.data,
        ...data,
      };
      state.form_fields = { ...state.data, ...data };
      state.table_rows[0] = { ...state.data, ...data };
    },
    resetSearchData: (state) => {
      state.data = {};
      state.form_fields = {};
      state.table_rows = [];
    },
    setFormFields: (state, action) => {
      state.form_fields = action.payload.reduce((acc: any, obj: any) => {
        Object.keys(obj).forEach((key) => {
          acc[key] = obj[key];
        });
        return acc;
      }, {});
    },
    //change input value of input field of form field
    changeFormField: (state, action) => {
      state.form_fields = {
        ...state.form_fields,
        [action.payload.name]: action.payload.value,
      };
    },
    addTableRows: (state) => {
      state.table_rows = [...state.table_rows, state.table_fields];
    },

    setTableFields: (state, action) => {
      state.table_fields = action.payload.reduce((acc: any, obj: any) => {
        Object.keys(obj).forEach((key) => {
          acc[key] = obj[key];
        });
        return acc;
      }, {});
      state.table_rows = [state.table_fields];
    },
    //change input fields for table cells inputs
    changeInputFields: (state, action) => {
      state.table_rows[action.payload.id] = {
        ...state.table_rows[action.payload.id],
        [action.payload.name]: action.payload.value,
      };
    },
    deleteTableRows: (state, action) => {
      state.table_rows = state.table_rows.filter(
        (_: any, id: number) => id !== action.payload.id
      );
    },
  },
});

export const {
  setSearchData,
  setFormFields,
  addTableRows,
  setTableFields,
  changeFormField,
  changeInputFields,
  deleteTableRows,
  resetSearchData,
  setUserPersonalData,
  setUserRelationData,
  change_search_lang
} = addSifarisSlice.actions;

export default addSifarisSlice.reducer;
