import ManageLayout from "../../../../component/manage_layout";
import InputField from "../../../../component/input_container";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { setSpecificdesignation } from "../../../../redux/store/designation";
import { CenterElement } from "../../../../component/manage_layout/center_element";
const DesignationContainer = (props: {
  title: string;
  form_handler: any;
  update?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.designation.designation);
  const loading = useAppSelector((state) => state.designation.loading);
  const designation_change = (e: any) => {
    dispatch(setSpecificdesignation(e.target.value));
  };
  return (
    <CenterElement>
      <ManageLayout
        form_handler={props.form_handler}
        page_title={"पदनाम " + props.title}
        form_width="50%"
        update={props.update}
        loading={loading}
      >
        <InputField
          placeholder="Enter designation"
          type="text"
          required
          label="Designation"
          name="designation"
          value={data}
          on_change={designation_change}
        />
      </ManageLayout>
    </CenterElement>
  );
};
export default DesignationContainer;
