import React, { useEffect, useState } from "react";
import { AiOutlineCopy, AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import TableHeader from "../../component/table_container/table_header";
import TableSubHeader from "../../component/table_container/table_sub_header";
import Table from "../../component/table_container/main_table";
import { AiOutlineFile, AiOutlineEye } from "react-icons/ai";
import { FaWpforms } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Http from "../../utils/httpClients";
import { BsTable } from "react-icons/bs";
import {
  setSifarisTypes,
  delete_sifaris,
  set_items_per_page,
  show_data_sifaristype,
  set_current_page,
  search_items,
} from "../../redux/store/sifaris_type";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import Modal from "../../component/modal";
import { CgAddR } from "react-icons/cg";
import style from "./index.module.scss";
type Props = {};
const view_template = (p: any) => {
  return !(
    p.sifarisdocuments.length > 0 ||
    p.sifarisforms.length > 0 ||
    p.sifaristables.length > 0
  );
};
const create_template = (p: any) => {
  return !(
    p.sifarisdocuments.length > 0 &&
    p.sifarisforms.length > 0 &&
    p.sifaristables.length > 0
  );
};
const create_table = (p: any) => {
  return !(p.sifaristables.length > 0);
};
const create_forms = (p: any) => {
  return !(p.sifarisforms.length > 0);
};
const create_documents = (p: any) => {
  return !(p.sifarisdocuments.length > 0);
};
const SifarisType = (props: Props) => {
  const sifarisType = useAppSelector((state: any) => state.sifaris_types.types);
  const [modal, setModal] = useState<boolean>(false);
  const [delete_id, setDeleteId] = useState<number>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const data = useAppSelector((state: any) => state.sifaris_types.show_data);
  const filtered_data = useAppSelector(
    (state: any) => state.sifaris_types.filtered_data
  );
  const items_per_page = useAppSelector(
    (state: any) => state.sifaris_types.items_per_page
  );
  const current_page = useAppSelector(
    (state: any) => state.sifaris_types.current_page
  );
  useEffect(() => {
    Http.GET("/sifaristypes", true)
      .then((res) => {
        dispatch(setSifarisTypes(res.data.data));
        dispatch(show_data_sifaristype());
      })
      .catch((er) => {
        toast.error("Error occured while fetching sifaris types");
      });
  }, [dispatch]);

  const handleEditForm = (id: any, path: string, set?: boolean) => {
    if (set) {
      navigate(path + id);
    }
  };
  const delete_handler = (id: number) => {
    setDeleteId(id);
    setModal(true);
  };
  const deleteSifaris = () => {
    Http.DELETE("/sifaristypes/" + delete_id, true)
      .then((res) => {
        toast.success("Sifaris type has been deleted successfully!");
        dispatch(delete_sifaris(delete_id));
        setModal(false);
      })
      .catch((err) => {
        toast.error("Error occured while deleting sifaris type");
        setModal(false);
      });
  };
  const show_items = (e: any) => {
    dispatch(set_items_per_page(e.value));
    dispatch(show_data_sifaristype());
  };
  const page_change = (event: any, pageNumber: any) => {
    dispatch(set_current_page(pageNumber));
    dispatch(show_data_sifaristype());
  };
  const change_input = (e: any) => {
    dispatch(search_items(e.target.value));
    dispatch(show_data_sifaristype());
  };
  return (
    <div
      className="component_wrapper table_wrapper"
      style={{ borderRadius: "0", border: "none" }}
    >
      <Modal
        title="sifaris"
        action={deleteSifaris}
        open={modal}
        close_modal={() => setModal(false)}
      />
      <div className="component_wrapper table_container">
        <TableHeader
          icon={<AiOutlineCopy />}
          primary_button_name="सिफारिस सूची थप्नुहोस्"
          table_title="सिफारिस सूची"
          items_length={sifarisType && sifarisType.length}
          on_add={() => navigate("add")}
        />
        <TableSubHeader
          no_of_items={show_items}
          input_handler={change_input}
          placeholder="Search office data...."
          item_select
        />
        <Table
          page_change={page_change}
          current_page={current_page}
          page_count={Math.ceil(filtered_data.length / items_per_page)}
          pagination_display={sifarisType.length >= 10}
          item_range={
            data.length * (current_page - 1) +
            "-" +
            current_page * data.length +
            " items"
          }
          table_headings={["S.N", "Sifaris Type", "actions"]}
        >
          {data?.map((sifaris: any, index: number) => (
            <tr key={index}>
              <td>{sifaris.id}</td>
              <td>{sifaris.name}</td>
              <td className="align_items table_icons">
                <AiOutlineEye
                  data-tooltip-id="view"
                  className={
                    view_template(sifaris) ? style.icon_disable : style.sc
                  }
                  data-tooltip-content={
                    view_template(sifaris)
                      ? "Click on add icon"
                      : "Preview sifaris"
                  }
                  onClick={() =>
                    handleEditForm(
                      sifaris.id,
                      "template_form/",
                      !view_template(sifaris)
                    )
                  }
                />
                <CgAddR
                  className={
                    !create_template(sifaris) ? style.icon_disable : style.sc
                  }
                  data-tooltip-id="view"
                  data-tooltip-content={
                    !create_template(sifaris)
                      ? "Sifaris Already Exists"
                      : "Create Sifaris Components"
                  }
                  onClick={() =>
                    handleEditForm(
                      sifaris.id,
                      "create/",
                      create_template(sifaris)
                    )
                  }
                />
                <AiOutlineFile
                  data-tooltip-id="view"
                  className={
                    create_documents(sifaris) ? style.icon_disable : style.sc
                  }
                  data-tooltip-content={
                    !create_documents(sifaris)
                      ? "Update sifaris documents"
                      : "Click on add icon"
                  }
                  onClick={() =>
                    handleEditForm(
                      sifaris.id,
                      "sifaris_file/",
                      !create_documents(sifaris)
                    )
                  }
                />
                <FaWpforms
                  data-tooltip-id="view"
                  className={
                    create_forms(sifaris) ? style.icon_disable : style.sc
                  }
                  data-tooltip-content={
                    !create_forms(sifaris) ? "Update Form" : "Click on add icon"
                  }
                  onClick={() =>
                    handleEditForm(sifaris.id, "form/", !create_forms(sifaris))
                  }
                />
                <BsTable
                  data-tooltip-id="view"
                  className={
                    create_table(sifaris) ? style.icon_disable : style.sc
                  }
                  data-tooltip-content={
                    !create_table(sifaris)
                      ? "Update Table"
                      : "Click on add icon"
                  }
                  onClick={() =>
                    handleEditForm(sifaris.id, "table/", !create_table(sifaris))
                  }
                />

                <FiEdit2
                  data-tooltip-id="view"
                  data-tooltip-content="Update Sifaris Type"
                  onClick={() => navigate("update/" + sifaris.id)}
                />
                <AiOutlineDelete
                  data-tooltip-id="view"
                  data-tooltip-content="Delete Sifairis Type"
                  onClick={() => delete_handler(sifaris.id)}
                />
              </td>
            </tr>
          ))}
        </Table>
      </div>
    </div>
  );
};

export default SifarisType;
