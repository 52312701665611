import { useEffect } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../hooks/hook";
import {
  setSpecificofficetype,
  set_loading_officetype,
  setofficetype,
} from "../../../../redux/store/office_type";
import Http from "../../../../utils/httpClients";
import OfficeTypeContainer from "../container";
const UpdateOfficeType = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    Http.GET("/officetypes/" + id, true)
      .then((res) => {
        console.log(res.data.data.office_type);
        dispatch(setSpecificofficetype(res.data.data.office_type));
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error occured while fetching office type!");
      });
  }, [id, dispatch]);
  const form_handler = (e: any) => {
    e.preventDefault();
    const form = new FormData(e.target);

    dispatch(set_loading_officetype(true));
    Http.PUT(
      "/officetypes" + id,
      { office_type: form.get("office_type") },
      true
    )
      .then((res) => {
        Http.GET("/officetypes", true).then((res) => {
          console.log(res);
          dispatch(set_loading_officetype(false));
          dispatch(setofficetype(res.data.data));
        });
        toast.success("Office type has been updated");
      })
      .catch((err) => {
        console.log(err);
        dispatch(set_loading_officetype(false));
        toast.error("Error occured while updating office type!");
      });
  };
  return <OfficeTypeContainer title="सम्पादन" update form_handler={form_handler} />;
};
export default UpdateOfficeType;
