import InputField from "../../../component/input_container";
import style from "../../dynamic_form/index.module.scss";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  setSifarisTableHeader,
  setSifarisTableTitle,
} from "../../../redux/store/sifaris_type";
import { CenterElement } from "../../../component/manage_layout/center_element";
const TableTitleContainer = (props: { title: string; formHandle: any }) => {
  const dispatch = useAppDispatch();
  const table_title = useAppSelector(
    (state: any) => state.sifaris_types.table_title
  );
  const input_changeHandler = (e: any) => {
    if (e.target.name === "table_title") {
      dispatch(setSifarisTableTitle(e.target.value));
    }
    if (e.target.name === "table_header") {
      dispatch(setSifarisTableHeader(e.target.value));
    }
  };
  return (
    <CenterElement>
      <div
        className="component_wrapper"
        style={{ width: "50%", margin: "0 auto" }}
      >
        <p className={style.title}>{props.title} Table Title</p>
        <form onSubmit={props.formHandle} className={style.form_style}>
          <InputField
            type="text"
            label="Table Title"
            placeholder="Enter table title"
            name="table_title"
            value={table_title}
            label_class="bold"
            required
            on_change={input_changeHandler}
          />
          {/* <InputField
            type="text"
            label="Table Headings"
            placeholder="Enter Table Headings"
            name="table_header"
            label_class="bold"
            required
            value={table_header}
            on_change={input_changeHandler}
          /> */}
          <button
            className="primary_button"
            style={{
              fontSize: "17px",
              fontWeight: "500",
              padding: "10px 1rem",
            }}
          >
            {props.title} & Next
          </button>
        </form>
      </div>
    </CenterElement>
  );
};
export default TableTitleContainer;
