import Signature from "../../lang_nepali/signature";
const Content = () => {

  return (
    <>
      <p className="paragraph_indent">
        श्री .................. को नाति श्री .................. तथा श्रीमती ..................
        को छोरा, .................. (साबिकको ठेगाना ..................) निवासी श्री ..................
        र श्री .................. को नातिनी श्री .................. तथा श्रीमती .................. को छोरी,
        .................. (साबिकको ठेगाना ..................) निवासी सुश्री ..................
        बीच मिति: .................. मा विवाह भएको व्यहोरा प्रमाणित गरिन्छ |
      </p>
      <Signature />
    </>
  );
};
export default Content;
