import styles from "./index.module.scss";
interface WidgetProps {
  bg_color: any;
  title: String;
  amount: any;
}
const Widget = (props: WidgetProps) => {
  return (
    <div className={styles.widget}>
      <div className={styles.color_line} style={{ background: props.bg_color }} />
      <div className={styles.line_gap}>
        <p className={styles.cap_grey_text}>{props.title}</p>
        <p className={["bold_text" , styles.align_text].join(" ")}>
          {props.amount} <i className={styles.italic_text}>This month</i>
        </p>
      </div>
    </div>
  );
};
const FineCollectionChart = () => {
  return (
    <div className={["component_wrapper" ,styles.fine_collection_chart].join(" ")}>
      <p className="bold_text">Fine Collection Overview</p>
      <p className={styles.grey_font}>Collection from all channels</p>
      <div className={styles.widgets}>
        <Widget
          bg_color="#165BAA
"
          title="highest anugaman"
          amount="5,200"
        />
        <Widget
          bg_color="#F765A3
"
          title="highest jafat items"
          amount="5,200"
        />{" "}
        <Widget
          bg_color="#A155B9
"
          title="highest fined suppliers"
          amount="5,200"
        />
      </div>
    </div>
  );
};
export default FineCollectionChart;
