import InputField from "../../../../component/input_container";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { setSpecificdocument } from "../../../../redux/store/document";
import ManageLayout from "../../../../component/manage_layout";
import { CenterElement } from "../../../../component/manage_layout/center_element";
import { useNavigate } from "react-router";
const ManageDocument = (props: {
  form_handler: any;
  title: string;
  update?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(
    (state: any) => state.document.specific_document.name
  );
  const changeHandler = (e: any) => {
    dispatch(setSpecificdocument(e.target.value));
  };
  return (
    <CenterElement>
      <ManageLayout
        form_width="50%"
        page_title={"कागजात" + props.title}
        update={props.update}
      >
        <InputField
          type="text"
          placeholder="
          कागजात नाम"
          name="doc_name"
          label="
          कागजात "
          label_class="bold"
          value={data}
          required
          on_change={changeHandler}
        />
      </ManageLayout>
    </CenterElement>
  );
};
export default ManageDocument;
