import "../federal_structure/index.scss";
import Layout from "../../component/hoc";
import TableHeader from "./component/header";
import Table from "./component/table";
import TableSubHeader from "./component/sub_header";
const Overview = () => {
  return (
    <Layout>
      <div className="component_wrapper table_wrapper">
        <div className="component_wrapper table_container">
          <TableHeader />
          <TableSubHeader />
          <Table />
        </div>
      </div>
    </Layout>
  );
};
export default Overview;
