import Content from "./content";
import Footer from "../lang_nepali/footer";
import Header from "../lang_nepali/header";
import SifarisDocLayout from "..";
const Template_Nep_3 = () => {
  return (
    <SifarisDocLayout>
      <Header />
      <Content />
      <Footer />
    </SifarisDocLayout>
  );
};
export default Template_Nep_3;
