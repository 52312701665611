import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { CenterElement } from "../../../../../component/manage_layout/center_element";
import { IoReturnUpBack } from "react-icons/io5";
import { FiEdit2, FiUser } from "react-icons/fi";
import { get_user } from "../../../../../utils/api/user_configuration/user";
import ReturnButton from "../../../../../component/buttons/return_button";
import { UpdateButton } from "../../../../../component/buttons/update_button";
const Childs = (props: { label: string; value: string }) => {
  return (
    <div className="input_container">
      <p className="input_label">{props.label}</p>
      <p className="input_p">{props.value}</p>
    </div>
  );
};
const Container = (props: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
      }}
    >
      {props.children}
    </div>
  );
};
const ViewUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState<any>();
  useEffect(() => {
    if (id !== undefined) {
      get_user(id)
        .then((res) => {
          console.log(res.data.data);
          setUser(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error occured while fetching user");
        });
    }
  }, [id]);

  return (
    <CenterElement>
      <div
        className={"component_wrapper"}
        style={{ width: "70%", margin: "0 auto", justifyContent: "center" }}
      >
        <div
          className="flex center"
          style={{ justifyContent: "space-between", paddingBottom: "1rem" }}
        >
          <ReturnButton />
          <p className="title bold align_items" style={{ fontSize: "2rem" }}>
            <FiUser />
            प्रयोगकर्ता विवरण
          </p>
          <UpdateButton url={"/user_configuration/user/update/" + id} />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Container>
            <Childs label="Full Name" value={user?.name} />
            <Childs label="Position" value={user?.roles[0]?.role} />
          </Container>
          <Container>
            <Childs label="Email" value={user?.email} />
            <Childs label="Phone Number" value={user?.phone_number} />
          </Container>{" "}
          <Container>
            <Childs label="Address" value={user?.address} />
            <Childs
              label="Designation"
              value={user?.designation?.designation}
            />
          </Container>{" "}
          <Container>
            <Childs label="Office" value={user?.office?.office_name} />
            <Childs
              label="Office Address"
              value={user?.office?.office_address}
            />
          </Container>
          <Container>
            <Childs label="Office Email" value={user?.office?.office_email} />
            <Childs label="Office Phone" value={user?.office?.office_phone} />
          </Container>
        </div>
      </div>
    </CenterElement>
  );
};
export default ViewUser;
