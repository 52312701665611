import ManageLayout from "../../../component/manage_layout";
import InputField from "../../../component/input_container";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { setSpecificofficetype } from "../../../redux/store/office_type";
import { CenterElement } from "../../../component/manage_layout/center_element";
const OfficeTypeContainer = (props: {
  form_handler: any;
  title: string;
  update?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const value = useAppSelector((state: any) => state.office_types.office_type);
  const office_type_change = (e: any) => {
    dispatch(setSpecificofficetype(e.target.value));
  };
  return (
    <CenterElement>
      <ManageLayout
        form_handler={props.form_handler}
        page_title={"कार्यालय " + props.title}
        form_width="50% "
        update={props.update}
      >
        <InputField
          placeholder="Enter office type"
          type="text"
          label="Office Type"
          name="office_type"
          required
          value={value}
          on_change={office_type_change}
        />
      </ManageLayout>
    </CenterElement>
  );
};
export default OfficeTypeContainer;
