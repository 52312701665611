import Select, { SingleValue } from "react-select";
import style from "./index.module.scss";
interface SelectProps {
  options?: any;
  on_change?: any;
  selectedOption?: object;
  value?: any;
  label?: string;
  placeholder?: string;
  label_class?: string;
  required?: boolean;
  name?: string;
}
export default function SelectInput(props: SelectProps) {
  return (
    <div className={style.select_input} style={{ width: "100%" }}>
      <p className={props.label_class} style={{ color: "#000" }}>
        {props.label}
        {props.required ? <span style={{ color: "red" }}>*</span> : null}
      </p>
      <Select
        value={props.value}
        defaultValue={props.selectedOption}
        options={props.options}
        onChange={props.on_change}
        required={props.required}
        name={props.name}
        placeholder={props.placeholder || "select options"}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            cursor: "pointer",
            fontSize: "18px",
            width: "100%",
            padding: "3px",
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary50: "#ffe9e8",
            primary25: "#ffe9e8",
            primary: "#ea4335",
          },
        })}
      />
    </div>
  );
}
