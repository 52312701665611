import { useState, useEffect, useRef } from "react";
import httpClients from "../../../utils/httpClients";
import { useParams } from "react-router";
import "./required.module.scss";
import Table from "../../../component/table_container/main_table";
import style from "./required.module.scss";
import { FiEye } from "react-icons/fi";
import { useAppSelector } from "../../../hooks/hook";
import { fileSizeHandler } from "../../../utils/utitlity";
import Modal from "./modal";
const Required = () => {
  const ref = useRef<HTMLInputElement>(null);
  const [required, setRequired] = useState<any>([]);
  const [docs, setDocuments] = useState<any>([]);
  const [current_id, setCurrentId] = useState<number>(0);
  const [modal, setModal] = useState<boolean>(false);
  const { id } = useParams();
  var data = useAppSelector((state: any) => state.add_sifaris.data);
  useEffect(() => {
    httpClients
      .GET(`sifaristypes/${id}`, true)
      .then((res: any) => {
        console.log();
        setRequired(res.data.data);
      })
      .catch((err: any) => console.log("Something went wrong", err.message));
  }, [id, docs]);
  const fileHandler = (value: number) => {
    setCurrentId(value);
    setModal(true);
    console.log(value);
    if (ref !== null) {
      ref && ref.current && ref.current.click();
    }
  };

  const change_document_upload = (e: any) => {
    setDocuments([...docs, { id: current_id, doc: e.target.files[0] }]);
    e.target.value = null;
  };
  const close_modal = () => {
    setModal(false);
  };
  const submit_modal = () => {};
  return (
    <>
      <Modal
        title="Upload "
        on_submit={submit_modal}
        show_modal={modal}
        on_close={close_modal}
        change_document_upload={change_document_upload}
        document={docs.length > 0 && docs[current_id]?.doc}
      />
      <fieldset
        style={{
          border: "1px solid #ccc",
          borderRadius: "5px",
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          backgroundColor: "#fff",
        }}
      >
        <legend
          className="bold"
          style={{
            color: "#1f60ba",
            fontSize: "1.5rem",
            padding: "0 8px",
          }}
        >
          आवश्यक कागजातहरू
        </legend>
        <Table table_headings={["SN", "Document Name", "Upload"]}>
          {required.sifarisdocuments?.map((item: any, index: number) => (
            <tr>
              <td>{index + 1}</td>
              <td>{item.doc_name}</td>
              <td>
                {data?.familymemberdocuments
                  ?.map((p: any) => p.id)
                  .includes(item.id) ? (
                  <a
                    href={
                      process.env.REACT_APP_BASE_URL +
                      data?.familymemberdocuments.filter(
                        (p: any) => p.id === item.id
                      )[0].upload_path
                    }
                    rel="noreferrer"
                    target="_blank"
                    style={{
                      width: "max-content",
                      cursor: "pointer",
                    }}
                    className={[style.file_button, "align_items"].join(" ")}
                  >
                    <FiEye />
                    Preview
                  </a>
                ) : (
                  <>
                    {docs.map((p: any) => p.id).includes(index) ? (
                      <p className="flex center" style={{ gap: "10px" }}>
                        {docs[index]?.doc?.name}
                        <span style={{ color: "red" }}>
                          ({fileSizeHandler(docs[index]?.doc?.size)})
                        </span>
                      </p>
                    ) : (
                      <>
                        <div
                          onClick={() => fileHandler(index)}
                          className={style.file_button}
                          style={{
                            width: "max-content",
                            cursor: "pointer",
                          }}
                        >
                          Upload Document
                        </div>
                      </>
                    )}
                  </>
                )}
              </td>{" "}
              {/* <input
                onChange={(change_document_upload)}
                name="attachment"
                style={{ display: "none" }}
                type="file"
                ref={ref}
              /> */}
            </tr>
          ))}
        </Table>
      </fieldset>
    </>
  );
};

export default Required;
