import { createSlice } from "@reduxjs/toolkit";
interface UserState {
  data: Array<Object>;
  loading: false;
  edit_id: number;
  specific_user: {
    name: string;
    address: string;
    email: string;
    phone_number: string;
    password: string;
    office_id: number;
    designation_id: number;
    roles: Array<number>;
    active_status: number;
  };
  show_data: Array<Object>;
  items_per_page: any;
  current_page: any;
  filtered_data: Array<Object>;
}

const initialState: UserState = {
  data: [],
  edit_id: 0,
  loading: false,
  specific_user: {
    name: "",
    address: "",
    email: "",
    phone_number: "",
    password: "",
    office_id: 0,
    designation_id: 0,
    roles: [],
    active_status: 0,
  },
  show_data: [],
  items_per_page: 10,
  current_page: 1,
  filtered_data: [],
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    set_loading_user: (state, action) => {
      state.loading = action.payload;
    },
    show_data_users: (state) => {
      const startIndex = (state.current_page - 1) * state.items_per_page;
      const endIndex = startIndex + state.items_per_page;
      const displayData = state.filtered_data.slice(startIndex, endIndex);
      state.show_data = displayData;
    },
    search_items: (state, action) => {
      state.filtered_data =
        action.payload.trim().length !== 0
          ? state.data.filter((p: any) => {
              return (
                p.phone_number
                  .toLowerCase()
                  .includes(action.payload.toLowerCase()) ||
                p.name.toLowerCase().includes(action.payload.toLowerCase()) ||
                p.address.toLowerCase().includes(action.payload.toLowerCase())
              );
            })
          : state.data;
      state.current_page = 1;
    },
    set_items_per_page: (state, action) => {
      state.items_per_page = action.payload;
    },
    set_current_page: (state, action) => {
      state.current_page = action.payload;
    },
    setUser: (state, action) => {
      state.data = action.payload;
      state.filtered_data = action.payload;
    },
    adduser: (state, action) => {
      console.log(action.payload);
      state.data = [...state.data, action.payload];
    },
    updateuser: (state, action) => {
      let data = [...state.data];
      data[state.edit_id] = action.payload;
      console.log(data);
      state.data = [...data];
    },
    setEditId: (state, action) => {
      console.log(action.payload);
      state.edit_id = action.payload;
    },
    resetUserForm: (state) => {
      state.specific_user = {
        name: "",
        address: "",
        email: "",
        phone_number: "",
        password: "",
        office_id: 0,
        designation_id: 0,
        roles: [],
        active_status: 0,
      };
    },
    getSpecificuser: (state, action) => {
      state.specific_user = {
        ...action.payload,
        office_id: action.payload.office.id,
        designation_id: action.payload.designation.id,
        roles: action.payload.roles.map((p: any) => p.id),
      };
    },
    setSpecificuser: (state, action) => {
      console.log(action.payload);
      state.specific_user = {
        ...state.specific_user,
        [action.payload.name]: action.payload.value,
      };
    },
    deleteuser: (state, action) => {
      state.data = [
        ...state.data.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
      state.filtered_data = [
        ...state.filtered_data.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
    },
  },
});

export const {
  setUser,
  adduser,
  deleteuser,
  getSpecificuser,
  setSpecificuser,
  resetUserForm,
  updateuser,
  setEditId,
  set_current_page,
  set_items_per_page,
  search_items,
  show_data_users,
  set_loading_user
} = userSlice.actions;

export default userSlice.reducer;
