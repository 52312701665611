import ManageSifarisType from ".";
import Http from "../../../../utils/httpClients";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../hooks/hook";
import { useEffect } from "react";
import {
  setdocument,
  setSpecificdocument,
} from "../../../../redux/store/document";
const AddSifarisType = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  useEffect(() => {
    Http.GET("/documents/" + id, true)
      .then((res: any) => {
        let data = res.data.data;
        dispatch(
          setSpecificdocument({
            name: data.doc_name,
          })
        );
      })
      .catch((err: any) => [
        toast.error("Error occured while fetching sifaris data!"),
      ]);
  }, [id, dispatch]);
  const form_handler = (e: any) => {
    e.preventDefault();
    const form = new FormData(e.target);
    Http.PUT("/documents/" + id, { doc_name: form.get("doc_name") }, true)
      .then(async (res: any) => {
        await Http.GET("/documents", true).then((res) => {
          dispatch(setdocument(res.data.data));
        });
        toast.success("Document has been craeted!");
        navigate(-1);
      })
      .catch((err: any) => {
        toast.error("Error occured while updating document!");
      });
  };
  return (
    <>
      <ManageSifarisType
        form_handler={form_handler}
        update
        title="
        सम्पादन"
      />
    </>
  );
};

export default AddSifarisType;
