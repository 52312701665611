import { createSlice } from "@reduxjs/toolkit";
interface FamilyRelationState {
  data: Array<Object>;
  specific_family_relation: {
    relation_en: string;
    relation_np: string;
  };
}

const initialState: FamilyRelationState = {
  data: [],
  specific_family_relation: {
    relation_en: "",
    relation_np: "",
  },
};

export const familyRelationSlice = createSlice({
  name: "familyRelationSlice",
  initialState,
  reducers: {
    setrelation: (state, action) => {
      state.data = action.payload;
    },
    addrelation: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    setSpecificrelation: (state, action) => {
      state.specific_family_relation = {
        ...state.specific_family_relation,
        [action.payload.name]: action.payload.value,
      };
    },
    resetRelations: (state) => {
      state.specific_family_relation = {
        relation_en: "",
        relation_np: "",
      };
    },
    deleterelation: (state, action) => {
      state.data = [
        ...state.data.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
    },
  },
});

export const {
  setrelation,
  addrelation,
  deleterelation,
  setSpecificrelation,
  resetRelations,
} = familyRelationSlice.actions;

export default familyRelationSlice.reducer;
