import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
} from "recharts";
import { HiArrowUp } from "react-icons/hi";
import "./index.scss";
const SuppliersGraph = () => {
  const data = [
    {
      name: "Dec",
      amount: 20,
    },
    {
      name: "Jan",
      amount: 10,
    },
    {
      name: "Feb",
      amount: 20,
    },
    {
      name: "Mar",
      amount: 40,
    },
    {
      name: "Apr",
      amount: 50,
    },
    {
      name: "May",
      amount: 40,
    },
    {
      name: "Jun",
      amount: 20,
    },
    {
      name: "Jul",
      amount: 30,
    },
    {
      name: "Aug",
      amount: 50,
    },
    {
      name: "Sep",
      amount: 20,
    },
    {
      name: "Oct",
      amount: 50,
    },
    {
      name: "Nov",
      amount: 70,
    },
    {
      name: "Dec",
      amount: 40,
    },
  ];
  return (
    <div className="component_wrapper suppliers_graph_container">
      <div className="align_items_at_corner" style={{ paddingBottom: "28px" }}>
        <p className="title">Monitored Suppliers Graph</p>
        <div className="chart_widgets">
          <p className="widget">Month</p>
          <p className="active widget">Week</p>
        </div>
      </div>
      <div>
        <div className="price_rate_analysis">
          <div className="align_items">
            <p className="price">52,123</p>
            <p className="rate">
              <HiArrowUp />
              2.2%
            </p>
          </div>
          <p className="label">Average monitored suppliers per week</p>
        </div>
      </div>
      <div className="area_chart">
        <AreaChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="1.5%" stopColor="#FF5722" stopOpacity={0.1} />
              <stop
                offset="97%"
                stopColor="rgba(255, 87, 34, 0)"
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="amount"
            stroke="#FF5722"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </div>
    </div>
  );
};
export default SuppliersGraph;
