import InputField from "../../../component/input_container";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { setSpecificSifaris } from "../../../redux/store/sifaris_type";
import { CenterElement } from "../../../component/manage_layout/center_element";
import ManageLayout from "../../../component/manage_layout";
const ManageSifarisType = (props: {
  form_handler: any;
  title: string;
  update?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(
    (state: any) => state.sifaris_types.specific_sifaris_type
  );
  const changeHandler = (e: any) => {
    dispatch(
      setSpecificSifaris({ name: e.target.name, value: e.target.value })
    );
  };
  return (
    <CenterElement>
      <ManageLayout
        form_width="50%"
        form_handler={props.form_handler}
        page_title={"सिफारिस प्रकार" + props.title}
        update={props.update}
      >
        <InputField
          type="text"
          placeholder="सिफारिस प्रकार नाम"
          name="name"
          label="सिफारिस प्रकार"
          label_class="bold"
          value={data.name}
          required
          on_change={changeHandler}
        />
      </ManageLayout>
    </CenterElement>
  );
};
export default ManageSifarisType;
