import { useNavigate } from "react-router";
import Table from "../../../component/table_container/main_table";
import TableHeader from "../../../component/table_container/table_header";
import TableSubHeader from "../../../component/table_container/table_sub_header";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Http from "../../../utils/httpClients";
import Modal from "../../../component/modal";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { MdOutlineFamilyRestroom } from "react-icons/md";
import {
  deleterelation,
  setrelation,
} from "../../../redux/store/family_relations";
const FamilyRelation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const relation = useAppSelector((state: any) => state.family_relation.data);
  const [modal, setModal] = useState<Boolean>(false);
  const [delete_id, setDeleteId] = useState<Number>();
  const [search, setSearch] = useState("");
  useEffect(() => {
    Http.GET("/relations", true)
      .then((res) => {
        dispatch(setrelation(res.data.data));
      })
      .catch((err) => {
        toast.error("Error occured while fetching relations!", {
          position: "bottom-right",
        });
      });
  }, [dispatch]);
  const deleteRelation = async () => {
    await Http.DELETE("/relations/" + delete_id, true)
      .then((res: any) => {
        dispatch(deleterelation(delete_id));
        toast.success("Relation has been deleted", {
          position: "bottom-right",
        });
        setModal(false);
      })
      .catch((err: any) => {
        toast.error("Error occured while deleting relation", {
          position: "bottom-right",
        });
        setModal(false);
      });
  };
  const deleteHandler = (id: number) => {
    setDeleteId(id);
    setModal(true);
  };
  return (
    <>
      <Modal
        close_modal={() => setModal(false)}
        title="Relation"
        open={modal}
        action={deleteRelation}
      />
      <div className="table_wrapper">
        <div className="component_wrapper table_container">
          <TableHeader
            icon={<MdOutlineFamilyRestroom />}
            primary_button_name="
            नाता थप्नुहोस्"
            table_title="
            पारिवारिक सम्बन्ध"
            on_add={() => navigate("add")}
          />
          <TableSubHeader
            input_handler={(e: any) => setSearch(e.target.value)}
            placeholder="नाता खोज्नुहोस्"
          />
          <Table
            table_headings={[
              "s.n.",
              "Relation (EN)",
              "Relation (NP)",
              "actions",
            ]}
          >
            {relation
              ?.filter((p: any) => {
                return search.trim().length !== 0
                  ? p.relation_en
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                      p.relation_np.includes(search)
                  : p;
              })
              ?.map((p: any, i: any) => (
                <tr key={i}>
                  <td>{p.id}</td>
                  <td className="overflow_control">{p.relation_en}</td>
                  <td className="overflow_control">{p.relation_np}</td>
                  <td className="align_items table_icons">
                    <AiOutlineDelete
                      data-tooltip-id="view"
                      data-tooltip-content="Delete"
                      onClick={() => deleteHandler(p.id)}
                    />
                    <FiEdit2
                      data-tooltip-id="view"
                      data-tooltip-content="Update"
                      onClick={() => navigate("update/" + p.id)}
                    />
                  </td>
                </tr>
              ))}
            {/* {family_relations.length < 1 && (
              <tr>
                <td colSpan={3} className="no_match_text">
                  No Family relations has been added
                </td>
              </tr>
            )} */}
          </Table>
        </div>
      </div>
    </>
  );
};
export default FamilyRelation;
