import { useEffect } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import DesignationContainer from ".";
import { useAppDispatch } from "../../../../hooks/hook";
import {
  setSpecificdesignation,
  set_loading_designation,
  setdesignation,
} from "../../../../redux/store/designation";
import Http from "../../../../utils/httpClients";
const AddOfficeType = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setSpecificdesignation(""));
  }, [dispatch]);
  const form_handler = (e: any) => {
    e.preventDefault();
    const form = new FormData(e.target);
    Http.POST("/designations", { designation: form.get("designation") }, true)
      .then((res) => {
        dispatch(set_loading_designation(true));
        Http.GET("/designations", true).then((res) => {
          dispatch(setdesignation(res.data.data));
        });
        dispatch(set_loading_designation(false));
        toast.success("Designation has been added");
        navigate(-1);
      })
      .catch((err) => {
        dispatch(set_loading_designation(false));
        toast.error("Error occured while adding office type!");
      });
  };
  return <DesignationContainer title="सिर्जना" form_handler={form_handler} />;
};
export default AddOfficeType;
