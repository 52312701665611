import { createSlice } from "@reduxjs/toolkit";
interface sidebarState {
  sidebar_max: boolean;
}

const initialState: sidebarState = {
  sidebar_max: false,
};

export const sidebarSlice = createSlice({
  name: "sidebarSlice",
  initialState,
  reducers: {
    setSidebar: (state) => {
      state.sidebar_max = !state.sidebar_max;
    },
  },
});

export const { setSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
