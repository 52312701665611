import { Route, Routes } from "react-router";
import SifarisType from "..";
import AddSifarisType from "../manage_sifaris_type/add";
import UpdateSifarisType from "../manage_sifaris_type/update";
export const SifarisTypeRoutes = () => (
  <Routes>
    <Route
      path="/system_configuration/sifaris_type/add"
      element={<AddSifarisType />}
    />
    <Route
      path="/system_configuration/sifaris_type/update/:id"
      element={<UpdateSifarisType />}
    />
    <Route
      path="/system_configuration/sifaris_type"
      element={<SifarisType />}
    />
  </Routes>
);
