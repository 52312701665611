import ManageDocument from ".";
import Http from "../../../../utils/httpClients";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { useEffect } from "react";
import { setSpecificrelation } from "../../../../redux/store/family_relations";
const UpdateRelation = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const relations = useAppSelector(
    (state: any) => state.family_relation.specific_family_relation
  );
  useEffect(() => {
    Http.GET("/relations/" + id, true)
      .then((res) => {
        const data = res.data.data;
        dispatch(
          setSpecificrelation({ name: "relation_en", value: data.relation_en })
        );
        dispatch(
          setSpecificrelation({ name: "relation_np", value: data.relation_np })
        );
      })
      .catch((err) => {
        toast.error(
          "Error has occured while fetching previous relationd data!"
        );
      });
  },[dispatch]);
  const form_handler = (e: any) => {
    e.preventDefault();
    Http.PUT("/relations/" + id, relations, true)
      .then((res) => {
        toast.success("Relation has been updated!");
        navigate(-1);
      })
      .catch((err) => {
        toast.error(err.data.message);
      });
  };
  return (
    <>
      <ManageDocument
        update
        form_handler={form_handler}
        title="
        सम्पादन"
      />
    </>
  );
};

export default UpdateRelation;
