import Header from "../lang_english/header";
import Footer from "../lang_nepali/footer";
import Content from "./content";
import "../index.scss";
import SifarisDocLayout from "..";
const Template3 = () => {
  return (
    <SifarisDocLayout>
      <div className="template_content_gap">
        <Header />
        <Content />
      </div>
      <Footer />
    </SifarisDocLayout>
  );
};
export default Template3;
