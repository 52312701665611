import InputField from "../../../../component/input_container";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { useEffect, useState } from "react";
import Http from "../../../../utils/httpClients";
import {
  setrolespermission,
  setSpecificrole,
} from "../../../../redux/store/roles";
import { CenterElement } from "../../../../component/manage_layout/center_element";
import ReturnButton from "../../../../component/buttons/return_button";
import { SubmitButton } from "../../../../component/buttons/submit_button";
const ManageRoles = (props: {
  form_handler?: React.FormEventHandler;
  title?: string;
  data?: any;
  update?:boolean;
}) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state: any) => state.roles.specific_role);
  const data = useAppSelector((state: any) => state.roles.roles_permissions);
  const loading = useAppSelector((state: any) => state.roles.loading);
  const [all_select, setAllSelect] = useState<boolean>(false);
  useEffect(() => {
    Http.GET("/permissions/parent/children", true)
      .then((res) => {
        dispatch(setrolespermission(res.data.data));
        console.log(res);
      })
      .catch((err) => {
        toast.error("error occured while fetching permissions");
      });
  }, [dispatch]);
  const changeHandler = (name: string, value: string) => {
    dispatch(setSpecificrole({ name, value }));
  };
  const select_all = () => {
    let all_element = document.querySelectorAll("#checked") as any;
    setAllSelect(!all_select);
    if (all_element) {
      all_element.forEach(function (subSelect: any) {
        subSelect.checked = !all_select ? true : false;
      });
    }
    let arr = data.map((p: any) => p.childrens.map((p: any) => p.id));
    dispatch(
      setSpecificrole({ name: "permissions", value: [].concat(...arr) })
    );
  };
  const select_specific_check = (name: string, data: any) => {
    console.log(name);
    let all_element = document.querySelectorAll(`.checked${name}`) as any;
    if (all_element) {
      all_element.forEach(function (subSelect: any) {
        subSelect.checked = !subSelect.checked;
      });
    }
    dispatch(
      setSpecificrole({
        name: "permissions",
        value: data.map((p: any) => p.id),
      })
    );
  };
  return (
    <CenterElement>
      <div
        className="component_wrapper"
        style={{ width: "60%", margin: "auto" }}
      >
        <p
          className="title"
          style={{
            marginBottom: "2rem",
            fontSize: "2.1rem",
            fontWeight: "600",
          }}
        >
          भूमिका {props.title}
        </p>
        <form
          className="flex"
          style={{ flexDirection: "column", gap: "1rem" }}
          onSubmit={props.form_handler}
        >
          <InputField
            type="text"
            placeholder="
          भूमिका नाम"
            name="role"
            label="
          भूमिका "
            label_class="bold"
            value={role.role}
            required
            on_change={(e: any) => changeHandler("role", e.target.value)}
          />
          <p className="bold" style={{ fontSize: "1.1rem" }}>
            Permissions
          </p>
          <div className="flex center" style={{ gap: "5px" }}>
            <input type="checkbox" onClick={select_all} />
            Select All
          </div>
          {Object.keys(data).length > 0 &&
            data?.map((r: any) => (
              <>
                <div
                  className="flex"
                  style={{ alignItems: "flex-start", gap: "3rem" }}
                >
                  <div
                    className="flex center"
                    style={{ gap: "5px", minWidth: "max-content" }}
                  >
                    <input
                      type="checkbox"
                      id="checked"
                      onClick={() =>
                        select_specific_check(
                          r.permission_name.toLowerCase().split(" ").join(""),
                          r.childrens
                        )
                      }
                      checked={
                        props.data && props.data.permissions?.includes(r.id)
                      }
                    />
                    {r.permission_name}
                  </div>
                  <div
                    className="flex"
                    style={{ flexWrap: "wrap", gap: "1.5rem" }}
                  >
                    {r.childrens.map((p: any) => (
                      <div className="flex center" style={{ gap: "5px" }}>
                        <input
                          type="checkbox"
                          id="checked"
                          className={`checked${r.permission_name
                            .toLowerCase()
                            .split(" ")
                            .join("")}`}
                          value={p.id}
                          checked={
                            props.data && props.data.permissions?.includes(p.id)
                          }
                        />
                        {p.permission_name}
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ))}
          <div className="align_items" style={{ alignSelf: "flex-end" }}>
            <ReturnButton btn_name="रद्द गर्नुहोस्" />
            <SubmitButton loading={loading} update={props.update} />
          </div>
        </form>
      </div>
    </CenterElement>
  );
};
export default ManageRoles;
