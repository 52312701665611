import React from "react";
import Fields from "./component/fields";
import style from  './index.module.scss';
const Profile: React.FC = () => {
  return (
    <div className={style.user_profile_layout}>
      <Fields />
    </div>
  );
};

export default Profile;
