import "./index.scss";
const Signature = () => {
  return (
    <div className="signature">
      <div className="solid_line_border" />
      <p className="bold">Venture Four Technology</p>
      <p >Company</p>
    </div>
  );
};

export default Signature;
