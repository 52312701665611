import { Route, Routes } from "react-router";
import District from "../";
import AddDistrict from "../manage_jilla/add_jilla";
import UpdateDistrict from "../manage_jilla/update_jilla";
export const DistrictRoutes = () => (
  <Routes>
    <Route path="/federal_structure/district" element={<District />} />
    <Route path="/federal_structure/district/add" element={<AddDistrict />} />
    <Route
      path="/federal_structure/district/update/:id"
      element={<UpdateDistrict />}
    />
  </Routes>
);
