import ManageSifarisType from ".";
import Http from "../../../utils/httpClients";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { useEffect } from "react";
import { getSpecificSifaris } from "../../../redux/store/sifaris_type";
const AddSifarisType = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const data = useAppSelector(
    (state: any) => state.sifaris_types.specific_sifaris_type
  );
  useEffect(() => {
    Http.GET("/sifaristypes/" + id, true)
      .then((res) => {
        let data = res.data.data;
        dispatch(
          getSpecificSifaris({
            name: data.name,
            route: data.route,
            method: data.method,
          })
        );
      })
      .catch((err) => [
        toast.error("Error occured while fetching sifaris data!"),
      ]);
  }, [id, dispatch]);
  const form_handler = (e: any) => {
    e.preventDefault();
    const form = new FormData(e.target);
    Http.PUT("/sifaristypes/" + id, form, true)
      .then((res) => {
        navigate("/system_configuration/sifaris_type");
      })
      .catch((err) => {
        toast.error("Error occured while creating sifaris type!");
      });
  };
  return (
    <>
      <ManageSifarisType
        form_handler={form_handler}
        update
        title="
        सम्पादन"
      />
    </>
  );
};

export default AddSifarisType;
