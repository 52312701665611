import { NavLink } from "react-router-dom";
import "./index.scss";
import { useAppSelector } from "../../../../hooks/hook";
interface LinkLayoutProps {
  name: string;
  icon: JSX.Element;
  url: string;
  link_clicked?: any;
}
const LinkLayout = (props: LinkLayoutProps) => {
  const minimize_link = useAppSelector(
    (state: any) => state.sidebar.sidebar_max
  );
  return (
    <>
      <NavLink
        onClick={props.link_clicked}
        to={props.url}
        className={({ isActive }) =>
          isActive
            ? minimize_link
              ? "minimize_sidebar_link sidebar_link_active"
              : "sidebar_link sidebar_link_active"
            : minimize_link
            ? "minimize_sidebar_link"
            : "sidebar_link"
        }
      >
        {props.icon}
        {!minimize_link ? <p>{props.name}</p> : null}
      </NavLink>
    </>
  );
};
export default LinkLayout;
