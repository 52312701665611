import React from "react";
import "./style.scss";

function NavBar() {
  return (
   <div></div>
  );
}

export default NavBar;
{/* <div className=" navTop">
{/* <Container className="contains">
  <div className="contains1">
    <div className="d-flex align-items-center">
      <img
        src="https://i.pinimg.com/564x/e1/c1/d7/e1c1d76acbb51846b915e540af5ae879.jpg"
        style={{ height: "59px", width: "69px" }}
        alt="nepallogo"
      />
    </div>

    <div className="lh-sm ps-2">
      <span
        className=""
        style={{
          fontSize: "14px",
          color: "#EA4335",
          fontWeight: 700,
        }}
      >
        नेपाल सरकार, उद्योग, वाणिज्य तथा आपूर्ति मन्त्रालय
      </span>
      <br />
      <span className="" style={{ fontSize: "14px" }}>
        वाणिज्य आपूर्ति र उपभोक्ता संरक्षण विभाग
      </span>
      <br />
      <span className="" style={{ fontSize: "14px" }}>
        एकिकृत बजार अनुगमन प्रणाली
      </span>
    </div>
  </div>
  {/* <div>
<div>
  <Navbar bg="" expand="lg">
    <Container>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          <Nav.Link href="">Navigation</Nav.Link>
          <Nav.Link href="">Navigation</Nav.Link>
          <Nav.Link href="">Navigation</Nav.Link>
          <Nav.Link href="">Navigation</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
</div>
</div> */}
//   <div className="contains2">
//     <div className="lang">
//       <FiGlobe />
//       <span className="px-1">En</span>
//       <FiChevronDown />
//     </div>
//     <div className="profile ms-1">
//       <div>
//         <img
//           src={`https://ui-avatars.com/api/?background=EFEFEF&color=000000&name=JennyWilson`}
//           alt="profile"
//           className="profileImage"
//         />
//       </div>
//       <div className="ms-1">
//         <span className="profileName">Jenny Wilson</span>
//         <br />
//         <span className="profileMail">jen.wilson@example.com</span>
//       </div>
//     </div>
//   </div>
// </Container>
// </div> */}