import DynamicForm from "../dynamic_form";
import Http from "../../utils/httpClients";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import {
  setSifarisForms,
  updateSifarisForm,
} from "../../redux/store/sifaris_type";
const Form = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const form_title = useAppSelector(
    (state: any) => state.sifaris_types.form_title
  );
  useEffect(() => {
    Http.GET("/sifaristypes/" + id, true)
      .then((res) => {
        let data = [...res.data.data.sifarisforms];
        dispatch(setSifarisForms(res.data.data));
        dispatch(
          updateSifarisForm(
            data.sort((a: any, b: any) =>
              Number(a.field_order) > Number(b.field_order) ? 1 : -1
            )
          )
        );
      })
      .catch((err) => {
        toast.error("Error occured while fetching data!");
      });
  }, [id, dispatch]);
  const form_submit = (data: any) => {
    Http.PUT(
      "/sifarisforms/" + id,
      data.map((p: any) => {
        return { ...p, form_title, sifaristype_id: id };
      }),
      true
    )
      .then((res) => {
        toast.success("Form has been updated!");
        navigate("/system_configuration/sifaris_type/form/" + id, {
          replace: true,
        });
      })
      .catch((err) => {
        toast.error("Error while updating form!");
      });
  };
  const delete_handler = (p: any) => {
    Http.DELETE("/sifarisforms/" + p.id, true)
      .then((res) => {
        toast.success("Input field has been deleted");
        dispatch(updateSifarisForm([...p.data]));
      })
      .catch((err) => {
        toast.error("Error occured while deleting input fields!");
      });
  };
  return (
    <>
      <DynamicForm
        title="फारम"
        status="
      सम्पादन"
        on_submit={form_submit}
        form_title={form_title}
        status_en="Update"
        table_headings="फारम आउटपुट"
        delete_handler={delete_handler}
        current_status="Update"
      />
    </>
  );
};
export default Form;
