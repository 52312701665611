import HeaderLayout from "../../layouts/header";
interface Props {
  ward_no: String | Number;
  address: String;
  province: String;
}
const Header = (props: { children?: JSX.Element | JSX.Element[] }) => {
  let ward_no = "१";
  let address = "पुतलीसडक";
  let province = "बागमती";
  let title_1 = "काठमाडौँ महानगरपालिका";
  let title_2 = `${ward_no} नं वडा कार्यालय`;
  let title_3 = `${address}, काठमाडौँ`;
  let title_4 = `${province} प्रदेश, नेपाल`;
  return (
    <HeaderLayout
      title_1={title_1}
      title_2={title_2}
      title_3={title_3}
      title_4={title_4}
      date="२०७८/०६/१५"
      sub_title_extra="पत्र संख्या:"
      sub_title_2="मिति:"
      sub_title_1="चलानी नं.:"
      children={props.children}
    />
  );
};
export default Header;
