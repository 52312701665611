import InputField from "../../../../component/input_container";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { CenterElement } from "../../../../component/manage_layout/center_element";
import { setSpecificrelation } from "../../../../redux/store/family_relations";
import ManageLayout from "../../../../component/manage_layout";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
const FamilyRelationContainer = (props: {
  form_handler: any;
  title: string;
  update?:boolean;
}) => {
  const dispatch = useAppDispatch();
  const changeHandler = (e: any) => {
    dispatch(
      setSpecificrelation({ name: e.target.name, value: e.target.value })
    );
  };
  const data = useAppSelector(
    (state: any) => state.family_relation.specific_family_relation
  );
  return (
    <CenterElement>
      <ManageLayout
        form_handler={props.form_handler}
        page_title={"नाता " + props.title}
        form_width="50%"
        update={props.update}
        icon={<AiOutlineUsergroupAdd />
      }
      >
        <div
          className="flex"
          style={{ flexDirection: "column", width: "100%", gap: "1rem" }}
        >
          <InputField
            type="text"
            placeholder="
          Relation"
            name="relation_en"
            label="
            Relation "
            label_class="bold"
            required
            on_change={changeHandler}
            value={data?.relation_en}
          />
          <InputField
            type="text"
            placeholder="
          नाता"
            name="relation_np"
            label="
            नाता "
            label_class="bold"
            required
            on_change={changeHandler}
            value={data?.relation_np}
          />
        </div>
      </ManageLayout>
    </CenterElement>
  );
};
export default FamilyRelationContainer;
