import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/hook";
import Http from "../../../../../utils/httpClients";
import { useNavigate, useParams } from "react-router";
import ManageRoles from "..";
import { useEffect } from "react";
import {
  getSpecificrole,
  setrolespermission,
  setrole,
} from "../../../../../redux/store/roles";
import {
  get_role,
  get_roles,
  update_role,
} from "../../../../../utils/api/user_configuration/roles";
const UpdateRoles = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const role = useAppSelector((state: any) => state.roles.specific_role);
  useEffect(() => {
    if (id !== undefined) {
      get_role(id)
        .then((res: any) => {
          console.log(res.data.data);
          let data = res.data.data;
          get_roles().then((res: any) => {
            dispatch(setrole(res.data.data));
          });
          dispatch(
            getSpecificrole({
              role: data.role,
              permissions: data.permissions.map((p: any) => p.id),
            })
          );
        })
        .catch((err) => {
          toast.error("Error occured while fetching roles data!");
          console.log(err);
        });
    }
  }, [dispatch]);
  const form_handler = (e: any) => {
    e.preventDefault();
    if (id !== undefined) {
      update_role(id, role)
        .then((res) => {
          navigate("/user_configuration/role");
          Http.GET("/permissions/parent/children", true).then((res) => {
            dispatch(setrolespermission(res.data.data));
            console.log(res);
          });
          toast.success("Roles has been added!");
        })
        .catch((err) => {
          toast.error("Error occured while sending roles!");
        });
    }
  };
  return (
    <ManageRoles
      form_handler={form_handler}
      data={role}
      update
      title="
  सम्पादन"
    />
  );
};
export default UpdateRoles;
