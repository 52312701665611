import { Route, Routes } from "react-router";
import Document from "..";
import AddDocument from "../component/add";
import UpdateDocument from "../component/update";
export const DocumentRoutes = () => {
  return (
    <Routes>
      <Route path="/system_configuration/document" element={<Document />} />
      <Route
        path="/system_configuration/document/add"
        element={<AddDocument />}
      />{" "}
      <Route
        path="/system_configuration/document/update/:id"
        element={<UpdateDocument />}
      />
    </Routes>
  );
};
