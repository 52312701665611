import styles from "./index.module.scss";
import { IoWarningOutline } from "react-icons/io5";
import Backdrop from "../backdrop/black_backdrop";
import { AnimatePresence, motion } from "framer-motion";
interface ModalProps {
  open: Boolean;
  close_modal: React.MouseEventHandler;
  action?: React.MouseEventHandler;
  title?: string;
  btn?: boolean;
}

const Modal = (props: ModalProps) => {
  return (
    <AnimatePresence>
      <motion.div
        style={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <Backdrop open={props.open}>
          <div className={styles.modal}>
            <IoWarningOutline />
            <div className={styles.msg}>
              <p className={styles.imp_txt}>
                Delete{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {props.title}
                </span>{" "}
                Information?
              </p>
              <p className={styles.note_txt}>
                Are you sure you want to delete this {props.title}? This action
                cannot be undone.
              </p>
            </div>
            {!props.btn && (
              <div className={styles.align_button}>
                <button
                  className="secondary_button"
                  onClick={props.close_modal}
                >
                  Cancel
                </button>
                <button className="primary_button" onClick={props.action}>
                  Delete
                </button>
              </div>
            )}
          </div>
        </Backdrop>
      </motion.div>
    </AnimatePresence>
  );
};
export default Modal;
