import style from "../../index.module.scss";
import InputField from "../../../dynamic_form/input_field";
import SelectInput from "../../../dynamic_form/input_field/select_input";
import { useState } from "react";
import { useEffect } from "react";
import Http from "../../../../utils/httpClients";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import {
  clearTempAddressData,
  setSpecificpublicuser,
  transferAddressData,
} from "../../../../redux/store/public_users";
import { Checkbox, FormControlLabel } from "@mui/material";
interface FieldProps {
  children: JSX.Element | JSX.Element[];
}
const Fields = (props: FieldProps) => {
  return (
    <div className="flex center" style={{ gap: "1rem" }}>
      {props.children}
    </div>
  );
};
const Container = (props: {
  children: JSX.Element | JSX.Element[];
  title: string;
}) => {
  return (
    <fieldset
      style={{
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <legend
        className="bold"
        style={{
          color: "#1f60ba",
          fontSize: "1.2rem",
          padding: "0 8px",
        }}
      >
        {props.title}
      </legend>
      {props.children}
    </fieldset>
  );
};
const AccountSettingsContainer = (props: {
  form_submit?: any;
  btn_title?: string;
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const public_info = useAppSelector(
    (state: any) => state.public_user.specific_public_user
  );
  const [province_data, setProvinceData] = useState<Array<Object>>();
  const [district_data, setDistrictData] = useState<Array<Object>>();
  const [local_level_data, setLocalLevelData] = useState<Array<Object>>();
  const [pdistrict_data, setPDistrictData] = useState<Array<Object>>();
  const [plocal_level_data, setPLocalLevelData] = useState<Array<Object>>();
  const [tdistrict_data, setTDistrictData] = useState<Array<Object>>();
  const [tlocal_level_data, setTLocalLevelData] = useState<Array<Object>>();
  useEffect(() => {
    Http.GET("/llevels", true).then((res) => {
      setLocalLevelData(res.data.data);
      setPLocalLevelData(res.data.data);
      setTLocalLevelData(res.data.data);
    });
    Http.GET("/provinces", true).then((res) => {
      setProvinceData(res.data.data);
    });
    Http.GET("/districts", true).then((res) => {
      setPDistrictData(res.data.data);
      setDistrictData(res.data.data);
      setTDistrictData(res.data.data);
    });
  }, [dispatch, id]);

  const pprovince_handler = (option: any) => {
    setPDistrictData(
      district_data?.filter((p: any) => p.province?.id === option.value)
    );
    dispatch(
      setSpecificpublicuser({
        name: "permanent_province_id",
        value: option.value,
      })
    );
  };
  const pdistrict_handler = (option: any) => {
    setPLocalLevelData(
      local_level_data?.filter((p: any) => p.district?.id === option.value)
    );
    dispatch(
      setSpecificpublicuser({
        name: "permanent_district_id",
        value: option.value,
      })
    );
  };
  const tprovince_handler = (option: any) => {
    setTDistrictData(
      district_data?.filter((p: any) => p.province?.id === option.value)
    );
    dispatch(
      setSpecificpublicuser({
        name: "temporary_province_id",
        value: option.value,
      })
    );
  };
  const tdistrict_handler = (option: any) => {
    setTLocalLevelData(
      local_level_data?.filter((p: any) => p.district.id === option.value)
    );
    dispatch(
      setSpecificpublicuser({
        name: "temporary_district_id",
        value: option.value,
      })
    );
  };
  const input_handler = (e: any) => {
    dispatch(
      setSpecificpublicuser({ name: [e.target.name], value: e.target.value })
    );
  };
  const select_handler = (e: any, name: string) => {
    dispatch(setSpecificpublicuser({ name, value: e.value }));
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.checked){
      dispatch(transferAddressData());
    }else{
      dispatch(clearTempAddressData());
    }
  };
  return (
    <>
      <form autoComplete="off" onSubmit={props.form_submit}>
        <div style={{ padding: "2rem 1rem" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              padding: "1rem 1rem",
            }}
          >
            <Container title="Basic Information">
              <Fields>
                <InputField
                  type="email"
                  label="Email"
                  label_class="bold"
                  name="email_address"
                  placeholder="Enter email address"
                  value={public_info?.email_address}
                  on_change={input_handler}
                  required
                />
                <InputField
                  type="number"
                  label="Mobile Number"
                  required
                  label_class="bold"
                  name="mobile_no"
                  placeholder="Enter mobile number"
                  value={public_info?.mobile_no}
                  on_change={input_handler}
                />
              </Fields>
              <Fields>
                <InputField
                  type="number"
                  label="Landline No."
                  // required
                  label_class="bold"
                  name="landline_no"
                  placeholder="Enter landline number"
                  value={public_info?.landline_no}
                  on_change={input_handler}
                />
                <InputField
                  type="number"
                  label="Tax Payer No."
                  required
                  label_class="bold"
                  name="kardata_number"
                  placeholder="Enter tax payer number"
                  value={public_info?.kardata_number}
                  on_change={input_handler}
                />
              </Fields>
            </Container>
            <Container title="Permanent Address">
              <Fields>
                <SelectInput
                  required
                  label="Province"
                  label_class="bold"
                  name="permanent_province_id"
                  placeholder="Select province level"
                  options={province_data?.map((p: any) => {
                    return {
                      label: p.province_en,
                      value: p.id,
                    };
                  })}
                  on_change={pprovince_handler}
                  value={province_data
                    ?.map((p: any) => {
                      return {
                        label: p.province_en,
                        value: p.id,
                      };
                    })
                    ?.filter(
                      (p: any) =>
                        p.value.toString() ===
                        public_info?.permanent_province_id?.toString()
                    )}
                />
                <SelectInput
                  label="District"
                  label_class="bold"
                  name="permanent_district_id"
                  on_change={pdistrict_handler}
                  options={pdistrict_data?.map((p: any) => {
                    return {
                      label: p.district_en,
                      value: p.id,
                    };
                  })}
                  placeholder="Select district level"
                  value={pdistrict_data
                    ?.map((p: any) => {
                      return {
                        label: p.district_en,
                        value: p.id,
                      };
                    })
                    ?.filter(
                      (p: any) =>
                        p.value.toString() ===
                        public_info?.permanent_district_id?.toString()
                    )}
                />
              </Fields>
              <Fields>
                <SelectInput
                  required
                  label="Local Level"
                  label_class="bold"
                  name="permanent_locallevel_id"
                  options={plocal_level_data?.map((p: any) => {
                    return {
                      label: p.lgname_en,
                      value: p.id,
                    };
                  })}
                  value={plocal_level_data
                    ?.map((p: any) => {
                      return {
                        label: p.lgname_en,
                        value: p.id,
                      };
                    })
                    ?.filter(
                      (p: any) =>
                        p.value.toString() ===
                        public_info?.permanent_locallevel_id?.toString()
                    )}
                  placeholder="Select local level"
                  on_change={(e: any) =>
                    select_handler(e, "permanent_locallevel_id")
                  }
                />
                <InputField
                  type="text"
                  label="Ward No."
                  required
                  name="permanent_ward"
                  label_class="bold"
                  placeholder="Ward No"
                  value={public_info?.permanent_ward}
                  on_change={input_handler}
                />
              </Fields>
              <div style={{ width: "49%" }}>
                <InputField
                  type="text"
                  label="Tole"
                  required
                  name="permanent_tole"
                  label_class="bold"
                  placeholder="Tole"
                  value={public_info?.permanent_tole}
                  on_change={input_handler}
                />
              </div>
            </Container>
            <Container title="Temporary Address">
              <FormControlLabel
                label="Same as permanent address"
                control={<Checkbox size="small" onChange={handleChange} />}
              />
              <Fields>
                <SelectInput
                  required
                  label_class="bold"
                  label="Province"
                  name="temporary_province_id"
                  placeholder="Select province level"
                  on_change={tprovince_handler}
                  options={province_data?.map((p: any) => {
                    return {
                      label: p.province_en,
                      value: p.id,
                    };
                  })}
                  value={province_data
                    ?.map((p: any) => {
                      return {
                        label: p.province_en,
                        value: p.id,
                      };
                    })
                    ?.filter(
                      (p: any) =>
                        p.value.toString() ===
                        public_info?.temporary_province_id?.toString()
                    )}
                />
                <SelectInput
                  required
                  label_class="bold"
                  label="District"
                  name="temporary_district_id"
                  placeholder="Select district level"
                  on_change={tdistrict_handler}
                  options={tdistrict_data?.map((p: any) => {
                    return {
                      label: p.district_en,
                      value: p.id,
                    };
                  })}
                  value={tdistrict_data
                    ?.map((p: any) => {
                      return {
                        label: p.district_en,
                        value: p.id,
                      };
                    })
                    ?.filter(
                      (p: any) =>
                        p.value.toString() ===
                        public_info?.temporary_district_id?.toString()
                    )}
                />
              </Fields>

              <Fields>
                <SelectInput
                  required
                  label_class="bold"
                  label="Local Level"
                  name="temporary_locallevel_id"
                  placeholder="Select local level"
                  options={tlocal_level_data?.map((p: any) => {
                    return {
                      label: p.lgname_en,
                      value: p.id,
                    };
                  })}
                  value={tlocal_level_data
                    ?.map((p: any) => {
                      return {
                        label: p.lgname_en,
                        value: p.id,
                      };
                    })
                    ?.filter(
                      (p: any) =>
                        p.value.toString() ===
                        public_info?.temporary_locallevel_id?.toString()
                    )}
                  on_change={(e: any) =>
                    select_handler(e, "temporary_locallevel_id")
                  }
                />
                <InputField
                  type="text"
                  label="Ward"
                  required
                  name="temporary_ward"
                  label_class="bold"
                  placeholder="Ward No"
                  value={public_info?.temporary_ward}
                  on_change={input_handler}
                />
              </Fields>
              <div style={{ width: "49%" }}>
                <InputField
                  type="text"
                  label="Tole"
                  required
                  name="temporary_tole"
                  label_class="bold"
                  placeholder="Tole"
                  value={public_info?.temporary_tole}
                  on_change={input_handler}
                />
              </div>
            </Container>
          </div>
        </div>
        <div className={style.buttons}>
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="secondary_button"
          >
            Cancel
          </button>
          <button type="submit" className="primary_button">
            {props.btn_title} & Next
          </button>
        </div>
      </form>
    </>
  );
};
export default AccountSettingsContainer;
