import { toast } from "react-toastify";
import UserContainer from "..";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/hook";
import {
  adduser,
  resetUserForm,
  set_loading_user,
} from "../../../../../redux/store/user";
import { useEffect } from "react";
import { FaUserPlus } from "react-icons/fa";
import { create_user } from "../../../../../utils/api/user_configuration/user";
const AddUser = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const specificuser = useAppSelector((state: any) => state.user.specific_user);
  useEffect(() => {
    dispatch(resetUserForm());
  }, [dispatch]);
  const form_handler = async (e: any) => {
    e.preventDefault();
    dispatch(set_loading_user(true));
    create_user(specificuser)
      .then((res) => {
        toast.success("User has been created!");
        console.log(res);
        dispatch(adduser(res.data.user));
        navigate(-1);
        dispatch(set_loading_user(false));
      })
      .catch((err) => {
        dispatch(set_loading_user(false));
        toast.error("Error occured while creating user");
      });
  };
  return (
    <>
      <UserContainer
        title="थप्नुहोस्"
        form_handler={form_handler}
        password_field
        icon={<FaUserPlus />}
      />
    </>
  );
};
export default AddUser;
