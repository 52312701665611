import { createSlice } from "@reduxjs/toolkit";
interface DistrictState {
  data: Array<Object>;
  specific_district: {
    district_en: string;
    district_np: string;
    province_id: number;
  };
  show_data: Array<Object>;
  items_per_page: any;
  current_page: any;
  filtered_data: Array<Object>;
  loading: boolean;
}

const initialState: DistrictState = {
  data: [],
  specific_district: {
    district_en: "",
    district_np: "",
    province_id: 0,
  },
  show_data: [],
  items_per_page: 10,
  current_page: 1,
  filtered_data: [],
  loading: false,
};

export const districtSlice = createSlice({
  name: "districtSlice",
  initialState,
  reducers: {
    set_loading_district: (state, action) => {
      state.loading = action.payload;
    },
    show_data_district: (state) => {
      const startIndex = (state.current_page - 1) * state.items_per_page;
      const endIndex = startIndex + state.items_per_page;
      const displayData = state.filtered_data && state.filtered_data?.slice(startIndex, endIndex);
      state.show_data = displayData;
    },
    setDistrict: (state, action) => {
      state.data = action.payload;
      state.filtered_data = action.payload;
    },
    addDistrict: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    search_items: (state, action) => {
      state.filtered_data =
        action.payload.trim().length !== 0
          ? state.data.filter((p: any) => {
              return (
                p.province?.province_en
                  .toLowerCase()
                  .includes(action.payload.toLowerCase()) ||
                p.district_en
                  .toLowerCase()
                  .includes(action.payload.toLowerCase()) ||
                p.district_np
                  .toLowerCase()
                  .includes(action.payload.toLowerCase())
              );
            })
          : state.data;
      state.current_page = 1;
    },
    set_items_per_page: (state, action) => {
      state.items_per_page = action.payload;
    },
    set_current_page: (state, action) => {
      state.current_page = action.payload;
    },
    getSpecificDistrict: (state, action) => {
      state.specific_district = {
        ...state,
        district_en: action.payload.district_en,
        district_np: action.payload.district_np,
        province_id: action.payload.province_id,
      };
    },
    setSpecificDistrict: (state, action) => {
      state.specific_district = {
        ...state.specific_district,
        [action.payload.name]: action.payload.value,
      };
    },
    deleteDistrict: (state, action) => {
      state.data = [
        ...state.data.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
      state.filtered_data = [
        ...state.filtered_data.filter(
          (p: any) => p.id.toString() !== action.payload.toString()
        ),
      ];
    },
  },
});

export const {
  setDistrict,
  addDistrict,
  deleteDistrict,
  getSpecificDistrict,
  setSpecificDistrict,
  show_data_district,
  search_items,
  set_current_page,
  set_items_per_page,
  set_loading_district
} = districtSlice.actions;

export default districtSlice.reducer;
