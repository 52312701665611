import { Route, Routes } from "react-router";
import OfficeType from "..";
import AddOfficeType from "../add_office_type";
import UpdateOfficeType from "../update_office_type";
const OfficeTypeRoutes = () => {
  return (
    <Routes>
      <Route
        path="/system_configuration/office_type"
        element={<OfficeType />}
      />
      <Route
        path="/system_configuration/office_type/add"
        element={<AddOfficeType />}
      />
      <Route
        path="/system_configuration/office_type/update/:id"
        element={<UpdateOfficeType />}
      />
    </Routes>
  );
};
export default OfficeTypeRoutes;
