import DynamicForm from "../dynamic_form";
import Http from "../../utils/httpClients";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import {
  setSifarisForms,
  updateSifarisForm,
} from "../../redux/store/sifaris_type";
import { resetSearchData } from "../../redux/store/add_sifaris";
const Table = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const table_title = useAppSelector(
    (state: any) => state.sifaris_types.table_title
  );
  const table_header = useAppSelector(
    (state: any) => state.sifaris_types.table_header
  );
  const forms = useAppSelector(
    (state: any) => state.sifaris_types.forms.sifaristables
  );
  console.log(forms);
  useEffect(() => {
    dispatch(resetSearchData());
    Http.GET("/sifaristypes/" + id, true)
      .then((res) => {
        console.log(res.data.data);
        dispatch(setSifarisForms(res.data.data));
        dispatch(updateSifarisForm(res.data.data.sifaristables));
      })
      .catch((err) => {
        toast.error("Error occured while fetching data!");
      });
  }, [id, dispatch]);
  const table_submit = (data: any) => {
    Http.PUT(
      "/sifaristables/" + id,
      data.map((p: any) => {
        return { ...p, sifairis_type: id };
      }),
      true
    )
      .then((res) => {
        console.log(res);
        toast.success("Table has been created!");
        navigate("/system_configuration/sifaris_type/table/" + id, {
          replace: true,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error while upading table fields!");
      });
  };
  const delete_handler = (p: any) => {
    console.log(p.data, p.id);
    Http.DELETE("/sifaristables/" + p.id, true)
      .then((res) => {
        toast.success("Input field has been deleted");
        dispatch(updateSifarisForm([...p.data]));
      })
      .catch((err) => {
        toast.error("Error occured while deleting input fields!");
      });
  };
  return (
    <>
      <DynamicForm
        form_title={table_title}
        status="सम्पादन"
        table_headings={table_header}
        status_en="Update"
        current_status="Update"
        table
        title="
      तालिका"
        delete_handler={delete_handler}
        forms={forms}
        on_submit={table_submit}
      />
    </>
  );
};
export default Table;
