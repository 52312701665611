import InputField from "../../../component/input_container";
import style from "../../dynamic_form/index.module.scss";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  setSifarisForms,
  setSifarisFormTitle,
} from "../../../redux/store/sifaris_type";
import { useEffect } from "react";
import Http from "../../../utils/httpClients";
import { useParams } from "react-router";
import { CenterElement } from "../../../component/manage_layout/center_element";
const FormTitleContainer = (props: { title: string; form_handler: any }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const form_title = useAppSelector(
    (state: any) => state.sifaris_types.form_title
  );
  const forms = useAppSelector(
    (state: any) => state.sifaris_types?.forms?.sifarisforms
  );
  useEffect(() => {
    if (forms && Object.keys(forms).length !== 0) {
      dispatch(setSifarisFormTitle(forms[0].form_title));
    } else {
      Http.GET("/sifaristypes/" + id, true).then((res) => {
        let data = res.data.data;
        dispatch(setSifarisForms(data));
        dispatch(setSifarisFormTitle(data.sifarisforms[0]?.form_title));
      });
    }
  }, [dispatch, id]);
  const changeHandler = (e: any) => {
    dispatch(setSifarisFormTitle(e.target.value));
  };
  return (
    <CenterElement>
      <div
        className="component_wrapper"
        style={{ width: "50%", margin: "auto" }}
      >
        <p className={style.title} style={{ marginBottom: "2rem" }}>
          <span style={{ textTransform: "capitalize" }}>{props.title} </span>
          Form Title
        </p>
        <form onSubmit={props.form_handler} className={style.form_style}>
          <InputField
            type="text"
            placeholder="Enter form title"
            name="form_title"
            label="Form Title"
            label_class="bold"
            value={form_title}
            required
            on_change={changeHandler}
          />
          <button
            className="primary_button"
            style={{
              fontSize: "17px",
              fontWeight: "500",
              padding: "10px 1rem",
            }}
          >
            {props.title} & Next
          </button>
        </form>
      </div>
    </CenterElement>
  );
};

export default FormTitleContainer;
