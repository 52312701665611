import { Routes, Route } from "react-router";
import User from "..";
import ViewUser from "../user_manage/view_user";
import AddUser from "../user_manage/add_user";
import UpdateUser from "../user_manage/update_user";
export const UserRoutes = () => (
  <Routes>
    <Route path="/user_configuration/user/view/:id" element={<ViewUser />} />
    <Route path="/user_configuration/user" element={<User />} />
    <Route path="/user_configuration/user/add" element={<AddUser />} />
    <Route
      path="/user_configuration/user/update/:id"
      element={<UpdateUser />}
    />
  </Routes>
);
