import React, { useEffect, useState } from "react";
import Http from "../../../utils/httpClients";
import "./index.scss";
import Template from "../../home";
import InputField from "../../../component/input_container";
import { MdOutlineFileCopy } from "react-icons/md";

const SifarisAdd = () => {
  const [sifarisType, setSifarisType] = useState<any>();
  const [search, setSearch] = useState<string>("");
  useEffect(() => {
    Http.GET("/sifaristypes", true).then((res) => {
      setSifarisType(res.data?.data);
    });
  }, []);
  const on_change = (e: any) => {
    setSearch(e.target.value);
  };
  return (
    <div
      className="component_wrapper"
      style={{ border: "none", backgroundColor: "transparent" }}
    >
      <div className="component_wrapper table_container">
        <div className="inline_table_header">
          <div className="table_title align_items">
            <MdOutlineFileCopy />
            सिफारिस प्रकार
            <p className="small_text blue_border_box">
              {sifarisType && sifarisType.length}
            </p>
          </div>
          <div style={{ width: "40%" }}>
            <InputField
              type="text"
              on_change={on_change}
              placeholder="खोज सिफारिस प्रकार..."
            />
          </div>
        </div>
        <div className="sifaris_types_container sifarisOptions">
          {sifarisType &&
            sifarisType
              .filter((p: any) =>
                search.trim().length !== 0
                  ? p.name.includes(search.toLowerCase())
                  : p
              )
              .map((p: any) => (
                <Template
                  key={p.name}
                  path={"/sifaris/add/template/" + p.id}
                  title={p.name}
                />
              ))}
        </div>
      </div>
    </div>
  );
};

export default SifarisAdd;
