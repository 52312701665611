import InputField from "../../../../dynamic_form/input_field";
import Http from "../../../../../utils/httpClients";
import { useState, useRef, useEffect } from "react";
import style from "../../../index.module.scss";
import { BsCreditCard2Front } from "react-icons/bs";
import { toast } from "react-toastify";
import { fileSizeHandler } from "../../../../../utils/utitlity";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useAppSelector } from "../../../../../hooks/hook";
import {
  getSpecificpublicuser,
  resetFamilyData,
  setFamilyData,
} from "../../../../../redux/store/public_users";
import { check_tab } from "../../../../../redux/store/tabs";
import SelectInput from "../../../../dynamic_form/input_field/select_input";
interface FieldProps {
  children: JSX.Element | JSX.Element[];
}
const Fields = (props: FieldProps) => {
  return (
    <div className="flex center" style={{ gap: "1rem" }}>
      {props.children}
    </div>
  );
};
const Container = (props: {
  children: JSX.Element | JSX.Element[];
  title: string;
}) => {
  return (
    <fieldset
      style={{
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <legend
        className="bold"
        style={{
          color: "#1f60ba",
          fontSize: "1.2rem",
          padding: "0 8px",
        }}
      >
        {props.title}
      </legend>
      {props.children}
    </fieldset>
  );
};
const Form = (props: { close_form: () => void }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const fileRef = useRef<any>();
  const CtzFrontRef = useRef<any>();
  const CtzBackRef = useRef<any>();
  const [user_img, setUserImg] = useState<any>();
  const [gender, setGender] = useState<any>();
  const edit_id = useAppSelector(
    (state) => state.public_user.family_info_edit_id
  );
  const form_status = useAppSelector((state) => state.public_user.modal_status);
  const family_data = useAppSelector((state) => state.public_user.family_data);
  useEffect(() => {
    Http.GET("/genders", true).then((res) => {
      setGender(
        res.data.data.map((p: any) => {
          return { label: p.gender_en, value: p.id };
        })
      );
    });
    let imageBG = document.getElementById("user_img") as any;
    let cc_front = document.getElementById("cc_front") as any;
    let cc_back = document.getElementById("cc_back") as any;
    if (form_status === "update") {
      imageBG.style.backgroundImage =
      process.env.REACT_APP_BASE_URL?.replace('api','storage') + "/"  +
        family_data.person_photo_path +
        ")";
      cc_front.style.backgroundImage =
      process.env.REACT_APP_BASE_URL?.replace('api','storage') + "/"  +
        family_data.ctz_front_path +
        ")";
      cc_back.style.backgroundImage =
      process.env.REACT_APP_BASE_URL?.replace('api','storage') + "/"  +
        family_data.ctz_back_path +
        ")";
    } else {
      imageBG.style.backgroundImage = "";
      cc_front.style.backgroundImage = "";
      cc_back.style.backgroundImage = "";
    }
  }, []);
  const change_user_img = (e: any) => {
    let file = e.target.files[0];
    dispatch(
      setFamilyData({
        name: "profilepicture_attachment",
        value: e.target.files[0],
      })
    );
    let imageBG = document.getElementById("user_img") as any;
    let reader = new FileReader();
    if (imageBG !== null) {
      reader.onloadend = function () {
        imageBG.style.backgroundImage = "url(" + reader.result + ")";
      };
    }
    if (file) reader.readAsDataURL(file);
    else imageBG.style.backgroundImage = "";
  };
  const form_submit = (e: any) => {
    e.preventDefault();
    if (form_status === "create") {
      Http.POST(
        "/familymembers",
        { familyprofile_id: id, ...family_data },
        true,
        true
      )
        .then(async (res) => {
          toast.success("Family members has been added", {
            position: "bottom-right",
          });
          props.close_form();
          dispatch(check_tab(2));
          dispatch(resetFamilyData());
          await Http.GET("/familyprofiles/" + id, true).then((res) => {
            let data = res.data.data;
            dispatch(
              getSpecificpublicuser({
                ...data,
              })
            );
          });
        })
        .catch((err) => {
          toast.error("Error:occured while adding family member");
        });
    }
    if (form_status === "update") {
      Http.PUT(
        "/familymembers/" + edit_id,
        { familyprofile_id: id, ...family_data },
        true,
        true
      )
        .then(async (res) => {
          toast.success("Family Info has been updated");
          props.close_form();
          dispatch(resetFamilyData());
          await Http.GET("/familyprofiles/" + id, true).then((res) => {
            let data = res.data.data;
            dispatch(
              getSpecificpublicuser({
                ...data,
              })
            );
          });
        })
        .catch((err) => {
          toast.error("Error:occured while updating family member");
        });
    }
  };
  const citizenFrontHandler = () => {
    if (CtzFrontRef !== null) {
      CtzFrontRef.current.click();
    }
  };
  const citizenBackHandler = () => {
    if (CtzBackRef !== null) {
      CtzBackRef.current.click();
    }
  };
  const change_front_cc = (e: any) => {
    let file = e.target.files[0];
    dispatch(
      setFamilyData({
        name: "ctz_frontside_attachment",
        value: e.target.files[0],
      })
    );
    let imageBG = document.getElementById("cc_front") as any;
    let reader = new FileReader();
    if (imageBG !== null) {
      reader.onloadend = function () {
        imageBG.style.backgroundImage = "url(" + reader.result + ")";
      };
    }
    if (file) reader.readAsDataURL(file);
    else imageBG.style.backgroundImage = "";
  };
  const change_back_cc = (e: any) => {
    let file = e.target.files[0];
    dispatch(
      setFamilyData({
        name: "ctz_backside_attachment",
        value: e.target.files[0],
      })
    );
    let imageBG = document.getElementById("cc_back") as any;
    let reader = new FileReader();
    if (imageBG !== null) {
      reader.onloadend = function () {
        imageBG.style.backgroundImage = "url(" + reader.result + ")";
      };
    }
    if (file) reader.readAsDataURL(file);
    else imageBG.style.backgroundImage = "";
  };
  const input_handler = (e: any) => {
    dispatch(
      setFamilyData({
        name: e.target.name,
        value: e.target.value,
      })
    );
  };
  const fileHandler = () => {
    if (fileRef !== null) {
      fileRef.current.click();
    }
  };
  const select_handler = (name: string, value?: any) => {
    dispatch(
      setFamilyData({
        name,
        value,
      })
    );
  };
  return (
    <form
      style={{
        marginTop: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1.5rem",
      }}
      autoComplete="off"
      onSubmit={(e: any) => form_submit(e)}
    >
      <input
        name="profilepicture_attachment"
        style={{ display: "none" }}
        type="file"
        ref={fileRef}
        onChange={change_user_img}
      />
      <div className="flex center" style={{ gap: "1rem" }}>
        <div className={style.user_image_change} id="user_img" />
        <div>
          <p
            className={style.light_grey_font}
            style={{ fontSize: "1rem", fontWeight: "400" }}
          >
            {user_img ? user_img.name : "Allowed file types: png, jpg, jpeg."}
          </p>
          {user_img ? (
            <p className={style.light_grey_font}>
              <span style={{ color: "red" }}>
                {fileSizeHandler(user_img.size)}
              </span>
            </p>
          ) : null}
          <div
            onClick={fileHandler}
            className={style.file_button}
            style={{ width: "max-content", cursor: "pointer" }}
          >
            {user_img ? "Change Image" : "Upload Image"}
          </div>
        </div>
      </div>
      <Container title="Basic Information">
        <Fields>
          <InputField
            label_class="bold"
            label="Full Name(EN)"
            type="text"
            placeholder="Enter full name in english"
            name="fullname_en"
            value={family_data?.fullname_en}
            on_change={input_handler}
            required
          />
          <InputField
            label_class="bold"
            label="Full Name(NP)"
            type="text"
            placeholder="Enter full name in nepali"
            name="fullname_ne"
            value={family_data?.fullname_ne}
            on_change={input_handler}
            required
          />
        </Fields>
        <Fields>
          <InputField
            label_class="bold"
            label="Date of Birth"
            type="date"
            name="dob"
            required
            value={family_data?.dob}
            on_change={input_handler}
          />
          <InputField
            label_class="bold"
            label="Phone Number"
            type="number"
            placeholder="98********"
            name="mobile"
            value={family_data?.mobile}
            on_change={input_handler}
            required
          />
        </Fields>
        <Fields>
          <InputField
            label_class="bold"
            label="E-mail"
            type="email"
            placeholder="customer@care.com"
            name="email"
            value={family_data?.email}
            on_change={input_handler}
            required
          />
          <SelectInput
            label="Gender"
            name="gender_id"
            required
            options={gender}
            label_class="bold"
            placeholder="Select Gender"
            value={gender?.filter(
              (p: any) =>
                p.value.toString() === family_data?.gender_id?.toString()
            )}
            on_change={(e: any) => select_handler("gender_id", e.value)}
          />
        </Fields>
      </Container>

      <fieldset
        style={{
          border: "1px solid #ccc",
          borderRadius: "5px",
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <legend
          className="bold"
          style={{
            color: "#1f60ba",
            fontSize: "1.2rem",
            padding: "0 8px",
          }}
        >
          Identifications
        </legend>
        <Fields>
          <InputField
            type="text"
            label="Citizenship No."
            required
            label_class="bold"
            name="ctz_num"
            placeholder="Enter citizenship number"
            on_change={input_handler}
            value={family_data?.ctz_num}
          />
          <InputField
            type="date"
            label="Citizenship Issue Date"
            required
            label_class="bold"
            name="ctz_issuedate"
            placeholder="20**/**/**"
            on_change={input_handler}
            value={family_data?.ctz_issuedate}
          />
        </Fields>
        <InputField
          type="text"
          label="Citizenship Issue Place"
          required
          name="ctz_issuedplace"
          label_class="bold"
          placeholder="Janakpur"
          on_change={input_handler}
          value={family_data?.ctz_issuedplace}
        />
        <Fields>
          <input
            type="file"
            style={{ display: "none" }}
            name="ctz_frontside_attachment"
            ref={CtzFrontRef}
            onChange={change_front_cc}
          />
          <input
            type="file"
            style={{ display: "none" }}
            name="ctz_backside_attachment"
            ref={CtzBackRef}
            onChange={change_back_cc}
          />
          <div
            className={style.upload_file}
            onClick={citizenFrontHandler}
            id="cc_front"
          >
            {!family_data.ctz_frontside_attachment && (
              <>
                <BsCreditCard2Front />

                <p>Citizenship FrontSize Attachment</p>
              </>
            )}
          </div>
          <div
            className={style.upload_file}
            id="cc_back"
            onClick={citizenBackHandler}
          >
            {!family_data.ctz_backside_attachment && (
              <>
                <BsCreditCard2Front />
                <p>Citizenship BackSize Attachment</p>
              </>
            )}
          </div>
        </Fields>
      </fieldset>
      <div className={style.buttons}>
        <button
          type="button"
          className="secondary_button"
          onClick={props.close_form}
        >
          Cancel
        </button>
        <button className="primary_button" type="submit">
          {form_status === "create" ? "Save" : "Update"}
        </button>
      </div>
    </form>
  );
};
export default Form;
