import Table from "../../../component/table_container/main_table";
import style from "../../content.module.scss";
const Content = () => {
  let headings = [
    "नविकरण मिति",
    "असुली आ.व.",
    "रसिद नं.",
    "दस्तखत",
    "मितिः",
    "कैफियत",
  ];
  return (
    <div
      className={style.sifaris_docs_table}
    >
      <Table table_headings={headings}></Table>
    </div>
  );
};
export default Content;
