import { Route, Routes } from "react-router";
import FamilyRelation from "..";
import AddFamilyRelation from "../component/add";
import UpdateFamilyRelation from "../component/update";
export const FamilyRelationRoutes = () => (
  <Routes>
    <Route
      path="/system_configuration/family_relation"
      element={<FamilyRelation />}
    />
    <Route
      path="/system_configuration/family_relation/add"
      element={<AddFamilyRelation />}
    />{" "}
    <Route
      path="/system_configuration/family_relation/update/:id"
      element={<UpdateFamilyRelation />}
    />
  </Routes>
);
