import JillaContainer from "..";
import Http from "../../../../../utils/httpClients";
import {  toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../../../../hooks/hook";
import { addDistrict } from "../../../../../redux/store/districts";

const AddJilla = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const form_handler = (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);
    Http.POST("/districts", data, true)
      .then(() => {
        toast.success("District has been created!");
        dispatch(addDistrict(data));
        navigate(-1);
      })
      .catch((err) => {
        toast.error("Error while creating district!");
      });
  };
  return (
    <>
      <JillaContainer
        title="सिर्जना"
        form_handler={form_handler}
      />
    </>
  );
};
export default AddJilla;
