import { FiBell } from "react-icons/fi";
import style from "./index.module.scss";
import UserMenu from "./user_menu";
import InputField from "../input_container/index";
import UserInfo from "../side_bar/component/user_description";
const Nav = () => {
  return (
    <>
      <div className={style.nav_top}>
        <UserInfo />
        <div className={style.item_gap}>
          <InputField
            type="text"
            input_class="font-size-16"
            placeholder="Search for Anything"
          />
          <FiBell style={{ fontSize: "1.8rem", padding: "0 1rem" }} />
          <UserMenu />
        </div>
      </div>
    </>
  );
};
export default Nav;
