import style from "./index.module.scss";
import { MdOutlineDelete } from "react-icons/md";
import SelectInput from "../input_field/select_input";
import { FiEdit2 } from "react-icons/fi";
import { useState } from "react";
import { HiOutlineDuplicate } from "react-icons/hi";
interface InputFieldProps {
  input_data?: any;
  on_delete?: VoidFunction;
  on_edit?: VoidFunction;
  on_add?: any;
  display?: boolean;
  children?: JSX.Element | JSX.Element[];
  test?: boolean;
  duplicate_inputfield?:VoidFunction;
}
const PreviewSelectField = (props: InputFieldProps) => {
  const [hoverState, setHoverState] = useState<boolean>(false);
  return (
    <div
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
      className={props.input_data.outer_class}
      style={{
        paddingTop: "1rem",
        position: "relative",
        width: props.test ? "100%" : "default",
      }}
    >
      {props.display ? (
        <div
          style={{
            visibility: hoverState ? "visible" : "hidden",
          }}
          className={[
            "align_items",
            "table_icons",
            style.input_field_widget,
          ].join(" ")}
        >
          <HiOutlineDuplicate
            onClick={props.duplicate_inputfield}
            data-tooltip-id="view"
            data-tooltip-content="Duplicate"
          />
          <FiEdit2
            onClick={props.on_edit}
            data-tooltip-id="view"
            data-tooltip-content="Edit"
          />
          <MdOutlineDelete
            data-tooltip-id="view"
            data-tooltip-content="Remove"
            onClick={props.on_delete}
          />
        </div>
      ) : null}
      <SelectInput
        label={props.input_data.label}
        placeholder={props.input_data.placeholder}
        label_class={props.input_data.label_class}
      />
      {props.children}
    </div>
  );
};
export default PreviewSelectField;
