import GovLogo from "../../../assest/images/mofoga.png";
import style from './index.module.scss';
interface Props {
  title_1: String;
  title_2: String;
  title_3: String;
  title_4: String;
  sub_title_extra?: String;
  sub_title_1: String;
  sub_title_2: String;
  date: String;
  children?: JSX.Element | JSX.Element[];
}
const Header = (props: Props) => {
  return (
    <div className={style.header}>
      <div className={style.header_1}>
        <img className={style.header_logo_img} src={GovLogo} alt="style.gov logo" />
        <div className={style.header_center_titles}>
          <p className={["bold",style.header_large_text].join(" ")}>{props.title_1}</p>
          <p className="bold">{props.title_2}</p>
          <p className="bold">{props.title_3}</p>
          <p className="bold">{props.title_4}</p>
        </div>
      </div>
      <div className={style.header_2}>
        <div>
          <p className="bold">{props.sub_title_extra}</p>
          <p className="bold">{props.sub_title_1}</p>
        </div>
        <p className="bold">
          {props.sub_title_2} {props.date}
        </p>
      </div>
      {props.children}
    </div>
  );
};
export default Header;
