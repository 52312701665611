import { useEffect, useState } from "react";
import style from "../../roles/manage_roles/view_role/index.module.scss";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { CenterElement } from "../../../../component/manage_layout/center_element";
import { get_permission } from "../../../../utils/api/user_configuration/permissions";
import ReturnButton from "../../../../component/buttons/return_button";
import { UpdateButton } from "../../../../component/buttons/update_button";
const ViewPermisssion = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const [users, setUser] = useState<any>();
  const [roles, setRoles] = useState<any>();
  useEffect(() => {
    if (id !== undefined) {
      get_permission(id?.toString())
        .then((res) => {
          setData(res.data.data);
          console.log(res.data.data);
          setUser(res.data.data.roles[0].users);
          setRoles(res.data.data.roles);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error occured while fetching permission data!", {
            position: "bottom-right",
          });
        });
    }
  }, [id]);
  console.log(users);
  return (
    <CenterElement>
      <div
        className="component_wrapper"
        style={{ width: "70%", margin: "auto" }}
      >
        <div className="flex" style={{ justifyContent: "space-between" }}>
          <ReturnButton />
          <p className="title">{data?.permission_name}</p>
          <UpdateButton url={"/user_configuration/permission/update/" + id} />
        </div>
        <div className={style.table}>
          <div className={style.section}>
            <p className={style.head}>allowed roles</p>
            <div>
              {roles &&
                roles.map((p: any) => (
                  <p
                    className={style.children}
                    onClick={() =>
                      navigate("/user_configuration/role/view/" + p.id)
                    }
                  >
                    {p.role}
                  </p>
                ))}
            </div>
          </div>
          <div className={style.section}>
            <p className={style.head}>allowed users</p>
            <div>
              {users &&
                users.map((p: any) => (
                  <p
                    onClick={() =>
                      navigate("/user_configuration/user/view/" + p.id)
                    }
                    className={style.children}
                  >
                    {p.name}
                  </p>
                ))}
            </div>
          </div>
        </div>
      </div>
    </CenterElement>
  );
};
export default ViewPermisssion;
