import "./index.scss";
import Signature from "../../lang_nepali/signature";
const Content = () => {
  return (
    <>
      <div className="nepali_content">
        <div>
          <ul className="align_items">
            <p>१)</p>
            <div>
              <li>संस्थाको नाम:</li>
              <li>ठेगाना:</li>
              <li>स्वदेशी/ विदेशी:</li>
              <li>विषयगत क्षेत्र:</li>
              <li>संस्थाको कारोवार शुरु भएको मिति...</li>
              <li>ई-मेल:-</li>
              <li>सम्पर्क फोन नं:</li>
            </div>
          </ul>
          <ul className="align_items">
            <p>२)</p>
            <div>
              <li>संचालक/ अध्यक्ष / मुख्य व्यक्तिको नाम, थर</li>
              <li>ठेगाना:</li>
              <li>ई-मेल:-</li>
              <li>सम्पर्क फोन नं:</li>
            </div>
          </ul>
        </div>
        <Signature />
      </div>
    </>
  );
};
export default Content;
