import ReturnButton from "../buttons/return_button";
import { SubmitButton } from "../buttons/submit_button";
import styles from "./index.module.scss";
interface ManageLayoutProps {
  page_title: string;
  children: JSX.Element | JSX.Element[];
  btn_name?: string;
  form_handler?: React.FormEventHandler;
  form_width?: string;
  icon?: JSX.Element;
  on_cancel?: any;
  cancel_title?: string;
  loading?: boolean;
  loading_title?: string;
  update?:boolean;
}
const ManageLayout = (props: ManageLayoutProps) => {
  return (
    <div className={styles.align_form}>
      <div
        style={{
          width: props.form_width ? props.form_width : "80%",
          flex: "wrap",
        }}
        className={["component_wrapper", styles.manage_layout].join(" ")}
      >
        <p
          style={{ justifyContent: "center", gap: "8px" }}
          className={[styles.page_title, "align_items"].join(" ")}
        >
          {props.icon}
          {props.page_title}
        </p>
        <form autoComplete="off" onSubmit={props.form_handler}>
          {props.children}
          <div className="align_items">
            <ReturnButton btn_name="रद्द गर्नुहोस्" />
            <SubmitButton
              loading_title={props.loading_title}
              btn_title={props.btn_name}
              loading={props.loading}
              update={props.update}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
export default ManageLayout;
